/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useObserver } from "mobx-react-lite";
import Search from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { apiService } from "../../services/api-service";
import {
  IAddProvider,
  IAppointmentList,
  IUserProvider,
} from "../../services/api-types";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";
import { ISearchProvider } from "../../state/global-store-types";
import configData from "../../config.json";

// const styles = (theme: any) => ({
//   multilineColor: {
//     color: "red",
//   },
// });

export default function ProviderSearch() {
  const pageName = "ProviderSearch";
  const appInsights = useAppInsightsContext();
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const globalStore = useGlobalStore();
  const [searchResult, setSearchResult] = useState<IUserProvider[]>([]);
  const [globalSearchResult, setGlobalSearchResult] = useState<IUserProvider[]>(
    []
  );
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [filteredData, setFilteredData] = useState<any>();
  const [selectedProvider, setSelectedProvider] = useState<IUserProvider>(
    {} as IUserProvider
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    const getProvider = async () => {
      const searchString = globalStore.providerSearchText;

      const searchProviderObj: ISearchProvider = {
        groupId: configData.PROVIDER_GROUP_ID,
        userName: globalStore.providerSearchText,
        userId: globalStore.loginUserAadId,
      };
      if (searchString.trim().length > 2 && searchString.slice(-1) !== " ") {
        setShowSpinner(true);
        setShowSearchResult(true);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `Search Provider API Call, searchText: ${searchString}`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
        apiService
          .getProviderBasedOnSearch(searchProviderObj)
          .then((res) => {
            if (res.length > 0) {
              const userSpecificProviderListIds: string[] =
                globalStore.userSpecificProviderList.map(
                  (val: IUserProvider) => {
                    return val.providerId;
                  }
                );
              const filteredSearchResult: IUserProvider[] = res.filter(
                (val: IUserProvider) =>
                  !userSpecificProviderListIds.includes(val.providerId)
              );
              const newfilteredSearchResult = filteredSearchResult.map((v) => ({
                ...v,
                selected: false,
              }));
              setSearchResult(newfilteredSearchResult);
              setGlobalSearchResult(newfilteredSearchResult);
            } else {
              setSearchResult([]);
              setGlobalSearchResult([]);
            }
            setShowSpinner(false);
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `Search Provider API Call success, searchText: ${searchString}`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
          })
          .catch((error) => {
            setShowSpinner(false);
            setSearchResult([]);
            setGlobalSearchResult([]);
            AppInsightMethods.TrackAppInsightsException(
              `Error at getProviderBasedOnSearch Api Call, Component: ${pageName}, Error: ${error}`
            );
          });
      } else if (searchString.length === 0) {
        if (globalStore.isAllProviderList === false) {
          setShowSearchResult(false);
          setShowSpinner(false);
          setShowCloseIcon(false);
          setSelectedProvider({} as IUserProvider);
        } else {
          setShowSearchResult(true);
          setShowSpinner(true);
          apiService
            .getProviderBasedOnSearch(searchProviderObj)
            .then((res) => {
              if (res.length > 0) {
                const userSpecificProviderListIds: string[] =
                  globalStore.userSpecificProviderList.map(
                    (val: IUserProvider) => {
                      return val.providerId;
                    }
                  );
                const filteredSearchResult: IUserProvider[] = res.filter(
                  (val: IUserProvider) =>
                    !userSpecificProviderListIds.includes(val.providerId)
                );
                const newfilteredSearchResult = filteredSearchResult.map(
                  (v) => ({
                    ...v,
                    selected: false,
                  })
                );
                setSearchResult(newfilteredSearchResult);
                setGlobalSearchResult(newfilteredSearchResult);
              } else {
                setSearchResult([]);
                setGlobalSearchResult([]);
              }
              setShowSpinner(false);
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `Search Provider API Call success, searchText: ${searchString}`,
                globalStore.loginUserEmail,
                window.location.href,
                ""
              );
            })
            .catch((error) => {
              setShowSpinner(false);
              setSearchResult([]);
              setGlobalSearchResult([]);
              AppInsightMethods.TrackAppInsightsException(
                `Error at getProviderBasedOnSearch Api Call, Component: ${pageName}, Error: ${error}`
              );
            });
        }
      }
    };
    getProvider();
  }, [globalStore.providerSearchText, globalStore.isAllProviderList]);
  /* Method on clicking a provider from the search results */
  const onSearchResultSelectionHandler = (value: IUserProvider) => {
    try {
      const selectedName =
        `${value.firstName}` +
        `${value.middleName ? ` ${value.middleName}` : ""}` +
        ` ${value.lastName}`;
      setSelectedProvider(value);
      globalStore.setProviderSearchText(selectedName);
      setShowCloseIcon(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSearchResultSelectionHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Keyboard event on clicking a provider from the search results */
  const onSearchResultSelectionKeyDownHandler = (
    e: React.KeyboardEvent<HTMLLIElement>,
    value: IUserProvider
  ) => {
    try {
      if (e.key === "Enter") {
        onSearchResultSelectionHandler(value);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSearchResultSelectionKeyDownHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for adding a provider from the search results */
  const onAddProviderClick = (event: any) => {
    globalStore.setisAllProviderList(false);
    setShowSearchResult(false);
    try {
      if (
        selectedProvider &&
        selectedProvider.providerId &&
        selectedProvider.providerId !== ""
      ) {
        const addProvider: IAddProvider = {
          providers: [
            {
              providerId: selectedProvider.providerId,
              title: selectedProvider.title ? selectedProvider.title : "",
              firstName: selectedProvider.firstName,
              middleName: selectedProvider.middleName
                ? selectedProvider.middleName
                : "",
              lastName: selectedProvider.lastName,
              phone: selectedProvider.phone ? selectedProvider.phone : "",
              email: selectedProvider.email,
            },
          ],
          admin: {
            adminId: globalStore.loginUserAadId,
            emailId: globalStore.loginUserEmail,
          },
        };
        setShowSpinner(true);
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setSelectedProvider({} as IUserProvider);
        globalStore.setProviderSearchText("");
        apiService
          .addProvider(addProvider)
          .then((res: any) => {
            if (res === "success") {
              const globalProviderList = [
                ...globalStore.userSpecificProviderList,
              ];
              globalProviderList.push(selectedProvider);
              globalStore.setUserSpecificProviderList(globalProviderList);
              const appointmentLists: IAppointmentList[] = JSON.parse(
                JSON.stringify(globalStore.globalAppointmentsList)
              );
              const appointmentListFiltered = appointmentLists.filter((el) => {
                return globalProviderList.some((f) => {
                  return f.email === el.providerRequest[0].email;
                });
              });
              setFilteredData(appointmentListFiltered);
              // globalStore.setAppointmentsList(appointmentListFiltered);
              globalStore.setShowAlertMessage("success");
              globalStore.setDashboardAlertMessage(
                "Provider added successfully."
              );
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `Add Provider API Call success.`,
                globalStore.loginUserEmail,
                window.location.href,
                "success"
              );
              setTimeout(() => {
                globalStore.setShowAlertMessage("");
                globalStore.setDashboardAlertMessage("");
              }, 5000);
            }
            setShowSpinner(false);
          })
          .catch((error: any) => {
            setShowSpinner(false);
            globalStore.setShowAlertMessage("error");
            globalStore.setDashboardAlertMessage("Provider not added.");
            setTimeout(() => {
              globalStore.setShowAlertMessage("");
              globalStore.setDashboardAlertMessage("");
            }, 5000);
            AppInsightMethods.TrackAppInsightsException(
              `Error at Add Provider API Call, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onAddProviderClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  useEffect(() => {
    if (globalStore.selectedProviderName === "All Providers") {
      globalStore.setAppointmentsList(filteredData);
    }
  }, [globalStore.selectedProviderName]);

  /* Keyboard event for adding a provider from the search results */
  const onAddProviderKeyDownEvent = (event: any) => {
    try {
      if (event.key === "Enter") {
        onAddProviderClick(event);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onAddProviderKeyDownEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onChange handler for provider search input field */
  const onTextChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    globalStore.setisAllProviderList(true);
    try {
      const searchString: string = event.target.value;
      globalStore.setProviderSearchText(searchString);
      setShowCloseIcon(true);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTextChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard event for clearing the search field */
  const onCloseKeyDownHandler = (event: any) => {
    try {
      if (event.key === "Enter") {
        globalStore.setProviderSearchText("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCloseKeyDownHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for clearing the search field */
  const onCloseClickHandler = () => {
    globalStore.setisAllProviderList(false);
    try {
      globalStore.setProviderSearchText("");
      setShowSearchResult(false);
      setShowSpinner(false);
      setShowCloseIcon(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCloseClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className={"providerSearchMain"}>
        <Grid className={"providerSearch"}>
          <Search className={"providerSearchIcon"} />
          <TextField
            id="provider-SearchBox"
            placeholder={"Add Provider"}
            InputProps={{ disableUnderline: true }}
            className={` ${
              isMobile &&
              globalStore.windowWidth <= ConstantVariables.mobileMaxWidth &&
              globalStore.navOpen === true
                ? "providerSearchTextFieldNav"
                : "providerSearchTextField"
            }`}
            value={globalStore.providerSearchText}
            onChange={(e) => onTextChangeHandler(e)}
          />
          {showCloseIcon ? (
            <CloseIcon
              onClick={() => {
                onCloseClickHandler();
              }}
              onKeyDown={(e) => onCloseKeyDownHandler(e)}
              tabIndex={0}
              className={"providerSearchCloseIcon"}
            />
          ) : (
            <AddIcon
              className={` ${
                isMobile &&
                globalStore.windowWidth <= ConstantVariables.mobileMaxWidth &&
                globalStore.navOpen === true
                  ? "providerAddIconNav"
                  : "providerAddIcon"
              }`}
              onClick={(e) => onAddProviderClick(e)}
              onKeyDown={(e) => {
                onAddProviderKeyDownEvent(e);
              }}
              tabIndex={0}
            />
          )}
        </Grid>
        {globalStore.isAllProviderList && ""}
        {showSearchResult && globalStore.isAllProviderList && (
          <Grid className={"searchResultContainer"}>
            {showSpinner ? (
              <CircularProgress size={40} />
            ) : (
              <ul className={"searchResultUl"}>
                {searchResult.length > 0 ? (
                  searchResult.map((value: IUserProvider) => {
                    return (
                      <li
                        className={` ${
                          isMobile &&
                          globalStore.windowWidth <=
                            ConstantVariables.mobileMaxWidth &&
                          globalStore.navOpen === true
                            ? "searchResultLiNav"
                            : "searchResultLi"
                        }`}
                        onClick={() => onSearchResultSelectionHandler(value)}
                        onKeyDown={(e) =>
                          onSearchResultSelectionKeyDownHandler(e, value)
                        }
                        key={value.providerId}
                      >
                        {`${value.title ? `${value.title}.` : ""} ${
                          value.firstName
                        } ${value.middleName ? value.middleName : ""} ${
                          value.lastName
                        }`}
                      </li>
                    );
                  })
                ) : (
                  <li
                    className={` ${
                      isMobile &&
                      globalStore.windowWidth <=
                        ConstantVariables.mobileMaxWidth &&
                      globalStore.navOpen === true
                        ? "searchResultLiNav"
                        : "searchResultLi"
                    }`}
                  >
                    {ConstantVariables.No_Result_Text}
                  </li>
                )}
              </ul>
            )}
          </Grid>
        )}
      </Grid>
    );
  });
}
