/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import ProviderList from "./ProviderList";
import ProviderSearch from "./ProviderSearch";

export default function ProviderComponent() {
  const pageName = "ProviderComponent";
  const appInsights = useAppInsightsContext();

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"provider"}>
        <ProviderSearch />
        <ProviderList />
      </Grid>
    );
  });
}
