/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Divider,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { isMobile } from "react-device-detect";
import { useObserver } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useGlobalStore } from "../../state/global-store";
import { apiService } from "../../services/api-service";
import statusAvailable from "../../assets/status_available.svg";
import statusMeeting from "../../assets/status_meeting.svg";
import statusUnknown from "../../assets/status_unknown.svg";
import DateRightIcon from "../../assets/date_right_icon.svg";

import EllipsisMenuIcon from "../../assets/ellipsis_menu_icon.svg";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
  MeetingStatus,
} from "../../constants/Constants";
import { AppInsightMethods } from "../../AppInsightMethods";

const Participants = () => {
  const globalStore = useGlobalStore();
  const appointment = globalStore.meetingDetailedView;
  const pageName = "Participants";
  const appInsights = useAppInsightsContext();

  const { data, isFetching, isError } = useQuery(
    [appointment?.televisitId],
    apiService.getParticipantsList,
    { retry: false }
  );

  const [anchorEls, setAnchorEls] = useState({} as any);
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  /* Method for opening more action menu on individual participant */
  const handleActionClick = (
    email: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    anchorEls[email] = event?.target;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Remove participants action menu has been clicked. PatientEmail: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };
  /* Method for closing more action menu on individual participant */
  const handleActionClose = (email: string) => {
    anchorEls[email] = null;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Close "Remove participants" action menu. PatientEmail: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  return useObserver(() => {
    return (
      <Grid
        container
        id={
          !isMobile &&
          !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
            ? ""
            : "detailsPatientProviderMobile"
        }
        className="participants"
      >
        <Grid
          item
          className={
            !isMobile &&
            !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
              ? "external"
              : ""
          }
        >
          {/* Count and additional participants hardcoded as of now. Need to replace once the actual flow is implemented */}
          <Grid
            className={` ${
              !isMobile &&
              !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                ? "header"
                : "header-mobile"
            }`}
          >
            <img
              className="right-icon-mobile"
              src={DateRightIcon}
              alt="Right Arrow Icon"
            />

            {`Participants ${
              data && data.length > 0 ? `(${data.length + 1})` : `(1)`
            }`}
          </Grid>
          <Grid
            className={` ${
              !isMobile &&
              !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                ? "list-container"
                : "list-container-mobile"
            }`}
          >
            <Grid
              className={` ${
                !isMobile &&
                !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                  ? "list list-container-patient"
                  : "list-mobile list-container-patient"
              }`}
            >
              <Grid item>
                <Grid
                  className={` ${
                    !isMobile &&
                    !(
                      globalStore.windowWidth <=
                      ConstantVariables.mobileMaxWidth
                    )
                      ? "content"
                      : "content-mobile"
                  }`}
                >
                  {!isMobile &&
                  !(
                    globalStore.windowWidth <= ConstantVariables.mobileMaxWidth
                  ) ? (
                    <img src={statusAvailable} alt={"Available"} />
                  ) : (
                    <img
                      src={statusAvailable}
                      alt={"Available"}
                      style={{ height: "10px", width: "10px" }}
                    />
                  )}

                  {`${appointment?.patientFirstName} ${appointment?.patientLastName}`}
                </Grid>
                <Grid
                  className={` ${
                    !isMobile &&
                    !(
                      globalStore.windowWidth <=
                      ConstantVariables.mobileMaxWidth
                    )
                      ? "role"
                      : "role-mobile"
                  }`}
                >
                  {ConstantVariables.Patient_Text}
                </Grid>
              </Grid>
              {appointment && (
                <Grid item>
                  <img
                    src={EllipsisMenuIcon}
                    alt="Action-Menu-Icon"
                    className={`pointer ${
                      !isMobile &&
                      !(
                        globalStore.windowWidth <=
                        ConstantVariables.mobileMaxWidth
                      )
                        ? ""
                        : "marginEllipse"
                    }`}
                    role="button"
                    onClick={(e) =>
                      handleActionClick(appointment?.patientEmail, e)
                    }
                  />
                  <Menu
                    id={appointment?.patientEmail}
                    anchorEl={anchorEls[appointment?.patientEmail]}
                    keepMounted
                    open={Boolean(anchorEls[appointment?.patientEmail])}
                    onClose={() => handleActionClose(appointment?.patientEmail)}
                  >
                    <MenuItem
                      onClick={() =>
                        handleActionClose(appointment?.patientEmail)
                      }
                    >
                      {ConstantVariables.Remove_Participant_Button_Text}
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
            </Grid>
            <Divider />
            {isFetching && (
              <Grid
                container
                className={"appointmentList spinner"}
                justify={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={90} />
              </Grid>
            )}
            {isError && (
              <Grid
                container
                className={"error"}
                justify={"center"}
                alignItems={"center"}
              >
                {ConstantVariables.Common_Error_Text}
              </Grid>
            )}
            {!isFetching &&
              !isError &&
              data &&
              data.length > 0 &&
              data?.map((participant, index) => {
                const contact = participant.contacts[0];
                const statusIcon =
                  contact.status === "available"
                    ? statusAvailable
                    : contact.status === "unknown"
                    ? statusUnknown
                    : statusMeeting;
                return (
                  <Grid key={contact.email}>
                    <Grid
                      container
                      className={` ${
                        !isMobile &&
                        !(
                          globalStore.windowWidth <=
                          ConstantVariables.mobileMaxWidth
                        )
                          ? "list"
                          : "list-mobile"
                      }`}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid
                          className={` ${
                            !isMobile &&
                            !(
                              globalStore.windowWidth <=
                              ConstantVariables.mobileMaxWidth
                            )
                              ? "content"
                              : "content-mobile"
                          }`}
                        >
                          {!isMobile &&
                          !(
                            globalStore.windowWidth <=
                            ConstantVariables.mobileMaxWidth
                          ) ? (
                            <img src={statusIcon} alt={contact.status} />
                          ) : (
                            <img
                              src={statusIcon}
                              alt={contact.status}
                              style={{ height: "10px", width: "10px" }}
                            />
                          )}

                          {`${contact.firstName} ${contact.lastName}`}
                        </Grid>
                        <Grid
                          className={` ${
                            !isMobile &&
                            !(
                              globalStore.windowWidth <=
                              ConstantVariables.mobileMaxWidth
                            )
                              ? "role"
                              : "role-mobile"
                          }`}
                        >
                          {contact.relationship.relation}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <img
                          src={EllipsisMenuIcon}
                          alt="Action-Menu-Icon"
                          className={`pointer ${
                            !isMobile &&
                            !(
                              globalStore.windowWidth <=
                              ConstantVariables.mobileMaxWidth
                            )
                              ? ""
                              : "marginEllipse"
                          }`}
                          role="button"
                          onClick={(e) => handleActionClick(contact.email, e)}
                        />
                        <Menu
                          id={contact.email}
                          anchorEl={anchorEls[contact.email]}
                          keepMounted
                          open={Boolean(anchorEls[contact.email])}
                          onClose={() => handleActionClose(contact.email)}
                        >
                          <MenuItem
                            onClick={() => handleActionClose(contact.email)}
                          >
                            {ConstantVariables.Remove_Participant_Button_Text}
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                    {index !== data.length - 1 && <Divider />}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid
          item
          className={
            !isMobile &&
            !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
              ? "internal"
              : "internal-mobile"
          }
        >
          {/* Count hardcoded as of now. Need to replace once the actual flow is implemented */}

          <Grid
            className={` ${
              !isMobile &&
              !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                ? "header"
                : "header-mobile"
            }`}
          >
            <img
              className="right-icon-mobile"
              src={DateRightIcon}
              alt="Right Arrow Icon"
            />
            Providers (1)
          </Grid>
          <Grid
            className={` ${
              !isMobile &&
              !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                ? "list-container"
                : "list-container-mobile"
            }`}
          >
            <Grid
              className={` ${
                !isMobile &&
                !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                  ? "list"
                  : "list-mobile"
              }`}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  className={` ${
                    !isMobile &&
                    !(
                      globalStore.windowWidth <=
                      ConstantVariables.mobileMaxWidth
                    )
                      ? "content"
                      : "content-mobile"
                  }`}
                >
                  {!isMobile &&
                  !(
                    globalStore.windowWidth <= ConstantVariables.mobileMaxWidth
                  ) ? (
                    <img src={statusMeeting} alt={"Meeting"} />
                  ) : (
                    <img
                      src={statusMeeting}
                      alt={"Meeting"}
                      style={{ height: "10px", width: "10px" }}
                    />
                  )}

                  {`${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}
                </Grid>
                <Grid
                  className={` ${
                    !isMobile &&
                    !(
                      globalStore.windowWidth <=
                      ConstantVariables.mobileMaxWidth
                    )
                      ? "role"
                      : "role-mobile"
                  }`}
                >
                  Oncologist
                </Grid>
              </Grid>
              <Grid item>
                <img
                  src={EllipsisMenuIcon}
                  alt="Action-Menu-Icon"
                  className={`pointer ${
                    !isMobile &&
                    !(
                      globalStore.windowWidth <=
                      ConstantVariables.mobileMaxWidth
                    )
                      ? ""
                      : "marginEllipse"
                  }`}
                  role="button"
                  onClick={(e) =>
                    handleActionClick(
                      appointment?.providerRequest[0].email
                        ? appointment?.providerRequest[0].email
                        : "",
                      e
                    )
                  }
                />
                <Menu
                  id={appointment?.providerRequest[0].email}
                  anchorEl={
                    appointment?.providerRequest[0].email
                      ? anchorEls[appointment.providerRequest[0].email]
                      : null
                  }
                  keepMounted
                  open={
                    appointment?.providerRequest[0].email
                      ? Boolean(
                          anchorEls[appointment?.providerRequest[0].email]
                        )
                      : false
                  }
                  onClose={() =>
                    handleActionClose(
                      appointment?.providerRequest[0].email
                        ? appointment?.providerRequest[0].email
                        : ""
                    )
                  }
                >
                  {appointment?.status !== MeetingStatus.Scheduled &&
                    appointment?.status !== MeetingStatus.Completed && (
                      <MenuItem
                        onClick={() =>
                          handleActionClose(
                            appointment?.providerRequest[0].email
                              ? appointment?.providerRequest[0].email
                              : ""
                          )
                        }
                      >
                        {ConstantVariables.Send_Message_Button_Text}
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={() =>
                      handleActionClose(
                        appointment?.providerRequest[0].email
                          ? appointment?.providerRequest[0].email
                          : ""
                      )
                    }
                  >
                    {ConstantVariables.Details_Button_Text}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    );
  });
};

export default Participants;
