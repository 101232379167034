import { MeetingStatus } from "../constants/Constants";

export const dashboardUtils = {
  /* Method for getting background color base on meeting status */
  getBackGroundColor: (status: string) => {
    try {
      let statusColor;
      switch (status) {
        case MeetingStatus.InProgress:
          statusColor = "inProgress";
          break;
        case MeetingStatus.Waiting:
          statusColor = "waiting";
          break;
        case MeetingStatus.Delayed:
          statusColor = "delayed";
          break;
        case MeetingStatus.Completed:
          statusColor = "completed";
          break;
        case MeetingStatus.Scheduled:
        default:
          statusColor = "scheduled";
          break;
      }
      return statusColor;
    } catch (error) {
      return "#DCDCDC";
    }
  },
};
