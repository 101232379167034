/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Tooltip, withStyles } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import DateFnsUtils from "@date-io/date-fns";
import { useObserver } from "mobx-react-lite";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import dateLeftIcon from "../../assets/date_left_icon.svg";
import todayCalendar from "../../assets/today_calendar.svg";
import dateRightIcon from "../../assets/date_right_icon.svg";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

const useStyles = makeStyles({
  root: {
    fontSize: 40,
    color: "#484644",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});
const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFF",
    color: "#3D3D3D",
    boxShadow: "0px 2px 7px #00000021",
    fontSize: 16,
  },
  arrow: {
    color: "#FFF",
  },
}))(Tooltip);

interface ppprops {
  apifetch: (sd: Date, ed: Date) => void;
}

export default function AppointmentListNav(props: ppprops) {
  const pageName = "AppointmentListNav";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  /* Method to call meeting list api on the selected date */
  const callGetAppointmentApi = async (_selectedDate: Date) => {
    try {
      props.apifetch(_selectedDate, _selectedDate);
      globalStore.setSelectedDate(_selectedDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Appointment Date Change, selected Date is ${_selectedDate}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at callGetAppointmentApi, Component: ${pageName}, Error: ${error}`
      );
      globalStore.setSpinner(false);
    }
  };
  /* Method called on change of date in calendar */
  const handleDateChange = async (date: any) => {
    globalStore.setSelectedDate(date);
    try {
      const _selectedDate = new Date(date);
      await callGetAppointmentApi(_selectedDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Date Change, calendar click event on the Appointment list screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleDateChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method called on previous date arrow click event */
  const onPreviousDatehandler = async () => {
    try {
      const previousDate = new Date(
        globalStore.selectedDate.setDate(globalStore.selectedDate.getDate() - 1)
      );
      await callGetAppointmentApi(previousDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Date Change, the Previous button has been clicked on the Appointment list screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onPreviousDatehandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method called on next date arrow click event */
  const onNextDatehandler = async () => {
    try {
      const nextDate = new Date(
        globalStore.selectedDate.setDate(globalStore.selectedDate.getDate() + 1)
      );
      await callGetAppointmentApi(nextDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Date Change, the Next button has been clicked on the Appointment list screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onNextDatehandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on Today date button click event */
  const onCurrentDatehandler = async () => {
    try {
      const date = new Date();
      await callGetAppointmentApi(date);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Date Change, the Today button has been clicked on the Appointment list screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCurrentDatehandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on Today date button keyboard click event */
  const onCurrentDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onCurrentDatehandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCurrentDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on previous date arrow keyboard click event */
  const onPreviousDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onPreviousDatehandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onPreviousDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on next date arrow keyboard click event */
  const onNextDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onNextDatehandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onNextDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on calendar keyboard click event */
  const onDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        setOpen(true);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onNextDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className={"dateFilter"}>
        <Grid className={"dateFilterLeft"}>
          <p>{`${globalStore.selectedProviderName}`}</p>
        </Grid>
        <Grid className={"dateFilterRight"}>
          <Grid
            tabIndex={0}
            onClick={() => onCurrentDatehandler()}
            onKeyDown={(e) => onCurrentDateKeyDown(e)}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role={"button"}
            className={"icon cursor enabled todayBtn"}
            title={`Go to Today ${moment(new Date()).format("dddd")},${moment(
              new Date()
            ).format("LL")}`}
          >
            <img src={todayCalendar} alt={"calendar"} />
            <p>{ConstantVariables.Today_Text}</p>
          </Grid>
          <LightTooltip title="Previous Date" arrow>
            <img
              src={dateLeftIcon}
              alt="Previous Date"
              className="icon cursor enabled"
              role={"button"}
              onClick={() => onPreviousDatehandler()}
              onKeyDown={(e) => onPreviousDateKeyDown(e)}
              tabIndex={0}
            />
          </LightTooltip>
          <LightTooltip title="Next Date" arrow>
            <img
              src={dateRightIcon}
              alt="Next Date"
              className="icon cursor enabled"
              role={"button"}
              onClick={() => onNextDatehandler()}
              onKeyDown={(e) => onNextDateKeyDown(e)}
              tabIndex={0}
            />
          </LightTooltip>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd MMMM yyyy"
              margin="normal"
              id="filterDatePicker"
              value={globalStore.selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disableUnderline: true,
              }}
              keyboardIcon={<KeyboardArrowDownIcon className={classes.root} />}
              className={"appointmentDatePicker"}
              autoOk
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onClick={() => setOpen(true)}
              onKeyDown={(e) => onDateKeyDown(e)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  });
}
