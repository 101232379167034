/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { isMobile } from "react-device-detect";
import ReorderIcon from "@material-ui/icons/Reorder";
import { useObserver } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { apiService } from "../../services/api-service";
import {
  AppInsightConstants,
  ConstantVariables,
} from "../../constants/Constants";
import AppointmentBtn from "./AppointmentBtn";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import { ICustomLayoutType } from "../../state/global-store-types";

const AppointmentListHeader = () => {
  const appInsights = useAppInsightsContext();
  const pageName = "AppointmentListHeader";
  const globalStore = useGlobalStore();
  const history = useHistory();
  const trackComponent = useTrackMetric(appInsights, pageName);

  const { data } = useQuery(
    ["logoandnameappoinmtnetheader"],
    apiService.getLogoAndName,
    { retry: false }
  );

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    if (data) {
      const logoIconID = document.getElementById(
        "iconID"
      ) as HTMLAnchorElement | null;
      const logoAppleTouchIconID = document.getElementById(
        "appleTouchIconID"
      ) as HTMLAnchorElement | null;
      if (logoAppleTouchIconID !== null) {
        logoAppleTouchIconID.href = data.faviconLogo;
      }

      if (logoIconID !== null) {
        logoIconID.href = data.faviconLogo;
      }
    }
  }, [data]);

  useEffect(() => {
    // customised font api call
    apiService
      .getDefaultFontDetails()
      .then((resData: any) => {
        if (resData) {
          const root = document.documentElement;
          // font family
          root.style.setProperty(
            "--font-family-heading1",
            resData.heading1.fontFamily
          );
          root.style.setProperty(
            "--font-family-heading2",
            resData.heading2.fontFamily
          );
          root.style.setProperty(
            "--font-family-heading3",
            resData.heading3.fontFamily
          );
          root.style.setProperty(
            "--font-family-body1",
            resData.body1.fontFamily
          );
          root.style.setProperty(
            "--font-family-body2",
            resData.body2.fontFamily
          );
          root.style.setProperty(
            "--font-family-body3",
            resData.body3.fontFamily
          );
          root.style.setProperty(
            "--font-family-body4",
            resData.body4.fontFamily
          );
          root.style.setProperty(
            "--font-family-body5",
            resData.body5.fontFamily
          );
          root.style.setProperty(
            "--font-family-body6",
            resData.body6.fontFamily
          );
          root.style.setProperty(
            "--font-family-body7",
            resData.body7.fontFamily
          );
          root.style.setProperty(
            "--font-family-button",
            resData.button.fontFamily
          );
          root.style.setProperty(
            "--font-family-label",
            resData.label.fontFamily
          );
          // font-size
          root.style.setProperty(
            "--font-size-heading1",
            resData.heading1.fontSize
          );
          root.style.setProperty(
            "--font-size-heading2",
            resData.heading2.fontSize
          );
          root.style.setProperty(
            "--font-size-heading3",
            resData.heading3.fontSize
          );
          root.style.setProperty("--font-size-body1", resData.body1.fontSize);
          root.style.setProperty("--font-size-body2", resData.body2.fontSize);
          root.style.setProperty("--font-size-body3", resData.body3.fontSize);
          root.style.setProperty("--font-size-body4", resData.body4.fontSize);
          root.style.setProperty("--font-size-body5", resData.body5.fontSize);
          root.style.setProperty("--font-size-body6", resData.body6.fontSize);
          root.style.setProperty("--font-size-body7", resData.body7.fontSize);
          root.style.setProperty("--font-size-button", resData.button.fontSize);
          root.style.setProperty("--font-size-label", resData.label.fontSize);
          // font-weight
          root.style.setProperty(
            "--font-weight-heading1",
            resData.heading1.fontWeight
          );
          root.style.setProperty(
            "--font-weight-heading2",
            resData.heading2.fontWeight
          );
          root.style.setProperty(
            "--font-weight-heading3",
            resData.heading3.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body1",
            resData.body1.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body2",
            resData.body2.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body3",
            resData.body3.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body4",
            resData.body4.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body5",
            resData.body5.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body6",
            resData.body6.fontWeight
          );
          root.style.setProperty(
            "--font-weight-body7",
            resData.body7.fontWeight
          );
          root.style.setProperty(
            "--font-weight-button",
            resData.button.fontWeight
          );
          root.style.setProperty(
            "--font-weight-label",
            resData.label.fontWeight
          );
          // line-height
          root.style.setProperty(
            "--line-height-heading1",
            resData.heading1.lineHeight
          );
          root.style.setProperty(
            "--line-height-heading2",
            resData.heading2.lineHeight
          );
          root.style.setProperty(
            "--line-height-heading3",
            resData.heading3.lineHeight
          );
          root.style.setProperty(
            "--line-height-body1",
            resData.body1.lineHeight
          );
          root.style.setProperty(
            "--line-height-body2",
            resData.body2.lineHeight
          );
          root.style.setProperty(
            "--line-height-body3",
            resData.body3.lineHeight
          );
          root.style.setProperty(
            "--line-height-body4",
            resData.body4.lineHeight
          );
          root.style.setProperty(
            "--line-height-body5",
            resData.body5.lineHeight
          );
          root.style.setProperty(
            "--line-height-body6",
            resData.body6.lineHeight
          );
          root.style.setProperty(
            "--line-height-body7",
            resData.body7.lineHeight
          );
          root.style.setProperty(
            "--line-height-button",
            resData.button.lineHeight
          );
          root.style.setProperty(
            "--line-height-label",
            resData.label.lineHeight
          );
          // font-style
          root.style.setProperty(
            "--font-style-heading1",
            resData.heading1.fontStyle
          );
          root.style.setProperty(
            "--font-style-heading2",
            resData.heading2.fontStyle
          );
          root.style.setProperty(
            "--font-style-heading3",
            resData.heading3.fontStyle
          );
          root.style.setProperty("--font-style-body1", resData.body1.fontStyle);
          root.style.setProperty("--font-style-body2", resData.body2.fontStyle);
          root.style.setProperty("--font-style-body3", resData.body3.fontStyle);
          root.style.setProperty("--font-style-body4", resData.body4.fontStyle);
          root.style.setProperty("--font-style-body5", resData.body5.fontStyle);
          root.style.setProperty("--font-style-body6", resData.body6.fontStyle);
          root.style.setProperty("--font-style-body7", resData.body7.fontStyle);
          root.style.setProperty(
            "--font-style-button",
            resData.button.fontStyle
          );
          root.style.setProperty("--font-style-label", resData.label.fontStyle);
          // letter-spacing
          root.style.setProperty(
            "--letter-spacing-heading1",
            resData.heading1.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-heading2",
            resData.heading2.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-heading3",
            resData.heading3.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body1",
            resData.body1.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body2",
            resData.body2.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body3",
            resData.body3.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body4",
            resData.body4.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body5",
            resData.body5.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body6",
            resData.body6.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-body7",
            resData.body7.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-button",
            resData.button.letterSpacing
          );
          root.style.setProperty(
            "--letter-spacing-label",
            resData.label.letterSpacing
          );
          // text-indent
          root.style.setProperty(
            "--text-indent-heading1",
            resData.heading1.textIndent
          );
          root.style.setProperty(
            "--text-indent-heading2",
            resData.heading2.textIndent
          );
          root.style.setProperty(
            "--text-indent-heading3",
            resData.heading3.textIndent
          );
          root.style.setProperty(
            "--text-indent-body1",
            resData.body1.textIndent
          );
          root.style.setProperty(
            "--text-indent-body2",
            resData.body2.textIndent
          );
          root.style.setProperty(
            "--text-indent-body3",
            resData.body3.textIndent
          );
          root.style.setProperty(
            "--text-indent-body4",
            resData.body4.textIndent
          );
          root.style.setProperty(
            "--text-indent-body5",
            resData.body5.textIndent
          );
          root.style.setProperty(
            "--text-indent-body6",
            resData.body6.textIndent
          );
          root.style.setProperty(
            "--text-indent-body7",
            resData.body7.textIndent
          );
          root.style.setProperty(
            "--text-indent-button",
            resData.button.textIndent
          );
          root.style.setProperty(
            "--text-indent-label",
            resData.label.textIndent
          );
          // text-transform
          root.style.setProperty(
            "--text-transform-heading1",
            resData.heading1.textTransform
          );
          root.style.setProperty(
            "--text-transform-heading2",
            resData.heading2.textTransform
          );
          root.style.setProperty(
            "--text-transform-heading3",
            resData.heading3.textTransform
          );
          root.style.setProperty(
            "--text-transform-body1",
            resData.body1.textTransform
          );
          root.style.setProperty(
            "--text-transform-body2",
            resData.body2.textTransform
          );
          root.style.setProperty(
            "--text-transform-body3",
            resData.body3.textTransform
          );
          root.style.setProperty(
            "--text-transform-body4",
            resData.body4.textTransform
          );
          root.style.setProperty(
            "--text-transform-body5",
            resData.body5.textTransform
          );
          root.style.setProperty(
            "--text-transform-body6",
            resData.body6.textTransform
          );
          root.style.setProperty(
            "--text-transform-body7",
            resData.body7.textTransform
          );
          root.style.setProperty(
            "--text-transform-button",
            resData.button.textTransform
          );
          root.style.setProperty(
            "--text-transform-label",
            resData.label.textTransform
          );
          // text-decoration
          root.style.setProperty(
            "--text-decoration-heading1",
            resData.heading1.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-heading2",
            resData.heading2.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-heading3",
            resData.heading3.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body1",
            resData.body1.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body2",
            resData.body2.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body3",
            resData.body3.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body4",
            resData.body4.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body5",
            resData.body5.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body6",
            resData.body6.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-body7",
            resData.body7.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-button",
            resData.button.textDecoration
          );
          root.style.setProperty(
            "--text-decoration-label",
            resData.label.textDecoration
          );
        }
      })
      .catch((error: any) => {
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightConstants.Information,
          `Error in getting Customised font API call`,
          globalStore.loginUserEmail,
          window.location.href,
          `${error}`
        );
      });
    apiService
      .getLayoutStyle()
      .then((resData: any) => {
        if (resData) {
          const LayoutData: ICustomLayoutType = {
            layoutStyle: resData.layoutStyle,
            appTagLine: resData.appTagLine,
            fileName: resData.fileName,
            id: resData.id,
            uniqueFileName: resData.uniqueFileName,
            uploadedBy: resData.uploadedBy,
            uploadedTime: resData.uploadedTime,
            url: resData.url,
          };
          globalStore.setCustomLayoutData(LayoutData);
        }
      })
      .catch((error: any) => {
        history.push("/error");
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightConstants.Information,
          `Error in getting Customised Layout API call`,
          globalStore.loginUserEmail,
          window.location.href,
          `${error}`
        );
      });
  }, []);

  return useObserver(() => {
    return (
      <Grid container direction="column" className="header-container">
        {data && (
          <Grid
            item
            className="logo-container"
            style={
              globalStore.customLayoutData.layoutStyle === "Compact"
                ? { height: "80px" }
                : globalStore.customLayoutData.layoutStyle === "Standard"
                ? { height: "100px" }
                : globalStore.customLayoutData.layoutStyle === "Extended"
                ? { height: "120px", padding: "0px" }
                : { height: "64px" }
            }
          >
            {globalStore.customLayoutData.layoutStyle === "Standard" ? (
              <>
                <img
                  className="oltivaLogo"
                  src={data?.hospitalLogo}
                  alt={"Logo"}
                />
                <div className="standardLayout" style={{ alignSelf: "center" }}>
                  <p className="headerName">
                    {data?.hospitalName ? data.hospitalName : ""}
                  </p>
                  <p className="tagLineText">
                    {globalStore.customLayoutData.appTagLine}
                  </p>
                </div>
              </>
            ) : globalStore.customLayoutData.layoutStyle === "Extended" ? (
              <>
                <div
                  className="extendedlayoutContainer"
                  style={{ height: "120px" }}
                >
                  <div
                    className="headerTop"
                    style={{
                      alignSelf: "center",
                      backgroundImage: `url(${globalStore.customLayoutData.url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "1920px",
                    }}
                  >
                    <img src={data?.hospitalLogo} alt="logo" className="logo" />
                    <p className="headerName">OLTIVA HEALTHCARE</p>
                  </div>
                  <p className="tagLineText">
                    {globalStore.customLayoutData.appTagLine}
                  </p>
                </div>
              </>
            ) : (
              <>
                <img src={data?.hospitalLogo} alt={"Logo"} />
                {data?.hospitalName ? data.hospitalName : ""}
              </>
            )}
          </Grid>
        )}
        <>
          {globalStore.windowWidth >= ConstantVariables.mobileMaxWidth && (
            <Grid item className="heading">
              <Grid className={"heading-left"}>
                {globalStore.windowWidth <=
                  ConstantVariables.tabletMaxWidth && (
                  <ReorderIcon
                    onClick={() =>
                      globalStore.setIsNavOpen(!globalStore.navOpen)
                    }
                    style={{ marginRight: "18px" }}
                  />
                )}

                {ConstantVariables.Appointment_Text}
              </Grid>
              <Grid container className={"heading-Right"}>
                <AppointmentBtn />
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    );
  });
};

export default AppointmentListHeader;
