/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useGlobalStore } from "../../state/global-store";

import ProviderComponent from "../Provider/ProviderComponent";

export default function MobileSideNav() {
  const pageName = "SideNavScreen";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
    window.scrollTo(0, 0);
  }, []);

  return useObserver(() => {
    return (
      <div>
        <div className={`${globalStore.navOpen ? "sideNavOverlay" : ""}`} />

        <div
          className={`mobile-side-nav ${globalStore.navOpen ? "active" : ""}`}
        >
          <Grid className="titleSideNav">
            <p className="providerTitle">PROVIDERS</p>
            <CloseIcon
              onClick={() => globalStore.setIsNavOpen(false)}
              className="closeSideNav"
            />
          </Grid>
          <ProviderComponent />
        </div>
      </div>
    );
  });
}
