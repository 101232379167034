/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import {
  Button,
  Divider,
  InputLabel,
  FormControl,
  Grid,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useGlobalStore } from "../../state/global-store";
import OrganizerForm from "../OrganizerForm";
import SuccessScreen from "../MeetingLinkPopup/SuccessScreen";
import { apiService } from "../../services/api-service";
import {
  IAppointment,
  IProvider,
  ISearchPatientTableObj,
} from "../../services/api-types";
import ExistingPatientSearch from "./ExistingPatientSearch";
import PatientGrid from "./PatientGrid";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

interface IDurationMin {
  key: number;
  value: string;
}

const mockDuration: Array<IDurationMin> = [
  {
    key: 5,
    value: "5 minutes",
  },
  {
    key: 10,
    value: "10 minutes",
  },
  {
    key: 15,
    value: "15 minutes",
  },
  {
    key: 30,
    value: "30 minutes",
  },
  {
    key: 45,
    value: "45 minutes",
  },
  {
    key: 60,
    value: "60 minutes",
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AdhocMeeting() {
  const globalStore = useGlobalStore();
  const pageName = "AdhocMeeting";
  const appInsights = useAppInsightsContext();
  const history = useHistory();
  const classes = useStyles();
  const selectedDate = new Date();
  const [providerState, setProviderState] = useState<IProvider>(
    {} as IProvider
  );
  const [showNameRequiredMgs, setShowNameRequiredMgs] = useState(false);
  const [isCreateAppointmentApiError, setIsCreateAppointmentApiError] =
    useState("");
  const [duration, setDuration] = useState(10);
  const [showDurationMessage, setShowDurationErrorMgs] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [patientFullName, setPatientFullName] = useState("");

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  /* onChange method for calendar date picker */
  const handleDateChange = (date: any) => {
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `The Date Change Event. selected date is: ${date}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  /* onChange handler for time picker */
  const handleTimeChange = (date: any) => {
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `The Time Change Event. selected date is: ${date}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  /* Method for form validations and calling schedule meeting api service */
  const onScheduleMeetingClick = () => {
    try {
      setIsCreateAppointmentApiError("");
      let validationFailed = false;
      let providerValidationFailed = false;
      providerValidationFailed = validateProviderForm();
      if (duration === 0) {
        validationFailed = true;
        setShowDurationErrorMgs(true);
      }

      if (validationFailed || providerValidationFailed) {
        return;
      }
      const providerStateArr: IProvider[] = [];
      providerStateArr.push(providerState);
      if (globalStore.patient) {
        setPatientFullName(
          `${globalStore.patient.patientFirstName} ${globalStore.patient.patientLastName}`
        );
        let localTimeZone = "";
        try {
          const date = new Date();
          const timeZone = date.toString().match(/\(([^)]+)\)/);
          if (timeZone) {
            // eslint-disable-next-line prefer-destructuring
            localTimeZone = timeZone[1];
          }
        } catch (error) {
          localTimeZone = "";
        }
        const meetingObj: IAppointment = {
          televisitId: "",
          scheduledDateTime: moment(selectedDate).format(),
          appointmentDuration: duration,
          providers: providerStateArr,
          patientId: globalStore.patient.patientId,
          patientFirstName: globalStore.patient.patientFirstName,
          patientLastName: globalStore.patient.patientLastName,
          patientMiddleName: globalStore.patient.patientMiddleName,
          dob: globalStore.patient.dob,
          patientPhones: [...globalStore.patient.patientPhones],
          patientEmails: [...globalStore.patient.patientEmails],
          isPatientVerified: globalStore.patient.isPatientVerified,
          sendSMS: globalStore.patient.sendSMS,
          isAdhocMeeting: true,
          timeZone:
            localTimeZone === "" ? "Indian Standard Time" : localTimeZone,
        };
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Submit button has been clicked on the Appointment Creation screen..`,
          globalStore.loginUserEmail,
          window.location.href,
          JSON.stringify(meetingObj)
        );
        globalStore.setAppointment(meetingObj);
        setShowProgress(true);
        apiService
          .createAppointment(meetingObj)
          .then((res) => {
            setShowProgress(false);
            if (res.patientMeetingId) {
              globalStore.setOpenedModal("createAppointmentSuccess");
            } else {
              globalStore.setOpenedModal("none");
            }
            globalStore.setShowSuccessScreenHeader(true);
            setShowSuccess(true);
            globalStore.resetPatientGridObj();
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at Creating Appointment API Call, Component: ${pageName}, Error: ${error}`
            );
            setShowProgress(false);
            setIsCreateAppointmentApiError(error.message);
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onScheduleMeetingClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for resetting new appointment form and redirecting to meeting list page */
  const onCancelClick = (isReset: boolean) => {
    try {
      setShowNameRequiredMgs(false);
      setShowDurationErrorMgs(false);
      setDuration(10);
      globalStore.resetPatientGridObj();
      setProviderState({} as IProvider);
      globalStore.setPatient(null);
      globalStore.setProvider({
        providerId: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      } as IProvider);
      globalStore.resetSelectedProviderName();
      if (!isReset) {
        globalStore.resetSelectedProviderId();
        globalStore.setSelectedDate(new Date());
        history.push("/");
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Cancel button has been clicked on the Appointment Creation screen..`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Validations for provider details */
  const validateProviderForm = () => {
    try {
      let isFailed = false;
      if (typeof globalStore.provider === "undefined") {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else if (
        typeof globalStore.provider.firstName === "undefined" ||
        globalStore.provider.firstName === ""
      ) {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else {
        setShowNameRequiredMgs(false);
      }
      return isFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at validateProviderForm, Component: ${pageName}, Error: ${error}`
      );
      return false;
    }
  };
  /* onChange handler for provider field */
  const onChangeHandler = (providerObj: IProvider) => {
    try {
      setProviderState(providerObj);
      setShowNameRequiredMgs(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Handler for creating a new booking from appointment created success modal */
  const onBookAppointmentClickHandler = () => {
    try {
      globalStore.setShowSuccessScreenHeader(false);
      setShowSuccess(false);
      onCancelClick(true);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Book Appointment button has been clicked on the Appointment Success screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBookAppointmentClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Redirecting to home scree (meeting list screen) from appointment creation success modal */
  const onBackToHomeClickHandler = () => {
    try {
      globalStore.resetSelectedProviderId();
      globalStore.setSelectedDate(new Date());
      globalStore.setShowSuccessScreenHeader(false);
      setShowSuccess(false);
      history.push("/");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Back to Home button has been clicked on the Appointment Success screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackToHomeClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    const isAnyPatientSelected = globalStore.patientGridObj.some(
      (value: ISearchPatientTableObj) => value.selected
    );
    return (
      <>
        <Grid className="createeNewAppointment">
          {!!isCreateAppointmentApiError && (
            <Grid item className="error">
              {isCreateAppointmentApiError}
            </Grid>
          )}
          {showSuccess ? (
            <SuccessScreen
              onBackToHomeClick={onBackToHomeClickHandler}
              onBookAppointmentClick={onBookAppointmentClickHandler}
              patientName={patientFullName}
            />
          ) : (
            <Grid container>
              <Grid container className="createeNewAppointmentHeading">
                <h1 className="cardTitle">Create Adhoc Appointment</h1>
              </Grid>
              <Grid item className="createeNewAppointmentElement">
                <Grid className="patientDetails">
                  <h2 className="patientTitle">{`${ConstantVariables.Patient_Text} ${ConstantVariables.Details_Button_Text}`}</h2>
                  <Divider light className="dividerAppointment" />
                  <ExistingPatientSearch
                    setAlert={(v: boolean) => {
                      setShowAlert(v);
                    }}
                    setError={(v: string) => {
                      setIsCreateAppointmentApiError(v);
                    }}
                  />
                  {showAlert && (
                    <Alert
                      severity="error"
                      onClose={() => {
                        setShowAlert(false);
                      }}
                    >
                      {ConstantVariables.Patient_Find_Existing_Message_Text}
                    </Alert>
                  )}
                </Grid>
                <Grid className={"patientGrid_Container"}>
                  <PatientGrid />
                </Grid>
                {isAnyPatientSelected && (
                  <Grid className={"patientScheduleDetails"}>
                    <Grid className={"providerDetailsSection"}>
                      <OrganizerForm
                        onChangeHandler={onChangeHandler}
                        providerState={providerState}
                        showNameRequiredMgs={showNameRequiredMgs}
                      />
                    </Grid>
                    <Grid className="appointMent">
                      <h2 className="appointMentDateTitle">
                        Appointment Date and Time
                      </h2>
                      <Divider light style={{ width: "700px" }} />
                      <section className="appointMentDateSection">
                        <Grid className="appointMentDate">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InputLabel className="label">
                              {ConstantVariables.Date_Text}
                            </InputLabel>
                            <KeyboardDatePicker
                              variant="inline"
                              id="date-picker-dialog1"
                              format="MM/dd/yyyy"
                              inputVariant="outlined"
                              value={moment(selectedDate, ["MM/DD/YYYY"])}
                              onChange={(date) => handleDateChange(date)}
                              placeholder={"MM/DD/YYYY"}
                              autoOk
                              autoComplete="off"
                              disabled
                              className="dateColorChange"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid className="appointMentTime">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <InputLabel className="label">
                              {ConstantVariables.Time_Text}
                            </InputLabel>
                            <KeyboardTimePicker
                              variant="inline"
                              inputVariant="outlined"
                              value={selectedDate}
                              onChange={handleTimeChange}
                              placeholder={"HH:MM AM"}
                              autoOk
                              autoComplete="off"
                              disabled
                              className="dateColorChange"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid className="appointMentDuration">
                          <InputLabel className="label">
                            {ConstantVariables.Duration_Text}
                          </InputLabel>
                          <FormControl>
                            <Select
                              variant="outlined"
                              id="createAppointment-Duration"
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                              error={showDurationMessage}
                              value={duration}
                              onChange={(e) => {
                                setDuration(Number(e.target.value));
                                setShowDurationErrorMgs(false);
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                              className="dropdown duration"
                            >
                              {mockDuration.map((val: IDurationMin) => {
                                return (
                                  <MenuItem value={val.key} key={val.key}>
                                    {val.value}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {showDurationMessage && (
                              <FormHelperText
                                className={"appointMentDuration-helperText"}
                              >
                                {
                                  ConstantVariables.Select_Appointment_Duration_Text
                                }
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </section>
                    </Grid>
                  </Grid>
                )}
                {isAnyPatientSelected && (
                  <Grid className="appointmentBtn">
                    <Button
                      variant="contained"
                      onClick={() => onCancelClick(false)}
                      className="button cancel"
                    >
                      {ConstantVariables.Cancel_Button_Text}
                    </Button>
                    <Button
                      variant="contained"
                      className="button submit"
                      onClick={onScheduleMeetingClick}
                    >
                      {ConstantVariables.Submit_Button_Text}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {showProgress && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
}
