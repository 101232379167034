import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryConfigProvider } from "react-query";
import "./index.scss";
import App from "./App";
import { GlobalStore, GlobalStoreProvider } from "./state/global-store";

const globalStore = new GlobalStore();
const queryConfig = { queries: { refetchOnWindowFocus: false } };

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryConfigProvider config={queryConfig}>
      <GlobalStoreProvider value={globalStore}>
        <App />
      </GlobalStoreProvider>
    </ReactQueryConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
