/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import {
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useGlobalStore } from "../state/global-store";
import { IProvider, IGetProvider } from "../services/api-types";
import { AppInsightMethods } from "../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../constants/Constants";
import { apiService } from "../services/api-service";
import configData from "../config.json";

export interface IOrganizerFormProps {
  onChangeHandler: (provider: IProvider) => void;
  providerState: IProvider;
  showNameRequiredMgs: boolean;
}

interface IAPIProvider {
  providerId: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string | null;
  mobilePhone: string | null;
  officeLocation: string | null;
  surname: string;
  userPrincipalName: string;
  employeeId: string | null;
}

function OrganizerForm(props: IOrganizerFormProps) {
  const pageName = "OrganizerForm";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [providerList, setProviderList] = useState<IProvider[]>([]);

  useEffect(() => {
    trackComponent();
    const fetchProviderList = async () => {
      const cacheProviderdata = window.sessionStorage.getItem("providerList");
      if (cacheProviderdata) {
        const cacheProviderObj = JSON.parse(cacheProviderdata);
        setProviderList([...cacheProviderObj]);
      } else {
        const getProviderListObj: IGetProvider = {
          groupId: configData.PROVIDER_GROUP_ID,
          userId: globalStore.loginUserAadId,
        };
        apiService
          .getProviderList(getProviderListObj)
          .then((data: IAPIProvider[]) => {
            const formatedProviderList: IProvider[] = [];
            data.forEach((val: IAPIProvider) => {
              const apiProvider: IProvider = {} as IProvider;
              apiProvider.providerId = val.providerId;
              apiProvider.firstName = val.givenName;
              apiProvider.middleName = "";
              apiProvider.lastName = val.surname;
              apiProvider.title = val.jobTitle;
              apiProvider.email = val.userPrincipalName;
              apiProvider.phone = val.mobilePhone ?? "";
              formatedProviderList.push(apiProvider);
            });
            setProviderList(formatedProviderList);
            window.sessionStorage.setItem(
              "providerList",
              JSON.stringify(formatedProviderList)
            );
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `Get provider list API call succeeded.`,
              globalStore.loginUserEmail,
              window.location.href,
              JSON.stringify(data)
            );
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at getProviderList API call, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    };
    fetchProviderList();
  }, []);

  useEffect(() => {
    if (
      providerList &&
      providerList.length > 0 &&
      globalStore.selectedProviderId.toLowerCase() !==
        ConstantVariables.My_Provider_Text.toLowerCase() &&
      globalStore.selectedProviderId.toLowerCase() !==
        ConstantVariables.All_Provider_Text.toLowerCase()
    ) {
      const newObject: Array<IProvider> = JSON.parse(
        JSON.stringify(providerList)
      );
      const selectedProvider = newObject.filter(
        (val: IProvider) => val.providerId === globalStore.selectedProviderId
      )[0];
      if (
        selectedProvider &&
        selectedProvider.email &&
        selectedProvider.email !== ""
      ) {
        props.onChangeHandler(selectedProvider);
        globalStore.setProvider(selectedProvider);
      }
    }
  }, [providerList]);
  /* onChange handler for select provider dropdown */
  const onSelectBoxChange = (providerId: string) => {
    try {
      const newObject: Array<IProvider> = JSON.parse(
        JSON.stringify(providerList)
      );
      const selectedProvider = newObject.filter(
        (val: IProvider) => val.providerId === providerId
      )[0];
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The provider has been selected from the dropdown on the Appointment Creation page.`,
        globalStore.loginUserEmail,
        window.location.href,
        JSON.stringify(selectedProvider)
      );
      props.onChangeHandler(selectedProvider);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSelectBoxChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    globalStore.setProvider(props.providerState);
    return (
      <div className="provider">
        <h2 className="providerTitle">{`${ConstantVariables.Provider_Text} ${ConstantVariables.Details_Button_Text}`}</h2>
        <Divider light style={{ width: "300px" }} />
        <section>
          <div className="providerName">
            <InputLabel className="label">Name</InputLabel>
            <FormControl>
              <Select
                variant="outlined"
                id="providerName"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                error={props.showNameRequiredMgs}
                value={
                  props.providerState.providerId
                    ? props.providerState.providerId
                    : globalStore.selectedProviderId.toLowerCase() ===
                        ConstantVariables.My_Provider_Text.toLowerCase() ||
                      globalStore.selectedProviderId.toLowerCase() ===
                        ConstantVariables.All_Provider_Text.toLowerCase()
                    ? "0"
                    : globalStore.selectedProviderId
                }
                onChange={(e) => {
                  onSelectBoxChange(String(e.target.value));
                }}
                inputProps={{ "aria-label": "Without label" }}
                className="dropdown"
              >
                <MenuItem key={"0"} value={"0"} disabled>
                  Select provider
                </MenuItem>
                {providerList && providerList.length > 0 ? (
                  providerList.map((val: IProvider) => {
                    return (
                      <MenuItem
                        id={`providerName-MenuItem-${val.email}`}
                        key={val.providerId}
                        value={val.providerId}
                      >
                        {`${val.firstName} ${val.lastName}`}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    id={`providerName-MenuItem-loading`}
                    key={"99"}
                    value={"99"}
                  >
                    {`Loading...`}
                  </MenuItem>
                )}
              </Select>
              {props.showNameRequiredMgs && (
                <FormHelperText className={"provider-helperText"}>
                  {ConstantVariables.Select_Provider_Validation_Text}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </section>
      </div>
    );
  });
}

export default OrganizerForm;
