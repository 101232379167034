/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AppInsightMethods } from "../../AppInsightMethods";
import { AppInsightConstants as Constants } from "../../constants/Constants";
import { useGlobalStore } from "../../state/global-store";
import { ReactComponent as ChevronDownIcon } from "../../assets/chevron-down.svg";
import { ReactComponent as AdhocMeetingIcon } from "../../assets/adhoc-meeting.svg";
import { ReactComponent as AddMeetingIcon } from "../../assets/add-meeting.svg";

export default function AppointmentBtn() {
  const pageName = "AppointmentBtn";
  const refClassText = "refClass";
  const history = useHistory();
  const globalStore = useGlobalStore();
  const [openOptions, setOpenOptions] = useState(false);
  const wrapperRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const classes = event.target.classList;
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        classes.length > 0 &&
        !event.target.classList.contains(refClassText)
      ) {
        setOpenOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Grid
        role={"button"}
        tabIndex={0}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setOpenOptions(!openOptions);
          }
        }}
        className={`appointmentCustomBtn ${refClassText}`}
      >
        <span className={refClassText}>Appointment</span>
        <ChevronDownIcon className={refClassText} />
      </Grid>
      {openOptions && (
        <Grid className={"appointmentCustomBtnOptions"} ref={wrapperRef}>
          <Grid
            role={"button"}
            tabIndex={0}
            onClick={() => {
              history.push("/createAppointment");
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `A New Appointment button has been clicked to create new Appointment.`,
                globalStore.loginUserEmail,
                window.location.href,
                ""
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                history.push("/createAppointment");
              }
            }}
          >
            <AddMeetingIcon />
            <span>New Appointment</span>
          </Grid>
          <Grid
            role={"button"}
            tabIndex={0}
            onClick={() => {
              history.push("/createAdhocAppointment");
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `A Adhoc Appointment button has been clicked to create new Appointment.`,
                globalStore.loginUserEmail,
                window.location.href,
                ""
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                history.push("/createAdhocAppointment");
              } else {
                setOpenOptions(!openOptions);
              }
            }}
          >
            <AdhocMeetingIcon />
            <span>Adhoc Appointment</span>
          </Grid>
        </Grid>
      )}
    </>
  );
}
