/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Grid,
  TextareaAutosize,
  Button,
  Dialog,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import { useGlobalStore } from "../../state/global-store";
import { apiService } from "../../services/api-service";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";
import { sessionStorageObj } from "../../state/sessionStorage";
import { IPreMessages } from "../../services/api-types";

export default function SendMessage() {
  const pageName = "SendMessage";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const expiryTime = 3600;
  const [showValidation, setTextareaValidation] = useState(
    globalStore.sendMessageDetails.messageText.trim().length === 0
  );
  const [predefinedMgs, setPredefinedMgs] = useState<IPreMessages[]>();

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
    const getPredefinedMgs = async () => {
      const cachedMessages = await sessionStorageObj.getWithExpiry(
        "chatIntoRoomMessages"
      );
      if (cachedMessages) {
        setPredefinedMgs(cachedMessages);
      } else {
        apiService
          .getPredefinedMessages()
          .then((value: IPreMessages[]) => {
            sessionStorageObj.setWithExpiry(
              "chatIntoRoomMessages",
              value,
              expiryTime
            );
            setPredefinedMgs(value);
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at API call to fetch the Pre-defined Messages, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    };
    getPredefinedMgs();
  }, []);

  useEffect(() => {
    if (globalStore.sendMessageDetails.messageText.trim() === "") {
      setTextareaValidation(true);
    } else {
      setTextareaValidation(false);
    }
  }, [globalStore.sendMessageDetails.messageText]);

  /* Method for closing the send message modal */
  const onSendMessagePopupCloseClick = () => {
    try {
      globalStore.setShowSendMessagePopup("none");
      globalStore.updateSendMessage("");
      globalStore.setPreMessageType("");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Send Message Popup has been Closed.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessagePopupCloseClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard event for closing send message modal */
  const onSendMessagePopupCloseKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onSendMessagePopupCloseClick();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessagePopupCloseKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Reset method for clearing send message form */
  const onSendMessageReset = () => {
    try {
      globalStore.updateSendMessage("");
      globalStore.setPreMessageType("");
      globalStore.updateTelevisitId("", "");
      setTimeout(() => {
        globalStore.setShowAlertMessage("");
        globalStore.setDashboardAlertMessage("");
      }, ConstantVariables.PopupHideTime);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageReset, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for calling send message api service after clicking send button */
  const onSendMessageClickHandler = async () => {
    try {
      if (globalStore.sendMessageDetails.messageText.trim() === "") {
        setTextareaValidation(true);
        return;
      }
      globalStore.setShowSendMessagePopup("close");
      await apiService
        .sendMessageToMeetingRoom(globalStore.sendMessageDetails)
        .then((val: any) => {
          if (val.status === 200) {
            globalStore.setShowAlertMessage("success");
            globalStore.setDashboardAlertMessage("Messages sent successfully");
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `Successfully Message has been sent to the meeting room.`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
          } else {
            globalStore.setShowAlertMessage("error");
            globalStore.setDashboardAlertMessage(
              "Messages not sent successfully"
            );
            AppInsightMethods.TrackAppInsightsException(
              `Error at onSendMessageClickHandler, Component: ${pageName}, Error: Message not sent to the meeting room. ${val}`
            );
          }
          onSendMessageReset();
        })
        .catch((error: any) => {
          globalStore.setShowAlertMessage("error");
          globalStore.setDashboardAlertMessage(
            "Messages not sent successfully"
          );
          onSendMessageReset();
          AppInsightMethods.TrackAppInsightsException(
            `Error at send message to the meeting room API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard handler for calling send message api service after clicking send button */
  const onSendMessageKeyDownHandler = (event: any) => {
    try {
      if (event.key === "Enter") {
        onSendMessageClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageKeyDownHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onBlur handler for message text area field */
  const onTextareaBlurHandler = () => {
    try {
      if (globalStore.sendMessageDetails.messageText.trim() === "") {
        setTextareaValidation(true);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTextareaBlurHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Dialog
        open={globalStore.showSendMessagePopup === "open"}
        onClose={() => globalStore.setShowSendMessagePopup("none")}
        aria-labelledby="Send Message"
        aria-describedby="Send Message to Meeting Room"
        className={"sendMessageDialog"}
      >
        <Grid container className={"sendMessageContainer"}>
          <Grid item className={"sendMessageHeader"}>
            <p>{ConstantVariables.Sent_Message_Meeting_Room_Text}</p>
            <CloseIcon
              tabIndex={0}
              onClick={() => {
                onSendMessagePopupCloseClick();
              }}
              onKeyDown={(e) => onSendMessagePopupCloseKeyDown(e)}
            />
          </Grid>
          <Grid className="predefindMessagesContainer">
            <p>{ConstantVariables.Enter_Message_Meeting_Room_Text}</p>
            <FormControl>
              <Select
                variant="outlined"
                id="predefinedMessageDropdown"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                value={
                  globalStore.preMessageType ? globalStore.preMessageType : "0"
                }
                onChange={(e) => {
                  const preMgs: IPreMessages[] = JSON.parse(
                    JSON.stringify(predefinedMgs)
                  );
                  const mgs = preMgs.filter(
                    (val: IPreMessages) =>
                      val.messageType === String(e.target.value)
                  )[0].message;
                  globalStore.updateSendMessage(mgs);
                  globalStore.setPreMessageType(String(e.target.value));
                }}
                inputProps={{ "aria-label": "Without label" }}
                className="dropdown"
              >
                <MenuItem
                  className="dropdownListFont"
                  key={"0"}
                  value={"0"}
                  disabled
                >
                  Select Message
                </MenuItem>
                {predefinedMgs && predefinedMgs.length > 0 ? (
                  predefinedMgs.map((val: IPreMessages) => {
                    return (
                      <MenuItem
                        className="dropdownListFont"
                        id={`preMessages-MenuItem-${val.messageType}`}
                        value={val.messageType}
                      >
                        {`${val.messageType}`}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    id={`preMessages-MenuItem-loading`}
                    key={"99"}
                    value={"99"}
                  >
                    {`Loading...`}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={"sendMessageTextArea"}>
            <TextareaAutosize
              aria-label="TextArea"
              rowsMin={15}
              placeholder="Enter your message here..."
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setTextareaValidation(true);
                } else if (e.target.value.length > 0) {
                  setTextareaValidation(false);
                }
                globalStore.updateSendMessage(e.target.value);
              }}
              value={globalStore.sendMessageDetails.messageText}
              onBlur={() => onTextareaBlurHandler()}
            />
            <div className="helperMsg">
              * You can edit the above selected message text.
            </div>
          </Grid>
          <Grid item className={"sendMessageBtn"}>
            <Button
              variant="contained"
              onClick={() => onSendMessagePopupCloseClick()}
              onKeyDown={(e) => onSendMessagePopupCloseKeyDown(e)}
            >
              {ConstantVariables.Cancel_Button_Text}
            </Button>
            <Button
              variant="contained"
              className={`sendMessagePrimaryBtn ${
                showValidation ? "disable" : ""
              }`}
              onClick={() => onSendMessageClickHandler()}
              onKeyDown={(e) => onSendMessageKeyDownHandler(e)}
              disabled={showValidation}
            >
              {ConstantVariables.Send_Button_Text}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  });
}
