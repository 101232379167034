/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import { useGlobalStore } from "../../state/global-store";
import { ISearchPatientTableObj } from "../../services/api-types";
import { ConstantVariables } from "../../constants/Constants";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 180,
  },
});

const Verified = () => {
  return (
    <Grid className={"patientGridVerified"}>
      <CheckIcon /> <span>Verified</span>
    </Grid>
  );
};

const NotVerified = () => {
  return (
    <Grid className={"patientGridNotVerified"}>
      <CloseIcon /> <span>Not Verified</span>
    </Grid>
  );
};

export default function PatientGrid() {
  const pageName = "PatientGrid";
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <>
        {globalStore.patientGridObj.length > 0 && (
          <TableContainer
            className={`patientGridContainer ${classes.container}`}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ minWidth: 100, padding: 0 }}
                  >
                    {""}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 200, padding: 0 }}>
                    {ConstantVariables.Patient_FullName_Text}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 200, padding: 0 }}>
                    {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 200, padding: 0 }}>
                    {ConstantVariables.Recent_Visit_Date_Text}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 200, padding: 0 }}>
                    {""}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {globalStore.patientGridObj.map(
                  (value: ISearchPatientTableObj) => {
                    return (
                      <TableRow key={value.patientId}>
                        <TableCell
                          align="center"
                          style={{ minWidth: 100, padding: 0 }}
                        >
                          <Checkbox
                            color="primary"
                            onChange={(e, checked) =>
                              globalStore.onPatientCheckBoxChangeEvent(
                                checked,
                                value
                              )
                            }
                            checked={value.selected}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: 200, padding: 0 }}
                        >
                          {`${value.firstName} ${
                            value.lastName !== undefined ? value.lastName : ""
                          }`}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: 200, padding: 0 }}
                        >
                          {value.patientId === "" ? "--" : value.patientId}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: 200, padding: 0 }}
                        >
                          {value.recentVisit && value.recentVisit !== "--"
                            ? moment(new Date(value.recentVisit)).format(
                                "MMMM YYYY"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: 200, padding: 0 }}
                        >
                          {value.isVerified ? <Verified /> : <NotVerified />}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    );
  });
}
