/* eslint-disable @typescript-eslint/no-explicit-any */
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "./AppInsights";
import { LogType } from "./state/global-store-types";

export const AppInsightMethods = {
  trackAppInsightsEvent: (
    page: string,
    eventType: LogType,
    message: string,
    user: string,
    url: string,
    data: string
  ) => {
    appInsights.trackEvent({
      name: "VirualVisit-Dashboard",
      properties: {
        Page: page,
        EventType: eventType,
        Message: message,
        User: user,
        Url: url,
        Data: data,
      },
    });
  },

  TrackAppInsightsException: (error: any) => {
    appInsights.trackException({
      error: new Error(error),
      severityLevel: SeverityLevel.Error,
    });
  },
};
