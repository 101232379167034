/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useGlobalStore } from "../../state/global-store";
import { dashboardUtils } from "../../utils/dashboard-utils";
import JoinVisitIcon from "../../assets/join_visit_icon.svg";
import EndVisitIcon from "../../assets/end_visit_icon.svg";
import RescheduleVisitIcon from "../../assets/reschedule_visit_icon.svg";
import CancelVisitIcon from "../../assets/cancel_visit_icon.svg";
import SendMessageIcon from "../../assets/send_icon.svg";
import { IAppointmentList, IEndCall } from "../../services/api-types";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  MeetingStatus,
  AppInsightConstants as Constants,
} from "../../constants/Constants";
import { apiService } from "../../services/api-service";
import { helperMethods } from "../hooks/helper";

const useStyle = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 999,
  },
}));

const MeetingInfo = () => {
  const globalStore = useGlobalStore();
  const appointment = globalStore.meetingDetailedView;
  const pageName = "MeetingInfo";
  const headerClassName = "header";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const backdropClass = useStyle();
  const helper = helperMethods();

  useEffect(() => {
    trackComponent();
  }, []);

  /* Method for opening send message into meeting room modal */
  const onSendMessageClick = (appointmentDetail: IAppointmentList | null) => {
    try {
      if (appointmentDetail !== null) {
        globalStore.updateTelevisitId(
          appointmentDetail.televisitId,
          appointmentDetail.teamsMeetingUrl
        );
        globalStore.setShowSendMessagePopup("open");
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Send Message button has been clicked, to send messages to the meeting room.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleEndVisit = (appointmentDetail: IAppointmentList) => {
    try {
      const endMeeting: IEndCall = {
        participantId: "pID",
        televisitId: appointmentDetail.televisitId,
        timestamp: new Date().toISOString(),
        meetingUrl: appointmentDetail.teamsMeetingUrl,
        chatId: "",
      };
      setShowSpinner(true);
      apiService
        .endCall(endMeeting)
        .then((res: any) => {
          if (res === "success") {
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `The "EndVisit" API call success. televisitId: ${appointmentDetail.televisitId}`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
            helper.setDashboardAlertMessage(
              "Visit ended successfully.",
              "success"
            );
            globalStore.setMeetingDetailedViewStatus();
          }
          setShowSpinner(false);
        })
        .catch((error: any) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at "EndVisit" API call, handleEndVisit, Component: ${pageName}, Error: ${error}`
          );
          setShowSpinner(false);
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleEndVisit, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for opening send message into meeting room modal on keyboard event */
  const onSendMessageKeyDown = (
    event: any,
    appointmentDetail: IAppointmentList | null
  ) => {
    try {
      if (appointmentDetail && event.key === "Enter") {
        onSendMessageClick(appointmentDetail);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <>
        <Grid
          className={` ${
            !isMobile &&
            !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
              ? "meeting-info"
              : "meeting-info-mobile"
          }`}
        >
          <Grid
            container
            className={` ${
              !isMobile &&
              !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                ? "status-info"
                : "status-info-mobile"
            }`}
            justify="space-between"
            alignItems="center"
          >
            <Grid item className="details">
              {!isMobile &&
                !(
                  globalStore.windowWidth <= ConstantVariables.mobileMaxWidth
                ) && (
                  <Grid className={"details-container"}>
                    <Grid className="patient">
                      {`
            ${appointment?.patientLastName}, ${appointment?.patientFirstName}
          `}
                    </Grid>

                    {appointment?.status !== MeetingStatus.Scheduled &&
                      appointment?.status !== MeetingStatus.Completed && (
                        <Grid
                          item
                          className="send-message-button pointer"
                          tabIndex={0}
                          onClick={() => onSendMessageClick(appointment)}
                          onKeyDown={(e) =>
                            onSendMessageKeyDown(e, appointment)
                          }
                        >
                          <img
                            className="send-message-icon"
                            src={SendMessageIcon}
                            alt="Send Message Icon"
                          />
                          <Grid>
                            {ConstantVariables.Send_Message_Button_Text}
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                )}

              <Grid
                className={` ${
                  !isMobile &&
                  !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
                    ? "status"
                    : "statusMobile"
                } ${dashboardUtils.getBackGroundColor(
                  appointment?.status ? appointment?.status : ""
                )}`}
              >
                {appointment?.status}
              </Grid>
            </Grid>
            {!isMobile &&
            !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth)
              ? appointment?.status &&
                appointment?.status !== MeetingStatus.Completed && (
                  <Grid item className="buttons">
                    <Grid container className="noWrap">
                      {appointment?.status === MeetingStatus.Scheduled ? (
                        <>
                          <img
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "cancel-visit-icon"
                                : "cancel-visit-icon-mobile"
                            }`}
                            src={CancelVisitIcon}
                            alt="Cancel Visit Icon"
                            onClick={() => {
                              globalStore.setOpenedModal("cancel");
                            }}
                            role={"button"}
                          />
                          <Button
                            onClick={() => {
                              globalStore.setOpenedModal("reschedule");
                              globalStore.setActionMenuSelectedMeeting(
                                appointment
                              );
                            }}
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "reschedule-visit-icon"
                                : "reschedule-visit-icon-mobile"
                            }`}
                          >
                            Reschedule
                          </Button>
                          {/* <img
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "reschedule-visit-icon"
                                : "reschedule-visit-icon-mobile"
                            }`}
                            src={RescheduleVisitIcon}
                            alt="Reschedule Visit Icon"
                            onClick={() => {
                              globalStore.setOpenedModal("reschedule");
                              globalStore.setActionMenuSelectedMeeting(
                                appointment
                              );
                            }}
                            role="button"
                          /> */}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              AppInsightMethods.trackAppInsightsEvent(
                                pageName,
                                Constants.Information,
                                `The "EndVisit" button has been clicked on the Meeting Details page. televisitId: ${appointment.televisitId}`,
                                globalStore.loginUserEmail,
                                window.location.href,
                                ""
                              );
                              handleEndVisit(appointment);
                            }}
                          >
                            <img
                              className={`pointer ${
                                !isMobile &&
                                !(
                                  globalStore.windowWidth <=
                                  ConstantVariables.mobileMaxWidth
                                )
                                  ? "end-visit-icon"
                                  : "end-visit-icon-mobile"
                              }`}
                              src={EndVisitIcon}
                              alt="End Visit Icon"
                            />
                          </Button>
                          {appointment?.status &&
                            appointment?.status !==
                              MeetingStatus.InProgress && (
                              <img
                                className={`pointer ${
                                  !isMobile &&
                                  !(
                                    globalStore.windowWidth <=
                                    ConstantVariables.mobileMaxWidth
                                  )
                                    ? "join-visit-icon"
                                    : "join-visit-icon-mobile"
                                }`}
                                src={JoinVisitIcon}
                                alt="Join Visit Icon"
                                role="button"
                                onClick={() => {
                                  window.open(
                                    appointment.teamsMeetingUrl,
                                    "_blank"
                                  );
                                }}
                              />
                            )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                )
              : appointment?.status &&
                appointment?.status !== MeetingStatus.Completed && (
                  <Grid item className="buttons-mobile">
                    <Grid container className="noWrapMobile">
                      {appointment?.status === MeetingStatus.Scheduled ? (
                        <>
                          <img
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "cancel-visit-icon"
                                : "cancel-visit-icon-mobile"
                            }`}
                            src={CancelVisitIcon}
                            alt="Cancel Visit Icon"
                            onClick={() => {
                              globalStore.setOpenedModal("cancel");
                            }}
                            role={"button"}
                          />
                          <Button
                            onClick={() => {
                              globalStore.setOpenedModal("reschedule");
                              globalStore.setActionMenuSelectedMeeting(
                                appointment
                              );
                            }}
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "reschedule-visit-icon"
                                : "reschedule-visit-icon-mobile"
                            }`}
                          >
                            Reschedule
                          </Button>
                          {/* <img
                            className={`pointer ${
                              !isMobile &&
                              !(
                                globalStore.windowWidth <=
                                ConstantVariables.mobileMaxWidth
                              )
                                ? "reschedule-visit-icon"
                                : "reschedule-visit-icon-mobile"
                            }`}
                            src={RescheduleVisitIcon}
                            alt="Reschedule Visit Icon"
                            onClick={() => {
                              globalStore.setOpenedModal("reschedule");
                              globalStore.setActionMenuSelectedMeeting(
                                appointment
                              );
                            }}
                            role="button"
                          /> */}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              AppInsightMethods.trackAppInsightsEvent(
                                pageName,
                                Constants.Information,
                                `The "EndVisit" button has been clicked on the Meeting Details page. televisitId: ${appointment.televisitId}`,
                                globalStore.loginUserEmail,
                                window.location.href,
                                ""
                              );
                              handleEndVisit(appointment);
                            }}
                          >
                            <img
                              className={`pointer ${
                                !isMobile &&
                                !(
                                  globalStore.windowWidth <=
                                  ConstantVariables.mobileMaxWidth
                                )
                                  ? "end-visit-icon"
                                  : "end-visit-icon-mobile"
                              }`}
                              src={EndVisitIcon}
                              alt="End Visit Icon"
                            />
                          </Button>
                          {appointment?.status &&
                            appointment?.status !==
                              MeetingStatus.InProgress && (
                              <img
                                className={`pointer ${
                                  !isMobile &&
                                  !(
                                    globalStore.windowWidth <=
                                    ConstantVariables.mobileMaxWidth
                                  )
                                    ? "join-visit-icon"
                                    : "join-visit-icon-mobile"
                                }`}
                                src={JoinVisitIcon}
                                alt="Join Visit Icon"
                                role="button"
                                onClick={() => {
                                  window.open(
                                    appointment.teamsMeetingUrl,
                                    "_blank"
                                  );
                                }}
                              />
                            )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
          </Grid>
          {!isMobile &&
          !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth) ? (
            <Grid container className="appointment" justify="space-between">
              <Grid item>
                <Grid container>
                  <Grid className="patient-id">
                    <Grid className={headerClassName}>
                      {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                    </Grid>
                    <Grid className="text">{appointment?.patientId}</Grid>
                  </Grid>
                  <Grid className="schedule">
                    <Grid className={headerClassName}>
                      {ConstantVariables.Appointment_Date_Time_Text}
                    </Grid>
                    <Grid className="text">{`${moment(
                      new Date(
                        appointment?.scheduleDateTimeTZD
                          ? appointment?.scheduleDateTimeTZD
                          : ""
                      )
                    ).format("DD/MM/YYYY")} ${moment(
                      new Date(
                        appointment?.scheduleDateTimeTZD
                          ? appointment?.scheduleDateTimeTZD
                          : ""
                      )
                    ).format("LT")}`}</Grid>
                  </Grid>
                  <Grid className="provider">
                    <Grid className={headerClassName}>
                      {ConstantVariables.Provider_Text}
                    </Grid>
                    <Grid className="text">
                      {`${appointment?.providerRequest[0].title}. ${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}
                    </Grid>
                  </Grid>
                  {appointment?.status === MeetingStatus.Completed && (
                    <Grid className="duration">
                      <Grid
                        className={headerClassName}
                      >{`${ConstantVariables.Duration_Text} ${ConstantVariables.Duration_Format_Text}`}</Grid>
                      <Grid className="text">00:34:10</Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <Grid>
                  <Grid className="labelMobile">
                    {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                  </Grid>
                  <Grid className="textMobile">{appointment?.patientId}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid className="labelMobile">
                    {ConstantVariables.Appointment_Date_Time_Text}
                  </Grid>
                  <Grid className="textMobile">{`${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("DD/MM/YYYY")} ${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("LT")}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="providerDet">
                <Grid>
                  <Grid className="labelMobile">
                    {ConstantVariables.Provider_Text}
                  </Grid>
                  <Grid className="textMobile">
                    {`${appointment?.providerRequest[0].title}. ${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {showSpinner && (
          <Backdrop className={backdropClass.backdrop} open={showSpinner}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
};

export default MeetingInfo;
