/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import {
  Grid,
  Select,
  Button,
  Divider,
  Backdrop,
  MenuItem,
  InputLabel,
  makeStyles,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import DateLeftIcon from "../../assets/date_left_icon.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/global-store";
import {
  ConstantVariables,
  AppInsightConstants,
} from "../../constants/Constants";
import {
  IProvider,
  IAppointment,
  ISearchPatientTableObj,
} from "../../services/api-types";
import {
  mockDuration,
  IDurationMin,
} from "../CreateAppointment/CreateNewAppointment";
import ExistingPatientSearch from "../CreateAppointment/ExistingPatientSearch";
import MobilePatientGrid from "./MobilePatientGrid";
import OrganizerForm from "../OrganizerForm";
import { apiService } from "../../services/api-service";
import MobileSuccessScreen from "./MobileSuccessScreen";

interface IMobileAppointmentCreationProps {
  backBtnClick: () => void;
  appointmentType: string;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function MobileAdhocMeeting(
  props: IMobileAppointmentCreationProps
) {
  const pageName = "MobileAppointmentCreation";
  const classes = useStyles();
  const globalStore = useGlobalStore();
  const appInsights = useAppInsightsContext();
  const selectedDate = new Date();
  const [providerState, setProviderState] = useState<IProvider>(
    {} as IProvider
  );
  const [showNameRequiredMgs, setShowNameRequiredMgs] = useState(false);
  const [isCreateAppointmentApiError, setIsCreateAppointmentApiError] =
    useState("");
  const [duration, setDuration] = useState(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDateErrorMessage, setShowDateErrorMgs] = useState("");
  const [showDurationMessage, setShowDurationErrorMgs] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [patientFullName, setPatientFullName] = useState("");

  useEffect(() => {
    trackComponent();
  }, []);

  /* Click event for back button */
  const onBackBtnClick = () => {
    try {
      globalStore.setSelectedDate(new Date());
      globalStore.setPatient(null);
      globalStore.setProvider({
        providerId: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      } as IProvider);
      globalStore.resetPatientGridObj();
      props.backBtnClick();
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackBtnClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const onCancelClick = (isReset: boolean) => {
    try {
      setShowNameRequiredMgs(false);
      setShowDurationErrorMgs(false);
      setDuration(10);
      globalStore.resetPatientGridObj();
      setProviderState({} as IProvider);
      globalStore.setPatient(null);
      globalStore.setProvider({
        providerId: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      } as IProvider);
      globalStore.resetSelectedProviderName();
      if (!isReset) {
        globalStore.setSelectedDate(new Date());
        props.backBtnClick();
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `The Cancel button has been clicked on the Appointment Creation screen..`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCancelClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onChange handler for provider field */
  const onChangeHandler = (providerObj: IProvider) => {
    try {
      setProviderState(providerObj);
      setShowNameRequiredMgs(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onChange method for calendar date picker */
  const handleDateChange = (date: any) => {
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightConstants.Information,
      `The Date Change Event. selected date is: ${date}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  const handleTimeChange = (date: any) => {
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightConstants.Information,
      `The Time Change Event. selected date is: ${date}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  /* Validations for provider details */
  const validateProviderForm = () => {
    try {
      let isFailed = false;
      if (typeof globalStore.provider === "undefined") {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else if (
        typeof globalStore.provider.firstName === "undefined" ||
        globalStore.provider.firstName === ""
      ) {
        setShowNameRequiredMgs(true);
        isFailed = true;
      } else {
        setShowNameRequiredMgs(false);
      }
      return isFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at validateProviderForm, Component: ${pageName}, Error: ${error}`
      );
      return false;
    }
  };

  /* Method for form validations and calling schedule meeting api service */
  const onScheduleMeetingClick = () => {
    try {
      setIsCreateAppointmentApiError("");
      let validationFailed = false;
      let providerValidationFailed = false;
      providerValidationFailed = validateProviderForm();
      if (duration === 0) {
        validationFailed = true;
        setShowDurationErrorMgs(true);
      }

      if (validationFailed || providerValidationFailed) {
        return;
      }
      const providerStateArr: IProvider[] = [];
      providerStateArr.push(providerState);
      if (globalStore.patient) {
        setPatientFullName(
          `${globalStore.patient.patientFirstName} ${globalStore.patient.patientLastName}`
        );
        let localTimeZone = "";
        try {
          const date = new Date();
          const localTimeZoneStr = date.toString().match(/\(([^)]+)\)/);
          if (localTimeZoneStr) {
            // eslint-disable-next-line prefer-destructuring
            localTimeZone = localTimeZoneStr[1];
          }
        } catch (error) {
          localTimeZone = "";
        }
        const meetingObj: IAppointment = {
          televisitId: "",
          scheduledDateTime: moment(selectedDate).format(),
          appointmentDuration: duration,
          providers: providerStateArr,
          patientId: globalStore.patient.patientId,
          patientFirstName: globalStore.patient.patientFirstName,
          patientLastName: globalStore.patient.patientLastName,
          patientMiddleName: globalStore.patient.patientMiddleName,
          dob: globalStore.patient.dob,
          patientPhones: [...globalStore.patient.patientPhones],
          patientEmails: [...globalStore.patient.patientEmails],
          isPatientVerified: globalStore.patient.isPatientVerified,
          sendSMS: globalStore.patient.sendSMS,
          isAdhocMeeting: true,
          timeZone:
            localTimeZone === "" ? "Indian Standard Time" : localTimeZone,
        };
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightConstants.Information,
          `The Submit button has been clicked on the Appointment Creation screen..`,
          globalStore.loginUserEmail,
          window.location.href,
          JSON.stringify(meetingObj)
        );
        globalStore.setAppointment(meetingObj);
        setShowProgress(true);
        apiService
          .createAppointment(meetingObj)
          .then((res) => {
            setShowProgress(false);
            if (res.patientMeetingId) {
              globalStore.setOpenedModal("createAppointmentSuccess");
            } else {
              globalStore.setOpenedModal("none");
            }
            globalStore.setShowSuccessScreenHeader(true);
            setShowSuccess(true);
            globalStore.resetPatientGridObj();
          })
          .catch((error: any) => {
            AppInsightMethods.TrackAppInsightsException(
              `Error at Creating Appointment API Call, Component: ${pageName}, Error: ${error}`
            );
            setShowProgress(false);
            setIsCreateAppointmentApiError(error.message);
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onScheduleMeetingClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Redirecting to home scree (meeting list screen) from appointment creation success modal */
  const onBackToHomeClickHandler = () => {
    try {
      globalStore.resetSelectedProviderId();
      globalStore.setSelectedDate(new Date());
      globalStore.setShowSuccessScreenHeader(false);
      setShowSuccess(false);
      props.backBtnClick();
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `The Back to Home button has been clicked on the Appointment Success screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackToHomeClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Handler for creating a new booking from appointment created success modal */
  const onBookAppointmentClickHandler = () => {
    try {
      globalStore.setShowSuccessScreenHeader(false);
      setShowSuccess(false);
      onCancelClick(true);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `The Book Appointment button has been clicked on the Appointment Success screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBookAppointmentClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    const isAnyPatientSelected = globalStore.patientGridObj.some(
      (value: ISearchPatientTableObj) => value.selected
    );
    return (
      <>
        {showSuccess ? (
          <MobileSuccessScreen
            onBackToHomeClick={onBackToHomeClickHandler}
            onBookAppointmentClick={onBookAppointmentClickHandler}
            patientName={patientFullName}
            appointmentType={props.appointmentType}
          />
        ) : (
          <Grid className={"mobile-appointment-page"}>
            <Grid
              className="mobile-back-btn"
              alignItems="center"
              onClick={() => onBackBtnClick()}
              tabIndex={0}
            >
              <img
                className="back-icon"
                src={DateLeftIcon}
                alt="Back Arrow Icon"
              />
              {/* <span className="back text">{ConstantVariables.Back_Text}</span> */}
            </Grid>
            <p className="adhoc-mobile-appointment-heading">
              Create Adhoc Appointment
            </p>
            <Divider />
            <div className="adhoc-searchPatientComponent">
              <ExistingPatientSearch
                setAlert={(v: boolean) => {
                  setShowAlert(v);
                }}
                setError={(v: string) => {
                  setIsCreateAppointmentApiError(v);
                }}
              />
            </div>
            {showAlert && (
              <Alert
                severity="error"
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                {ConstantVariables.Patient_Find_Existing_Message_Text}
              </Alert>
            )}
            <MobilePatientGrid />
            {isAnyPatientSelected && (
              <Grid className={"patientScheduleDetails"}>
                <Grid className={"providerDetailsSection"}>
                  <OrganizerForm
                    onChangeHandler={onChangeHandler}
                    providerState={providerState}
                    showNameRequiredMgs={showNameRequiredMgs}
                  />
                </Grid>
                <Grid className="appointMent">
                  <h2 className="appointMentDateTitle">
                    Appointment Date and Time
                  </h2>
                  <Divider light style={{ width: "700px" }} />
                  <section className="appointMentDateSection">
                    <Grid className="appointMentDate">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <InputLabel className="label">
                          {ConstantVariables.Date_Text}
                        </InputLabel>
                        <KeyboardDatePicker
                          variant="inline"
                          id="date-picker-dialog1"
                          format="MM/dd/yyyy"
                          inputVariant="outlined"
                          value={moment(selectedDate, ["MM/DD/YYYY"])}
                          onChange={(date) => handleDateChange(date)}
                          helperText={showDateErrorMessage || ""}
                          placeholder={"MM/DD/YYYY"}
                          autoOk
                          autoComplete="off"
                          disabled
                          className="dateColorChange"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid className="appointMentTime">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <InputLabel className="label">
                          {ConstantVariables.Time_Text}
                        </InputLabel>
                        <KeyboardTimePicker
                          variant="inline"
                          inputVariant="outlined"
                          value={selectedDate}
                          onChange={handleTimeChange}
                          placeholder={"HH:MM AM"}
                          autoOk
                          autoComplete="off"
                          disabled
                          className="dateColorChange"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid className="appointMentDuration">
                      <InputLabel className="label">
                        {ConstantVariables.Duration_Text}
                      </InputLabel>
                      <FormControl>
                        <Select
                          variant="outlined"
                          id="createAppointment-Duration"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          error={showDurationMessage}
                          value={duration}
                          onChange={(e) => {
                            setDuration(Number(e.target.value));
                            setShowDurationErrorMgs(false);
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          className="dropdown duration"
                        >
                          {mockDuration.map((val: IDurationMin) => {
                            return (
                              <MenuItem value={val.key} key={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {showDurationMessage && (
                          <FormHelperText
                            className={"appointMentDuration-helperText"}
                          >
                            {ConstantVariables.Select_Appointment_Duration_Text}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </section>
                </Grid>
              </Grid>
            )}
            {isAnyPatientSelected && (
              <Grid className="appointmentBtn">
                <Button
                  variant="contained"
                  onClick={() => onCancelClick(false)}
                  className="button cancel"
                >
                  {ConstantVariables.Cancel_Button_Text}
                </Button>
                <Button
                  variant="contained"
                  className="button submit"
                  onClick={onScheduleMeetingClick}
                >
                  {ConstantVariables.Submit_Button_Text}
                </Button>
              </Grid>
            )}
            {!!isCreateAppointmentApiError && (
              <Grid item className="error">
                {isCreateAppointmentApiError}
              </Grid>
            )}
            {showProgress && (
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        )}
      </>
    );
  });
}
