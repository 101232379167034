/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Divider,
  Grid,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { isMobile } from "react-device-detect";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { apiService } from "../../services/api-service";
import {
  IAppointmentList,
  IProvider,
  IRemoveProvider,
  IUserProvider,
} from "../../services/api-types";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

const useStyles = makeStyles((theme: any) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function ProviderList() {
  const pageName = "ProviderList";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const [anchorEls, setAnchorEls] = React.useState({} as any);
  const [nameLength, setNameLength] = useState<number>();
  const [filteredData, setFilteredData] = useState<any>();

  const { data, refetch } = useQuery(
    [globalStore.loginUserEmail],
    apiService.getProviderDetailsBasedOnLogin,
    { retry: false }
  );
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    globalStore.userSpecificProviderList.map((value: IUserProvider) => {
      setNameLength(
        value.title.concat(value.firstName, value.middleName, value.lastName)
          .length
      );
      return nameLength;
    });
  }, [globalStore.userSpecificProviderList]);

  useEffect(() => {
    if (globalStore.loginUserEmail) {
      refetch();
    }
  }, [globalStore.loginUserEmail]);

  useEffect(() => {
    if (data && data.length > 0) {
      globalStore.setUserSpecificProviderList(data);
    }
  }, [data]);

  /* Method for filtering meetings based on the provider selected */
  const onProviderClick = (providerId: string) => {
    try {
      if (providerId === ConstantVariables.All_Provider_Text) {
        const providerList: IUserProvider[] = JSON.parse(
          JSON.stringify(globalStore.userSpecificProviderList)
        );
        const newProviderList = providerList.map((e: IUserProvider) => ({
          ...e,
          selected: false,
        }));
        globalStore.setUserSpecificProviderList(newProviderList);

        if (globalStore.globalAppointmentsList !== undefined) {
          const appointmentLists: IAppointmentList[] = JSON.parse(
            JSON.stringify(globalStore.globalAppointmentsList)
          );

          globalStore.setAppointmentsList(appointmentLists);
        }

        globalStore.setSelectedProviderName(
          ConstantVariables.All_Providers_Text
        );
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `All Providers has been selected from the provider List.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      } else if (providerId === ConstantVariables.My_Provider_Text) {
        const providerList: IUserProvider[] = JSON.parse(
          JSON.stringify(globalStore.userSpecificProviderList)
        );
        const newProviderList = providerList.map((e: IUserProvider) => ({
          ...e,
          selected: false,
        }));
        globalStore.setUserSpecificProviderList(newProviderList);

        if (globalStore.globalAppointmentsList !== undefined) {
          const appointmentLists: IAppointmentList[] = JSON.parse(
            JSON.stringify(globalStore.globalAppointmentsList)
          );
          const appointmentListFiltered = appointmentLists.filter((el) => {
            return newProviderList.some((f) => {
              return f.email === el.providerRequest[0].email;
            });
          });
          globalStore.setAppointmentsList(appointmentListFiltered);
        }

        globalStore.setSelectedProviderName("My Providers");
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `My Providers has been selected from the provider List.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      } else {
        const providerList: IUserProvider[] = JSON.parse(
          JSON.stringify(globalStore.userSpecificProviderList)
        );
        const newProviderList = providerList.map((e: IUserProvider) => ({
          ...e,
          selected: false,
        }));
        const selectedProviderObj: IUserProvider = newProviderList.filter(
          (value: IUserProvider) => value.providerId === providerId
        )[0];
        const providerName = `${
          selectedProviderObj.title ? selectedProviderObj.title : ""
        }. ${selectedProviderObj.firstName} ${
          selectedProviderObj.middleName ? selectedProviderObj.middleName : ""
        } ${selectedProviderObj.lastName}`;
        newProviderList.filter(
          (value: IUserProvider) => value.providerId === providerId
        )[0].selected = true;
        globalStore.setUserSpecificProviderList(newProviderList);
        if (globalStore.globalAppointmentsList !== undefined) {
          const appointmentList: IAppointmentList[] = JSON.parse(
            JSON.stringify(globalStore.globalAppointmentsList)
          );

          const filteredObj = appointmentList.filter((valu: IAppointmentList) =>
            valu.providerRequest.some(
              (val: IProvider) => val.providerId === providerId
            )
          );

          globalStore.setAppointmentsList(filteredObj);
        }
        globalStore.setSelectedProviderName(providerName);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `${providerName} has been selected from the provider List.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }

      globalStore.setSelectedProviderId(providerId);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onProviderClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for filtering meetings based on the provider selected using keyboard enter */
  const onProviderKeyDown = (event: any, providerId: string) => {
    try {
      if (event.key === "Enter") {
        onProviderClick(providerId);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onProviderKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for closing remove provider popover */
  const handleClose = (providerId: string) => {
    try {
      anchorEls[providerId] = null;
      setAnchorEls({ ...anchorEls });
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Remove Provider action menu has been closed, Provider Id: ${providerId}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleDateChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for opening remove provider popover */
  const handleClick = (providerId: string, event: any) => {
    try {
      anchorEls[providerId] = event?.target;
      setAnchorEls({ ...anchorEls });
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Remove Provider action menu has been opened, Provider Id: ${providerId}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for calling remove provider api service */
  const onProviderRemoveClick = (providerId: string) => {
    try {
      const removeProvider: IRemoveProvider = {
        providerId,
        adminId: globalStore.loginUserAadId,
      };
      anchorEls[providerId] = null;
      setAnchorEls({ ...anchorEls });
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Remove Provider action menu has been clicked, Provider Id: ${providerId}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      apiService
        .removeProvider(removeProvider)
        .then((res: any) => {
          if (res === "success") {
            const providerList: IUserProvider[] = JSON.parse(
              JSON.stringify(globalStore.userSpecificProviderList)
            );
            if (globalStore.selectedProviderId === providerId) {
              const newProviderList = providerList.map((e: IUserProvider) => ({
                ...e,
                selected: false,
              }));
              const providerListAfterRemoved = newProviderList.filter(
                (val: IUserProvider) => val.providerId !== providerId
              );
              globalStore.setUserSpecificProviderList(providerListAfterRemoved);
              globalStore.setSelectedProviderName("My Providers");
              globalStore.setSelectedProviderId(
                ConstantVariables.My_Provider_Text
              );
              removeProviderMeeting(providerListAfterRemoved);
              setFilteredData(providerListAfterRemoved);
            } else {
              const providerListAfterRemoved = providerList.filter(
                (val: IUserProvider) => val.providerId !== providerId
              );
              globalStore.setUserSpecificProviderList(providerListAfterRemoved);
              removeProviderMeeting(providerListAfterRemoved);
            }
            globalStore.setShowAlertMessage("success");
            globalStore.setDashboardAlertMessage(
              "Provider removed successfully."
            );
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `Remove Provider API call success, Provider Id: ${providerId}`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
          }
          setTimeout(() => {
            globalStore.setShowAlertMessage("");
            globalStore.setDashboardAlertMessage("");
          }, 5000);
        })
        .catch((error) => {
          globalStore.setShowAlertMessage("error");
          globalStore.setDashboardAlertMessage("Provider not removed.");
          setTimeout(() => {
            globalStore.setShowAlertMessage("");
            globalStore.setDashboardAlertMessage("");
          }, 5000);
          anchorEls[providerId] = null;
          setAnchorEls({ ...anchorEls });
          AppInsightMethods.TrackAppInsightsException(
            `Error at Remove Provider API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onProviderRemoveClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const removeProviderMeeting = (providerListAfterRemoved: IUserProvider[]) => {
    try {
      if (globalStore.globalAppointmentsList !== undefined) {
        const appointmentLists: IAppointmentList[] = JSON.parse(
          JSON.stringify(globalStore.globalAppointmentsList)
        );
        const appointmentListFiltered = appointmentLists.filter((el) => {
          return providerListAfterRemoved.some((f) => {
            return f.email === el.providerRequest[0].email;
          });
        });
        if (globalStore.selectedProviderName !== "All Providers") {
          globalStore.setAppointmentsList(appointmentListFiltered);
        }
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at removeProviderMeeting, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  useEffect(() => {
    removeProviderMeeting(filteredData);
  }, [globalStore.selectedProviderName]);

  return useObserver(() => {
    const isAnyProviderSelected =
      globalStore.userSpecificProviderList.filter(
        (value: IUserProvider) => value.selected === true
      ).length > 0;
    return (
      <Grid
        className={` ${
          isMobile &&
          globalStore.windowWidth <= ConstantVariables.mobileMaxWidth &&
          globalStore.navOpen === true
            ? "providerListTileNav"
            : "providerListTile"
        }`}
      >
        <ul>
          <li
            key={ConstantVariables.All_Provider_Text}
            title={ConstantVariables.All_Providers_Text}
            className={
              globalStore.selectedProviderId ===
              ConstantVariables.All_Provider_Text
                ? "selected"
                : ""
            }
            onClick={() => {
              onProviderClick(ConstantVariables.All_Provider_Text);

              globalStore.setIsNavOpen(false);
            }}
            onKeyDown={(e) =>
              onProviderKeyDown(e, ConstantVariables.All_Provider_Text)
            }
            tabIndex={0}
            style={{ cursor: "pointer" }}
          >
            {ConstantVariables.All_Providers_Text}
          </li>
          <li
            key={ConstantVariables.My_Provider_Text}
            title={ConstantVariables.My_Providers_Text}
            className={
              (globalStore.selectedProviderId !==
                ConstantVariables.All_Provider_Text &&
                globalStore.selectedProviderId ===
                  ConstantVariables.My_Provider_Text) ||
              (globalStore.selectedProviderId !==
                ConstantVariables.All_Provider_Text &&
                isAnyProviderSelected)
                ? "selected"
                : ""
            }
            onClick={() => {
              onProviderClick(ConstantVariables.My_Provider_Text);

              globalStore.setIsNavOpen(false);
            }}
            onKeyDown={(e) =>
              onProviderKeyDown(e, ConstantVariables.My_Provider_Text)
            }
            tabIndex={0}
            style={{ cursor: "pointer" }}
          >
            {ConstantVariables.My_Providers_Text}
          </li>
          <Divider />
          {globalStore.userSpecificProviderList.map((value: IUserProvider) => {
            return (
              <li
                key={value.providerId}
                title={`${value.firstName} ${
                  value.middleName ? value.middleName : ""
                } ${value.lastName}`}
                className={value.selected ? "providerSelected" : "notSelected"}
              >
                <Grid
                  onClick={() => {
                    onProviderClick(value.providerId);

                    globalStore.setIsNavOpen(false);
                  }}
                  onKeyDown={(e) => onProviderKeyDown(e, value.providerId)}
                  tabIndex={0}
                  className={"providerTab"}
                >
                  {isMobile &&
                  globalStore.windowWidth <=
                    ConstantVariables.mobileMaxWidth ? (
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "0.75rem",
                      }}
                    >
                      {`${value.title ? `${value.title}.` : ""} ${
                        value.firstName
                      } ${value.middleName ? value.middleName : ""} ${
                        value.lastName
                      }`}
                    </p>
                  ) : (
                    <p>
                      {`${value.title ? `${value.title}.` : ""} ${
                        value.firstName
                      } ${value.middleName ? value.middleName : ""} ${
                        value.lastName
                      }`}
                    </p>
                  )}
                </Grid>
                <Grid>
                  <MoreHorizIcon
                    onClick={(e) => handleClick(value.providerId, e)}
                    style={{ cursor: "pointer" }}
                  />
                  <Popover
                    id={value.providerId}
                    open={Boolean(anchorEls[value.providerId])}
                    anchorEl={anchorEls[value.providerId]}
                    onClose={() => handleClose(value.providerId)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Typography
                      className={classes.typography}
                      onClick={() => onProviderRemoveClick(value.providerId)}
                      style={{ cursor: "pointer" }}
                    >
                      {ConstantVariables.Remove_Provider_Button_Text}
                    </Typography>
                  </Popover>
                </Grid>
              </li>
            );
          })}
        </ul>
      </Grid>
    );
  });
}
