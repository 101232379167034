/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Grid, Tooltip, withStyles } from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { apiService } from "../../services/api-service";
import { useGlobalStore } from "../../state/global-store";
import DateLeftIcon from "../../assets/date_left_icon.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants,
} from "../../constants/Constants";
import { IProvider } from "../../services/api-types";
import { ICustomLayoutType } from "../../state/global-store-types";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFF",
    color: "#3D3D3D",
    boxShadow: "0px 2px 7px #00000021",
    fontSize: 16,
  },
  arrow: {
    color: "#FFF",
  },
}))(Tooltip);

const Header = () => {
  const pageName = "Header";
  const appInsights = useAppInsightsContext();
  const history = useHistory();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);

  /* Tracking the Component render on the page load event. */
  useEffect(() => {
    trackComponent();
  }, []);

  const { data } = useQuery(["logoandname"], apiService.getLogoAndName, {
    retry: false,
  });

  /* Click event for back button */
  const onBackBtnClick = () => {
    try {
      globalStore.setSelectedDate(new Date());
      globalStore.setPatient(null);
      globalStore.setProvider({
        providerId: "",
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      } as IProvider);
      globalStore.resetPatientGridObj();
      globalStore.resetSelectedProviderName();
      globalStore.resetSelectedProviderId();
      history.push("/");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackBtnClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard event for back button */
  const onBackBtnKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onBackBtnClick();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackBtnKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid container direction="column" className="header-container">
        {data && (
          <Grid
            item
            className="logo-container"
            style={
              globalStore.customLayoutData.layoutStyle === "Compact"
                ? { height: "80px" }
                : globalStore.customLayoutData.layoutStyle === "Standard"
                ? { height: "100px" }
                : globalStore.customLayoutData.layoutStyle === "Extended"
                ? { height: "120px", padding: "0px" }
                : { height: "64px" }
            }
          >
            {globalStore.customLayoutData.layoutStyle === "Standard" ? (
              <>
                <img
                  className="oltivaLogo"
                  src={data?.hospitalLogo}
                  alt={"Logo"}
                />
                <div className="standardLayout" style={{ alignSelf: "center" }}>
                  <p className="headerName">
                    {data?.hospitalName ? data.hospitalName : ""}
                  </p>
                  <p className="tagLineText">
                    {globalStore.customLayoutData.appTagLine}
                  </p>
                </div>
              </>
            ) : globalStore.customLayoutData.layoutStyle === "Extended" ? (
              <>
                <div
                  className="extendedlayoutContainer"
                  style={{ height: "120px" }}
                >
                  <div
                    className="headerTop"
                    style={{
                      alignSelf: "center",
                      backgroundImage: `url(${globalStore.customLayoutData.url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "1920px",
                    }}
                  >
                    <img src={data?.hospitalLogo} alt="logo" className="logo" />
                    <p className="headerName">OLTIVA HEALTHCARE</p>
                  </div>
                  <p className="tagLineText">
                    {globalStore.customLayoutData.appTagLine}
                  </p>
                </div>
              </>
            ) : (
              <>
                <img src={data?.hospitalLogo} alt={"Logo"} />
                {data?.hospitalName ? data.hospitalName : ""}
              </>
            )}
          </Grid>
        )}
        <Grid item className="heading">
          {globalStore.showSuccessScreenHeader ? (
            <Grid className={"heading-left"}>
              {ConstantVariables.Appointment_Text}
            </Grid>
          ) : (
            <Grid
              className="button back noWrap headingBack"
              alignItems="center"
              onClick={() => onBackBtnClick()}
              onKeyDown={(e) => onBackBtnKeyDown(e)}
              tabIndex={0}
            >
              <LightTooltip title="Back to Home Page" arrow>
                <img
                  className="back-icon"
                  src={DateLeftIcon}
                  alt="Back Arrow Icon"
                />
              </LightTooltip>
              <span className="back text">{ConstantVariables.Back_Text}</span>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  });
};

export default Header;
