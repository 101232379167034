/* eslint-disable no-else-return */
import {
  AccountInfo,
  AuthenticationResult,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { AppInsightMethods } from "../AppInsightMethods";
import { ConstantVariables } from "../constants/Constants";
import configData from "../config.json";

let msalClient: PublicClientApplication | undefined;
let userAccount: AccountInfo | null = null;
const scopes = ["User.Read"];

const getMsalConfig = async () => {
  return {
    auth: {
      clientId: configData.REACT_APP_API_APPID,
      authority: ConstantVariables.Authority,
      redirect_uri: `${window.location.origin}`,
    },
    cache: {
      cacheLocation: "sessionStorage",
    },
  };
};

export const authApiService = {
  signIn: async (): Promise<AccountInfo | null> => {
    const msalConfig = await getMsalConfig();
    msalClient = new PublicClientApplication(msalConfig);
    return msalClient
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        if (response !== null) {
          const { account } = response;
          userAccount = account;
          return userAccount;
        }
        const loginRequest = {
          scopes,
          redirectUri: `${window.location.origin}`,
        };

        msalClient?.loginRedirect(loginRequest);

        // this will never be called, as line above will do redirection
        return null;
      });
  },

  /* Method for getting bearer token for api calls */
  getAccessToken: async (): Promise<string | null> => {
    if (!msalClient) {
      throw new Error("Msal client is not initialized");
    }
    const appId = configData.REACT_APP_API_APPID;
    const silentRequest = {
      scopes: [`${appId}/.default`],
      account: userAccount,
    } as SilentRequest;

    try {
      const response = await msalClient.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAccessToken, Component: authApiService, Error: ${e}`
      );
    }
    return null;
  },
};
