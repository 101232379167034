/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import validator from "validator";
import { IAddPatient, ISearchPatientTableObj } from "../../services/api-types";
import { apiService } from "../../services/api-service";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface IAddPatientProps {
  setError: (val: string) => void;
}

export default function AddPatient(props: IAddPatientProps) {
  const re = /^0+$/;
  const pageName = "AddPatient";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [patientFullNameValidationMgs, setPatientFullNameValidationMgs] =
    useState("");
  const [patientPhoneValidationMgs, setPatientPhoneValidationMgs] =
    useState("");
  const [patientEmailValidationMgs, setPatientEmailValidationMgs] =
    useState("");
  const [disableAddPatientFields, setDisableAddPatientFields] = useState(false);

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  /* onBlur event for input elements in the page */
  const onAddPatientBlurEvent = async (fieldName: string) => {
    try {
      switch (fieldName) {
        case "name":
          if (fullName.trim().length === 0) {
            setPatientFullNameValidationMgs(
              ConstantVariables.Patient_Name_Validation_Text
            );
          } else {
            setPatientFullNameValidationMgs("");
          }
          break;
        case "phone":
          if (
            phoneNumber.length === 0 ||
            phoneNumber.trim().length === 0 ||
            phoneNumber.trim().replace(/-/g, "").length < 10 ||
            re.test(phoneNumber.trim().replace(/-/g, ""))
          ) {
            setPatientPhoneValidationMgs(
              ConstantVariables.Patient_Phone_Validation_Text
            );
          } else {
            setPatientPhoneValidationMgs("");
          }
          break;
        case "email":
          if (patientEmail.length === 0 || patientEmail.trim() === "") {
            setPatientEmailValidationMgs(
              ConstantVariables.Patient_Email_Validation_Text
            );
          } else if (!validator.isEmail(patientEmail)) {
            setPatientEmailValidationMgs(
              ConstantVariables.Patient_Email_Validation_Text
            );
          } else {
            setPatientEmailValidationMgs("");
          }
          break;
        default:
          break;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onAddPatientBlurEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Validation for Add Patient Form */
  const validateAddPatient = async () => {
    try {
      let isValidationFailed = false;
      if (fullName.trim().length === 0) {
        setPatientFullNameValidationMgs(
          ConstantVariables.Patient_Name_Validation_Text
        );
        isValidationFailed = true;
      } else {
        setPatientFullNameValidationMgs("");
      }
      if (
        phoneNumber.length === 0 ||
        phoneNumber.trim() === "" ||
        phoneNumber.trim().replace(/-/g, "").length < 10 ||
        re.test(phoneNumber.trim().replace(/-/g, ""))
      ) {
        setPatientPhoneValidationMgs(
          ConstantVariables.Patient_Phone_Validation_Text
        );
        isValidationFailed = true;
      } else {
        setPatientPhoneValidationMgs("");
      }

      if (patientEmail.length === 0 || patientEmail.trim() === "") {
        setPatientEmailValidationMgs(
          ConstantVariables.Patient_Email_Validation_Text
        );
        isValidationFailed = true;
      } else if (!validator.isEmail(patientEmail)) {
        setPatientEmailValidationMgs(
          ConstantVariables.Patient_Email_Validation_Text
        );
        isValidationFailed = true;
      } else {
        setPatientEmailValidationMgs("");
      }
      return isValidationFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at validateAddPatient, Component: ${pageName}, Error: ${error}`
      );
      return false;
    }
  };

  /* Method for resetting the form */
  const clearAllFields = () => {
    try {
      setFullName("");
      setPhoneNumber("");
      setPatientEmail("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at clearAllFields, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method called after click event on Add Patient Button */
  const addPatientClickEvent = async () => {
    try {
      const isValid = await validateAddPatient();
      if (isValid) {
        return;
      }

      const addNewPatientObj: IAddPatient = {
        patientId: "",
        firstName: fullName.split(" ")[0],
        middleName: "",
        lastName:
          fullName.split(" ")[1] === undefined ? "" : fullName.split(" ")[1],
        emails: [patientEmail],
        phones: [phoneNumber.replace(/-/g, "")],
        dob: "",
        sendSMS: false,
        isVerified: false,
      };
      props.setError("");
      setIsFetching(true);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Add Patient button has been clicked on the Appointment Creation screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        JSON.stringify(addNewPatientObj)
      );
      apiService
        .addPatient({ ...addNewPatientObj })
        .then((res: any) => {
          const addNewPatientObjToSearchResult: ISearchPatientTableObj = {
            ...res,
            selected: true,
            recentVisit: "--",
          };
          globalStore.setPatientGridObj({ ...addNewPatientObjToSearchResult });
          clearAllFields();
          setIsFetching(false);
          setDisableAddPatientFields(true);
        })
        .catch((error: any) => {
          setIsFetching(false);
          if (error.message.includes("already exists")) {
            props.setError(ConstantVariables.Patient_Exists_Message_Text);
          } else {
            props.setError(ConstantVariables.Common_Error_Text);
          }
          AppInsightMethods.TrackAppInsightsException(
            `Error at AddPatient API call, Component: ${pageName}, Error: ${error}`
          );
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at addPatientClickEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method called after keyboard click event on Add Patient Button */
  const addPatientKeyDownEvent = (event: any) => {
    try {
      if (event.key === "Enter") {
        addPatientClickEvent();
        event.preventDefault();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at addPatientKeyDownEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const normalizePhoneInput = (value: string) => {
    try {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, "");

      const cvLength = currentValue.length;

      if (cvLength < 4) return `${currentValue}`;
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at normalizePhoneInput, Component: ${pageName}, Error: ${error}`
      );
      return value;
    }
  };

  /* Method for onChange event handling on phone number field */
  const onPhoneNoChangeEvent = (event: any) => {
    try {
      if (event.target.value.length <= 12) {
        setPhoneNumber(normalizePhoneInput(event.target.value));
        setPatientPhoneValidationMgs("");
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onPhoneNoChangeEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for onChange event handling on email field */
  const onEmailChangeEvent = (event: any) => {
    try {
      const { value } = event.target;
      setPatientEmail(value);
      setPatientEmailValidationMgs("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onEmailChangeEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return (
    <Grid className={"addPatientComponent"}>
      <Grid className="addPatientNameContainer createInputContainer">
        <Grid className={"addPatientLabel"}>
          <InputLabel>{ConstantVariables.Patient_FullName_Text}</InputLabel>
          <sup>*</sup>
        </Grid>
        <TextField
          id="addPatientNameTextField"
          variant="outlined"
          placeholder={"Enter patient full name"}
          className={"scheduleInputTextField"}
          error={!!patientFullNameValidationMgs}
          helperText={patientFullNameValidationMgs || ""}
          value={fullName}
          onBlur={() => onAddPatientBlurEvent("name")}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setPatientFullNameValidationMgs("");
            }
            setFullName(e.target.value);
          }}
          autoComplete="off"
          disabled={disableAddPatientFields}
        />
      </Grid>
      <Grid className="addPatientPhoneNumberContainer createInputContainer">
        <Grid className={"addPatientLabel"}>
          <InputLabel>Phone Number</InputLabel>
          <sup>*</sup>
        </Grid>
        <TextField
          id="addPatientPhoneNoTextField"
          variant="outlined"
          placeholder={"+1 212-999-9999"}
          className={"scheduleInputTextField"}
          error={!!patientPhoneValidationMgs}
          helperText={patientPhoneValidationMgs || ""}
          value={phoneNumber}
          onBlur={() => onAddPatientBlurEvent("phone")}
          onChange={(e) => onPhoneNoChangeEvent(e)}
          disabled={disableAddPatientFields}
          autoComplete="off"
        />
      </Grid>
      <Grid className="addPatientEmailAddressContainer createInputContainer">
        <Grid className={"addPatientLabel"}>
          <InputLabel>{ConstantVariables.Email_Address_Text}</InputLabel>
          <sup>*</sup>
        </Grid>
        <TextField
          id="addPatientEmailTextField"
          variant="outlined"
          placeholder={"abcd@xyz.com"}
          className={"scheduleInputTextField"}
          error={!!patientEmailValidationMgs}
          helperText={patientEmailValidationMgs || ""}
          value={patientEmail}
          onBlur={() => onAddPatientBlurEvent("email")}
          onChange={(e) => onEmailChangeEvent(e)}
          disabled={disableAddPatientFields}
          autoComplete="off"
        />
      </Grid>
      <Grid className={"add-patient-btn-container"}>
        <Button
          color="primary"
          onClick={addPatientClickEvent}
          onKeyDown={(e) => addPatientKeyDownEvent(e)}
          className={"addCheckBtn"}
          disabled={disableAddPatientFields}
        >
          Add Patient
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
