/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { StylesProvider } from "@material-ui/core/styles";
import { useObserver } from "mobx-react-lite";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import SchedulingHomePage from "./pages/SchedulingHomePage";
import AdhocHomePage from "./pages/AdhocHomePage";
import AppointmentsList from "./components/AppointmentsList/Appointmentlist";
import { Authentication } from "./components/Authentication";
import { reactPlugin } from "./AppInsights";
import { ConstantVariables } from "./constants/Constants";
import { useGlobalStore } from "./state/global-store";

function App() {
  const globalStore = useGlobalStore();
  useEffect(() => {
    globalStore.setWindowWidth(window.innerWidth);
    if (window.innerWidth < window.innerHeight) {
      globalStore.setMobileOrientation("portrait");
    } else {
      globalStore.setMobileOrientation("landscape");
    }
    window.addEventListener("resize", () => {
      globalStore.setWindowWidth(window.innerWidth);
      if (window.innerWidth < window.innerHeight) {
        globalStore.setMobileOrientation("portrait");
      } else {
        globalStore.setMobileOrientation("landscape");
      }
    });
  }, []);

  return useObserver(() => {
    return (
      <Authentication>
        <AppInsightsErrorBoundary
          onError={() => <h1>{ConstantVariables.ErrorBoundaryTextMessage}</h1>}
          appInsights={reactPlugin}
        >
          <AppInsightsContext.Provider value={reactPlugin}>
            <StylesProvider injectFirst>
              <BrowserRouter basename="/">
                <Switch>
                  <Route exact path="/" component={AppointmentsList} />
                  <Route
                    exact
                    path="/createAppointment"
                    component={SchedulingHomePage}
                  />
                  <Route
                    exact
                    path="/createAdhocAppointment"
                    component={AdhocHomePage}
                  />
                </Switch>
              </BrowserRouter>
            </StylesProvider>
          </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
      </Authentication>
    );
  });
}

export default App;
