/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { ConstantVariables } from "../../constants/Constants";

interface IToggleButtonProps {
  selected: string;
  toggleSelected: (val: string) => void;
}

export default function ToggleButton(props: IToggleButtonProps) {
  const pageName = "ToggleButton";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  return (
    <Grid className="toggle-container">
      <FormControl component="fieldset" className={"radioGroup_Container"}>
        <RadioGroup
          row
          aria-label="Patient"
          name="row-radio-buttons-group"
          value={props.selected}
          onChange={(e, val) => {
            props.toggleSelected(val);
          }}
          className={"radioGroup"}
        >
          <FormControlLabel
            value="NewPatient"
            control={<Radio color="primary" />}
            label={ConstantVariables.New_Patient_Text}
            className={"newPatientRadioBtn"}
          />
          <FormControlLabel
            value="ExistingPatient"
            control={<Radio color="primary" />}
            label={ConstantVariables.Existing_Patient_Text}
            className={"existingPatientRadioBtn"}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
