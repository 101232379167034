/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import NoProviderImage from "../../assets/noproviderImage.svg";
import { useGlobalStore } from "../../state/global-store";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function NoProviderScreen() {
  const pageName = "NoProviderScreen";
  const classes = useStyles();
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"noProviderScreen"}>
        <img src={NoProviderImage} alt={"Add provider"} />
        <p>Welcome! Add provider to get started!</p>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => {
            globalStore.setProviderSearchText("");
            globalStore.setisAllProviderList(true);
          }}
          disabled={globalStore.isAllProviderList}
        >
          Add Provider
        </Button>
      </Grid>
    );
  });
}
