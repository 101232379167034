/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightConstants, MeetingStatus } from "../../constants/Constants";
import { AppInsightMethods } from "../../AppInsightMethods";

export default function StatusDropdown() {
  const pageName = "StatusDropdown";
  const refClassText = "mobile_RefClass";
  const [isOpen, setIsOpen] = useState(false);
  const globalStore = useGlobalStore();
  const wrapperRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const classes = event.target.classList;
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        classes.length > 0 &&
        !event.target.classList.contains(refClassText)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  /* On Change handler when meeting status tab is clicked */
  const onTabChangeHandler = (event: unknown, value: string) => {
    try {
      globalStore.setStatusTab(value);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Tab Change event, selected tab is ${value}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTabChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className={"mobile_Provider"} onClick={() => setIsOpen(!isOpen)}>
        <Grid className={"mobile_status_heading"}>
          {globalStore.statusTab} (
          {globalStore.statusTab === MeetingStatus.All
            ? globalStore.appointmentsList?.length
            : globalStore.appointmentsList?.filter(
                (val) => val.status === globalStore.statusTab
              ).length}
          ){isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        {isOpen && (
          <Grid className={"mobile_status_Menuitems"} ref={wrapperRef}>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.All ? "selected" : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.All)}
            >
              All ({globalStore.appointmentsList?.length})
            </Grid>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.InProgress
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.InProgress)}
            >
              In-Progress (
              {
                globalStore.appointmentsList?.filter(
                  (val) => val.status === MeetingStatus.InProgress
                ).length
              }
              )
            </Grid>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.Waiting
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.Waiting)}
            >
              Waiting (
              {
                globalStore.appointmentsList?.filter(
                  (val) => val.status === MeetingStatus.Waiting
                ).length
              }
              )
            </Grid>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.Delayed
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.Delayed)}
            >
              Delayed (
              {
                globalStore.appointmentsList?.filter(
                  (val) => val.status === MeetingStatus.Delayed
                ).length
              }
              )
            </Grid>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.Scheduled
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.Scheduled)}
            >
              Scheduled (
              {
                globalStore.appointmentsList?.filter(
                  (val) => val.status === MeetingStatus.Scheduled
                ).length
              }
              )
            </Grid>
            <Grid
              className={`mobile_status_item ${refClassText} ${
                globalStore.statusTab === MeetingStatus.Completed
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => onTabChangeHandler(e, MeetingStatus.Completed)}
            >
              Completed (
              {
                globalStore.appointmentsList?.filter(
                  (val) => val.status === MeetingStatus.Completed
                ).length
              }
              )
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  });
}
