/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ISearchProvider,
  ISendMessageDetails,
} from "../state/global-store-types";
import {
  RescheduleAppointmentObj,
  IAppointment,
  CancelAppointmentObj,
  IAddPatient,
  IRemoveProvider,
  IAddProvider,
  IParticipantsList,
  IEndCall,
  IGetProvider,
} from "./api-types";
import { authApiService } from "./authentication-api-service";
import { ConstantVariables } from "../constants/Constants";
import configData from "../config.json";

export const apiService = {
  getAppointments: async (
    _startDate: string,
    _endDate: string,
    _groupId: string,
    _userId: string
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/meeting-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify({
        startDate: _startDate,
        endDate: _endDate,
        groupId: _groupId,
        userId: _userId,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  createAppointment: async (appointment: IAppointment): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/appointment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(appointment),
    });
    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message
        ? message
        : ConstantVariables.ErrorBoundaryTextMessage;
      throw new Error(errorMessage);
    }
    return response.json();
  },

  getProviderDetailsBasedOnLogin: async (loginEmail: string): Promise<any> => {
    if (loginEmail) {
      const bToken = await authApiService.getAccessToken();
      const urlDomain = await configData.API_URL;
      const response = await fetch(`${urlDomain}/providers/${loginEmail}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }
    return "";
  },

  getProviderBasedOnSearch: async (
    serarchProviderObj: ISearchProvider
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/get-provider`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(serarchProviderObj),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },
  rescheduleAppointment: async (
    appointment: RescheduleAppointmentObj
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/appointment`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(appointment),
    });
    const { message } = await response.json();
    if (!response.ok) {
      const errorMessage = message
        ? message
        : ConstantVariables.ErrorBoundaryTextMessage;
      throw new Error(errorMessage);
    }
    return message;
  },

  cancelAppointment: async (
    appointment: CancelAppointmentObj
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/appointment`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(appointment),
    });
    const { message } = await response.json();
    if (!response.ok) {
      const errorMessage = message
        ? message
        : ConstantVariables.ErrorBoundaryTextMessage;
      throw new Error(errorMessage);
    }
    return message;
  },

  sendMessageToMeetingRoom: async (data: ISendMessageDetails): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/messages`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
  },

  addPatient: async (data: IAddPatient): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/add-patient`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if ((await response.text()).includes("already exists")) {
        throw new Error("already exists");
      }
      throw new Error(response.statusText);
    }
    return response.json();
  },

  searchPatient: async (searchText: string): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/search-patient/${searchText}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getParticipantsList: async (
    televisitId: string
  ): Promise<IParticipantsList[]> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/patient/${televisitId}/participant-list`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getLogoAndName: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/hospital`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  removeProvider: async (
    removeProviderDetails: IRemoveProvider
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-provider`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(removeProviderDetails),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  addProvider: async (addProviderDetails: IAddProvider): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/add-provider`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(addProviderDetails),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  getProviderList: async (getProviderBody: IGetProvider): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/get-providerlist?groupId=${getProviderBody.groupId}&userId=${getProviderBody.userId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getPredefinedMessages: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-predefined-messages`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },
  endCall: async (markComplete: IEndCall): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/meeting/mark-complete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(markComplete),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getLayoutStyle: async () => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-customlayout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },
  getThemeDetails: async () => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-themedetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getDefaultFontDetails: async () => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-defaultfontdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },
};
