/* eslint-disable react-hooks/rules-of-hooks */
import { useGlobalStore } from "../../state/global-store";
import { CustomAlert } from "../../state/global-store-types";

export function helperMethods() {
  const globalStore = useGlobalStore();
  const setDashboardAlertMessage = (mgs: string, mgsType: CustomAlert) => {
    globalStore.setShowAlertMessage(mgsType);
    globalStore.setDashboardAlertMessage(mgs);
    setTimeout(() => {
      globalStore.setShowAlertMessage("");
      globalStore.setDashboardAlertMessage("");
    }, 3000);
  };

  return {
    setDashboardAlertMessage,
  };
}
