/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { Divider, Grid, Button } from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import SuccessTick from "../../assets/success_tick.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/global-store";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

interface ISuccessScreenProps {
  onBackToHomeClick: () => void;
  onBookAppointmentClick: () => void;
  patientName: string;
  appointmentType: string;
}

export default function MobileSuccessScreen(props: ISuccessScreenProps) {
  const pageName = "SuccessScreen";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
    window.scrollTo(0, 0);
  }, []);

  /* Keyboard event for going to back to home screen from appointment created success modal */
  const onBackToHomeKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        props.onBackToHomeClick();
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Back to Home Page button has been clicked on the Appointment Creation Success screen..`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBackToHomeKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard event for going to new appointment screen from appointment created success modal */
  const onBookAppointmentKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        props.onBookAppointmentClick();
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Book Another Appointment button has been clicked on the Appointment Creation Success screen..`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onBookAppointmentKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={"mobile-successScreen"}
      >
        <Grid className={"mobile-successScreen-header"}>
          <p className="mobile-successScreen-header-heading">Appointments</p>
          {props.appointmentType === "adhoc" ? (
            <p className="mobile-successScreen-header-title">
              Create Adhoc Appointment
            </p>
          ) : (
            <p className="mobile-successScreen-header-title">
              Create New Appointment
            </p>
          )}
          <Divider />
        </Grid>
        <Grid className={"mobile-successScreenFirstSection"}>
          <img src={SuccessTick} alt={"Success Tick"} />
          {props.patientName !== "" ? (
            <p>
              {ConstantVariables.AdhocMeetingSuccessScreen}
              <strong>{props.patientName}</strong>
            </p>
          ) : (
            <p>{ConstantVariables.Appointment_Scheduled_Text}</p>
          )}
          <Divider />
        </Grid>
        <Grid className={"mobile-successScreenSecondSection"}>
          <Button
            variant="contained"
            className={"teleVisitBtn mobileteleVisitCancelBtn"}
            onClick={props.onBackToHomeClick}
            onKeyDown={(e) => onBackToHomeKeyDown(e)}
          >
            Home
          </Button>
          <Button
            variant="contained"
            className={"teleVisitBtn mobileteleVisitSuccessBtn"}
            onClick={props.onBookAppointmentClick}
            onKeyDown={(e) => onBookAppointmentKeyDown(e)}
          >
            Book New
          </Button>
        </Grid>
      </Grid>
    );
  });
}
