/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Button,
  CircularProgress,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import CloseIcon from "@material-ui/icons/Close";
import { useObserver } from "mobx-react-lite";
import { useGlobalStore } from "../../state/global-store";
import { CancelAppointmentObj } from "../../services/api-types";
import { apiService } from "../../services/api-service";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";
import { helperMethods } from "../hooks/helper";

interface CancelProps {
  refreshMeetings: () => void;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CancelAppointment(props: CancelProps) {
  const pageName = "CancelAppointment";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const helper = helperMethods();
  const [cancelAppointmentObj, setCancelAppointmentObj] =
    useState<CancelAppointmentObj | null>(null);
  const [errorMgs, setErrorMgs] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const cancelAppointMent = () => {
    try {
      if (cancelAppointmentObj) {
        setIsFetching(true);
        apiService
          .cancelAppointment(cancelAppointmentObj)
          .then((data: any) => {
            if (data) {
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `Cancel Appointment API call Success.`,
                globalStore.loginUserEmail,
                window.location.href,
                "success"
              );
              helper.setDashboardAlertMessage(data, "success");
              setIsFetching(false);
              setErrorMgs("");
              handleClickClose();
              props.refreshMeetings();
            }
          })
          .catch((error: any) => {
            setIsFetching(false);
            setErrorMgs(error.message);
          });
      }
    } catch (error) {
      setIsFetching(false);
      AppInsightMethods.TrackAppInsightsException(
        `Error at cancelAppointMent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to close Cancel appointment modal */
  const handleClickClose = () => {
    setErrorMgs("");
    setIsFetching(false);
    globalStore.setOpenedModal("none");
    globalStore.setActionMenuSelectedMeeting(null);
  };

  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    if (cancelAppointmentObj) {
      cancelAppointMent();
    }
  }, [cancelAppointmentObj]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCloseKeyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      handleClickClose();
    }
  };

  /* Method for cancelling a meeting */
  const onConfirmClick = () => {
    try {
      if (globalStore.actionMenuSelectedMeeting?.televisitId) {
        const cancelObj: CancelAppointmentObj = {
          televisitId: globalStore.actionMenuSelectedMeeting?.televisitId,
          patientId: "",
        };
        setCancelAppointmentObj(cancelObj);
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Confirm Button has been Clicked, on the Cancel Appointment Popup.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onConfirmClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* KEyboard event for cancelling a meeting */
  const onConfirmKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onConfirmClick();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onConfirmKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Modal
        open={globalStore.openedModal === "cancel"}
        onClose={handleClickClose}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className="cancel-appointment-modal"
        >
          <Grid item className="cancelContent">
            <Grid container justify="space-between">
              <Grid item className="cancelHeading">
                {ConstantVariables.Cancel_Visit_Button_Text}
              </Grid>
              <Grid item>
                <CloseIcon
                  onClick={() => {
                    handleClickClose();
                  }}
                  onKeyDown={(e) => onCloseKeyDownHandler(e)}
                  tabIndex={0}
                  className={"reschedule-visit-closeIcon pointer"}
                />
              </Grid>
            </Grid>
            <Grid className="cancel-side-heading">
              {ConstantVariables.Cancel_Confirm_Text}
            </Grid>
            <Grid container className="cancelDetails">
              <Button
                variant="contained"
                onClick={onConfirmClick}
                onKeyDown={(e) => onConfirmKeyDown(e)}
                className={"teleVisitBtn teleVisitCancelBtn"}
              >
                {ConstantVariables.Confirm_Button_Text}
              </Button>
            </Grid>
            {!!errorMgs && <span className={"error"}>{errorMgs}</span>}
          </Grid>
          {isFetching && (
            <Backdrop className={classes.backdrop} open={isFetching}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Modal>
    );
  });
}
