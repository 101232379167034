import React from "react";
import { useObserver } from "mobx-react-lite";
import { Grid } from "@material-ui/core";
import AdhocMeeting from "../components/CreateAppointment/AdhocMeeting";
import Header from "../components/Header/Header";
import { appInsights } from "../AppInsights";
import { useGlobalStore } from "../state/global-store";

export default function AdhocHomePage() {
  const globalStore = useGlobalStore();
  appInsights.trackPageView({
    name: "VirtualVisits-CreateAdhocAppointment",
    uri: window.location.href,
    properties: {
      user: globalStore.loginUserEmail,
    },
  });
  return useObserver(() => {
    return (
      <Grid className="schedulingAppointment">
        <Header />
        <AdhocMeeting />
      </Grid>
    );
  });
}
