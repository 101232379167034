/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useObserver } from "mobx-react-lite";
import { useGlobalStore } from "../../state/global-store";
import { IAppointmentList } from "../../services/api-types";
import { ConstantVariables, MeetingStatus } from "../../constants/Constants";
import MeetingDetailedView from "./MeetingDetailedView";

import Card from "./Card";

interface ICardViewProps {
  refreshMeetings: () => void;
}

const CardView = (props: ICardViewProps) => {
  const globalStore = useGlobalStore();
  const pageName = "Appointment-CardView";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    let appointments =
      globalStore.statusTab !== MeetingStatus.All
        ? globalStore.appointmentsList?.filter(
            (val: IAppointmentList) => val.status === globalStore.statusTab
          )
        : globalStore.appointmentsList;
    if (globalStore.searchMeetingString) {
      appointments = appointments.filter((appointment) => {
        if (
          globalStore?.searchMeetingString &&
          (appointment.patientId
            .toLowerCase()
            .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            appointment.patientFirstName
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            appointment.patientLastName
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            `${appointment.patientFirstName} ${appointment.patientLastName}`
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1)
        ) {
          return true;
        }
        return false;
      });
    }
    return (
      <>
        {!globalStore.meetingDetailedView ? (
          <Grid container className="cards-container">
            {appointments && appointments.length > 0 ? (
              appointments?.map(
                (appointment: IAppointmentList, index: number) => {
                  const detailsData = {
                    appointments,
                    index,
                  };
                  if (globalStore.statusTab === MeetingStatus.All) {
                    return (
                      <Card
                        appointment={appointment}
                        refreshMeetings={props.refreshMeetings}
                        detailsData={detailsData}
                      />
                    );
                  }
                  return (
                    globalStore.statusTab === appointment.status && (
                      <Card
                        appointment={appointment}
                        refreshMeetings={props.refreshMeetings}
                        detailsData={detailsData}
                      />
                    )
                  );
                }
              )
            ) : (
              <Grid className="noResultListView">
                <InfoOutlinedIcon /> {ConstantVariables.No_Records_Text}
              </Grid>
            )}
          </Grid>
        ) : (
          <MeetingDetailedView
            appointments={appointments}
            refreshMeetings={props.refreshMeetings}
          />
        )}
      </>
    );
  });
};

export default CardView;
