/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import { IAppointmentList, IEndCall } from "../../services/api-types";
import { dashboardUtils } from "../../utils/dashboard-utils";
import { apiService } from "../../services/api-service";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
  MeetingStatus,
} from "../../constants/Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/global-store";
import { helperMethods } from "../hooks/helper";

interface CardProps {
  appointment: IAppointmentList;
  refreshMeetings: () => void;
  detailsData: {
    appointments: IAppointmentList[];
    index: number;
  };
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 999,
  },
}));

const Card = (props: CardProps) => {
  const pageName = "Appointment-Card";
  const appInsights = useAppInsightsContext();
  const backdropClass = useStyle();
  const helper = helperMethods();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);
  const [anchorEls, setAnchorEls] = useState({} as any);

  useEffect(() => {
    trackComponent();
  }, []);

  const globalStore = useGlobalStore();

  const handleEndVisit = (data: IAppointmentList) => {
    try {
      const endMeeting: IEndCall = {
        participantId: "pID",
        televisitId: data.televisitId,
        timestamp: new Date().toISOString(),
        meetingUrl: data.teamsMeetingUrl,
        chatId: "",
      };
      setShowSpinner(true);
      apiService
        .endCall(endMeeting)
        .then((res: any) => {
          if (res === "success") {
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `The "EndVisit" API call success. televisitId: ${data.televisitId}`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
            helper.setDashboardAlertMessage(
              "Visit ended successfully.",
              "success"
            );
            props.refreshMeetings();
          }
          setShowSpinner(false);
        })
        .catch((error: any) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at "EndVisit" API call, handleEndVisit, Component: ${pageName}, Error: ${error}`
          );
          setShowSpinner(false);
        });
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleEndVisit, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleActionClose = (email: string) => {
    anchorEls[email] = null;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Action Menu Closed, PatientId: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  const onSendMessageMenuItemClick = (
    value: string,
    data: IAppointmentList
  ) => {
    try {
      anchorEls[value] = null;
      setAnchorEls({ ...anchorEls });
      globalStore.updateTelevisitId(data.televisitId, data.teamsMeetingUrl);
      globalStore.setShowSendMessagePopup("open");
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Send Message Button has been Clicked on the Dashboard.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageMenuItemClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleActionClick = (
    email: string,
    event: React.MouseEvent<SVGSVGElement>
  ) => {
    anchorEls[email] = event?.target;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Action Menu Opened, PatientId: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  return useObserver(() => {
    return (
      <>
        <Grid item className="card-item">
          <Grid className={"patient-name-container"}>
            <Grid className="patient-name">
              {`
            ${props.appointment.patientLastName}, ${props.appointment.patientFirstName}
          `}
            </Grid>
            <Grid item className="icon-container ellipsis">
              <>
                <MoreHorizIcon
                  onClick={(e) => {
                    globalStore.setActionMenuSelectedMeeting(props.appointment);
                    handleActionClick(`${props.appointment.televisitId}`, e);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <Menu
                  id={`${props.appointment.televisitId}`}
                  anchorEl={anchorEls[`${props.appointment.televisitId}`]}
                  keepMounted
                  open={Boolean(anchorEls[`${props.appointment.televisitId}`])}
                  onClose={() =>
                    handleActionClose(`${props.appointment.televisitId}`)
                  }
                  className={"card-Menu-Item"}
                >
                  {props.appointment.status === MeetingStatus.Scheduled ? (
                    <>
                      <MenuItem
                        disabled={props.appointment.isAdhocMeeting}
                        onClick={() => {
                          handleActionClose(`${props.appointment.televisitId}`);
                          globalStore.setOpenedModal("reschedule");
                        }}
                      >
                        {ConstantVariables.Reschedule_Button_Text}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleActionClose(`${props.appointment.televisitId}`);
                          globalStore.setOpenedModal("cancel");
                        }}
                      >
                        {ConstantVariables.Cancel_Visit_Button_Text}
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      onClick={() =>
                        onSendMessageMenuItemClick(
                          `${props.appointment.televisitId}`,
                          props.appointment
                        )
                      }
                    >
                      {ConstantVariables.Send_Message_Button_Text}
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Grid>
          </Grid>
          <>
            {props.appointment.isAdhocMeeting &&
              props.appointment.status.toLowerCase() === "scheduled" && (
                <span className="adhocMeetingSign">!</span>
              )}
            <span
              className={`status ${dashboardUtils.getBackGroundColor(
                props.appointment.status
              )}`}
            >
              {props.appointment.status}
            </span>
          </>
          <Grid
            container
            justify="space-between"
            className="appointment-details"
            onClick={() =>
              globalStore.setMeetingDetailedView(
                props.detailsData.appointments,
                props.detailsData.index,
                props.detailsData.appointments.length
              )
            }
          >
            <Grid item className="appointment-schedule">
              <Grid className="heading">
                {ConstantVariables.Appointment_Date_Time_Text}
              </Grid>
              <Grid className="content">
                {`${moment(
                  new Date(props.appointment.scheduleDateTimeTZD)
                ).format("DD/MM/YYYY")} ${moment(
                  new Date(props.appointment.scheduleDateTimeTZD)
                ).format("LT")}`}
              </Grid>
            </Grid>
            <Grid item className="provider-name">
              <Grid className="heading">{ConstantVariables.Provider_Text}</Grid>
              <Grid className="content">
                {`${props.appointment.providerRequest[0].title}. ${props.appointment.providerRequest[0].firstName} ${props.appointment.providerRequest[0].lastName}`}
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="space-between" className="action-buttons">
            {props.appointment.status !== MeetingStatus.Completed && (
              <Grid className="action-buttons-left">
                <Grid
                  item
                  className="join-call"
                  onClick={() => {
                    window.open(props.appointment.teamsMeetingUrl, "_blank");
                  }}
                >
                  {ConstantVariables.Join_Call_Button_Text}
                </Grid>
                {props.appointment.status !== MeetingStatus.Scheduled && (
                  <Grid
                    item
                    className="end-visit"
                    onClick={() => {
                      AppInsightMethods.trackAppInsightsEvent(
                        pageName,
                        Constants.Information,
                        `The "EndVisit" button has been clicked on the Meeting Card. televisitId: ${props.appointment.televisitId}`,
                        globalStore.loginUserEmail,
                        window.location.href,
                        ""
                      );
                      handleEndVisit(props.appointment);
                    }}
                  >
                    {ConstantVariables.End_Visit_Button_Text}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {showSpinner && (
          <Backdrop className={backdropClass.backdrop} open={showSpinner}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
};

export default Card;
