/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Grid, TableRow, Checkbox, TableCell } from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useObserver } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import { useGlobalStore } from "../../state/global-store";
import { ISearchPatientTableObj } from "../../services/api-types";
import { ConstantVariables } from "../../constants/Constants";

const Verified = () => {
  return (
    <Grid className={"patientGridVerified"}>
      <CheckIcon /> <span>Verified</span>
    </Grid>
  );
};

const NotVerified = () => {
  return (
    <Grid className={"patientGridNotVerified"}>
      <CloseIcon /> <span>Not Verified</span>
    </Grid>
  );
};

export default function MobilePatientGrid() {
  const pageName = "PatientGrid";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);

  return useObserver(() => {
    return (
      <Grid className={"mobile-patient-grid"}>
        {globalStore.patientGridObj.length > 0 &&
          globalStore.patientGridObj.map((value: ISearchPatientTableObj) => {
            return (
              <Grid
                key={value.patientId}
                className={"mobile-patient-grid-item"}
              >
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ minWidth: 30, padding: 0 }}
                    className={"mobile-patient-grid-item-checkbox"}
                  >
                    <Checkbox
                      color="primary"
                      onChange={(e, checked) =>
                        globalStore.onPatientCheckBoxChangeEvent(checked, value)
                      }
                      checked={value.selected}
                    />
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 165, padding: 0 }}>
                    <p>{`${value.firstName} ${
                      value.lastName !== undefined ? value.lastName : ""
                    }`}</p>
                    <p>{value.patientId === "" ? "--" : value.patientId}</p>
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 100, padding: 0 }}>
                    {value.isVerified ? <Verified /> : <NotVerified />}
                    <p>{""}</p>
                  </TableCell>
                </TableRow>
                <TableRow>{""}</TableRow>
                <TableRow>
                  <TableCell>{""}</TableCell>
                  <TableCell align="left" style={{ minWidth: 165, padding: 0 }}>
                    <p style={{ color: "#8a8a8a" }}>
                      {ConstantVariables.Recent_Visit_Date_Text}
                    </p>
                    <p>
                      {value.recentVisit && value.recentVisit !== "--"
                        ? moment(new Date(value.recentVisit)).format(
                            "MMMM YYYY"
                          )
                        : "--"}
                    </p>
                  </TableCell>
                </TableRow>
              </Grid>
            );
          })}
      </Grid>
    );
  });
}
