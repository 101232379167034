/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Tooltip,
  withStyles,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { isMobile } from "react-device-detect";
import { useObserver } from "mobx-react-lite";
import { useGlobalStore } from "../../state/global-store";
import statusAvailable from "../../assets/status_available.svg";
import EllipsisMenuIcon from "../../assets/ellipsis_menu_icon.svg";
import MeetingInfo from "./MeetingInfo";
import Participants from "./Participants";
import { IAppointmentList } from "../../services/api-types";
import DateLeftIcon from "../../assets/date_left_icon.svg";
import DateRightIcon from "../../assets/date_right_icon.svg";
import LeftArrowDisabledIcon from "../../assets/left_arrow_disabled_icon.svg";
import RightArrowDisabledIcon from "../../assets/right_arrow_disabled_icon.svg";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#FFF",
    color: "#3D3D3D",
    boxShadow: "0px 2px 7px #00000021",
    fontSize: 16,
  },
  arrow: {
    color: "#FFF",
  },
}))(Tooltip);

interface MeetingDetailedViewProps {
  appointments: IAppointmentList[];
  refreshMeetings: () => void;
}

const MeetingDetailedView = (props: MeetingDetailedViewProps) => {
  const globalStore = useGlobalStore();
  const pageName = "MeetingDetailedView";
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, pageName);
  const appointment = globalStore.meetingDetailedView;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    globalStore.setActionMenuSelectedMeeting(globalStore.meetingDetailedView);
    trackComponent();
  }, []);

  /* Method on back button click on Meeting Detailed view on keyboard action */
  const setMeetingOnKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setMeetingToNull();
    }
  };

  /* Method on back button click on Meeting Detailed view */
  const setMeetingToNull = () => {
    try {
      globalStore.setMeetingDetailedView([], null, 0);
      globalStore.setActionMenuSelectedMeeting(null);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The "Back" button has been clicked to go back to the appointment list page.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      props.refreshMeetings();
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at setMeetingToNull, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to go to next meeting in the list while in meeting detailed view */
  const goToNextMeeting = () => {
    try {
      globalStore.setMeetingDetailedView(
        props.appointments,
        globalStore.currentMeetingDetailedViewIndex !== null
          ? globalStore.currentMeetingDetailedViewIndex + 1
          : null,
        globalStore.currentMeetingListLength
      );
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The "Previous" button has been clicked to view the previous appointment detail.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at goToNextMeeting, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to go to previous meeting in the list while in meeting detailed view */
  const goToPreviousMeeting = () => {
    try {
      globalStore.setMeetingDetailedView(
        props.appointments,
        globalStore.currentMeetingDetailedViewIndex !== null
          ? globalStore.currentMeetingDetailedViewIndex - 1
          : null,
        globalStore.currentMeetingListLength
      );
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The "Previous" button has been clicked to view the previous appointment detail.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at goToPreviousMeeting, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for opening send message into meeting room modal */
  const onSendMessageClick = (appointmentDetail: IAppointmentList | null) => {
    try {
      setAnchorEl(null);
      if (appointmentDetail !== null) {
        globalStore.updateTelevisitId(
          appointmentDetail.televisitId,
          appointmentDetail.teamsMeetingUrl
        );
        globalStore.setShowSendMessagePopup("open");
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Send Message button has been clicked, to send messages to the meeting room.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for opening send message into meeting room modal on keyboard event */
  const onSendMessageKeyDown = (
    event: any,
    appointmentDetail: IAppointmentList | null
  ) => {
    try {
      setAnchorEl(null);
      if (appointmentDetail && event.key === "Enter") {
        onSendMessageClick(appointmentDetail);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSendMessageKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return useObserver(() => {
    return (
      <Grid className="meeting-detailed-view">
        {!isMobile &&
        !(globalStore.windowWidth <= ConstantVariables.mobileMaxWidth) ? (
          <Grid
            container
            className="navigation-buttons"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item
              onClick={() => setMeetingToNull()}
              onKeyDown={(e) => {
                setMeetingOnKeydown(e);
              }}
              className="pointer"
            >
              <Grid
                container
                className="button back noWrap"
                alignItems="center"
              >
                <LightTooltip title="Back to Home Page" arrow>
                  <img
                    className="back-icon"
                    src={DateLeftIcon}
                    alt="Back Arrow Icon"
                  />
                </LightTooltip>
                <span className="back text">{ConstantVariables.Back_Text}</span>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                className="button nextAndPrevious noWrap"
                alignItems="center"
              >
                {globalStore.currentMeetingDetailedViewIndex === 0 ? (
                  <Grid className="disabled">
                    <img
                      className="previous-icon"
                      src={LeftArrowDisabledIcon}
                      alt="Previous Meeting Disabled Arrow Icon"
                    />
                    <span className={`previous text`}>
                      {ConstantVariables.Previous_Text}
                    </span>{" "}
                  </Grid>
                ) : (
                  <Grid
                    className="pointer"
                    onClick={() => goToPreviousMeeting()}
                  >
                    <LightTooltip title="Previous patient" arrow>
                      <img
                        className="previous-icon"
                        src={DateLeftIcon}
                        alt="Previous Meeting Arrow Icon"
                      />
                    </LightTooltip>
                    <span className={`previous text`}>
                      {ConstantVariables.Previous_Text}
                    </span>
                  </Grid>
                )}
                {globalStore.currentMeetingDetailedViewIndex ===
                globalStore.currentMeetingListLength - 1 ? (
                  <Grid className="disabled">
                    <span className={`next text`}>
                      {ConstantVariables.Next_Text}
                    </span>
                    <img
                      className="next-icon"
                      src={RightArrowDisabledIcon}
                      alt="Next Meeting Disabled Arrow Icon"
                    />{" "}
                  </Grid>
                ) : (
                  <Grid className="pointer" onClick={() => goToNextMeeting()}>
                    <span className={`next text`}>
                      {ConstantVariables.Next_Text}
                    </span>
                    <LightTooltip title="Next patient" arrow>
                      <img
                        className="next-icon"
                        src={DateRightIcon}
                        alt="Next Meeting Arrow Icon"
                      />
                    </LightTooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container className="detailHeadMainMobile">
            <Grid item className="detailHeadMobile" xs={11}>
              <LightTooltip
                title="Back to Home Page"
                arrow
                onClick={() => setMeetingToNull()}
                onKeyDown={(e) => {
                  setMeetingOnKeydown(e);
                }}
              >
                <img
                  className="back-icon-mobile"
                  src={DateLeftIcon}
                  alt="Back Arrow Icon"
                />
              </LightTooltip>
              <img
                src={statusAvailable}
                alt={"Available"}
                style={{ width: "12px", marginRight: "8.73px" }}
              />
              {`
            ${appointment?.patientLastName}, ${appointment?.patientFirstName}
          `}
            </Grid>
            <Grid item xs={1}>
              <img
                src={EllipsisMenuIcon}
                alt="simple-menu"
                className={`pointer`}
                role="button"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ width: "11px" }}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem
                  onClick={() => onSendMessageClick(appointment)}
                  onKeyDown={(e) => onSendMessageKeyDown(e, appointment)}
                >
                  Send Message
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}

        <Grid>
          <MeetingInfo />
        </Grid>
        {isMobile &&
          globalStore.windowWidth <= ConstantVariables.mobileMaxWidth && (
            <Divider style={{ marginBottom: "10px" }} />
          )}
        <Grid>
          <Participants />
        </Grid>
      </Grid>
    );
  });
};

export default MeetingDetailedView;
