/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  InputLabel,
  FormControl,
  TextField,
  MenuItem,
  CircularProgress,
  Button,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import { useObserver } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { useGlobalStore } from "../../state/global-store";
import { apiService } from "../../services/api-service";
import { RescheduleAppointmentObj } from "../../services/api-types";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";
import { AppInsightMethods } from "../../AppInsightMethods";
import { helperMethods } from "../hooks/helper";

interface IDurationMin {
  key: number;
  value: string;
}

interface RescheduleProps {
  updateMeetings: () => void;
}

const mockDuration: Array<IDurationMin> = [
  {
    key: 5,
    value: "5 minutes",
  },
  {
    key: 10,
    value: "10 minutes",
  },
  {
    key: 15,
    value: "15 minutes",
  },
  {
    key: 30,
    value: "30 minutes",
  },
  {
    key: 45,
    value: "45 minutes",
  },
  {
    key: 60,
    value: "60 minutes",
  },
];

const RescheduleAppointment = (props: RescheduleProps) => {
  const pageName = "RescheduleAppointment";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const helper = helperMethods();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState("");
  const [duration, setDuration] = useState(0);
  const [showDurationMessage, setShowDurationErrorMgs] = useState(false);
  const [showDateErrorMessage, setShowDateErrorMgs] = useState(false);
  const [showTimeErrorMessage, setShowTimeErrorMgs] = useState(false);
  const [rescheduleData, setRescheduleData] =
    useState<RescheduleAppointmentObj | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState("");

  const rescheduleAppointment = () => {
    try {
      setIsFetching(true);
      if (rescheduleData) {
        apiService
          .rescheduleAppointment(rescheduleData)
          .then((data: any) => {
            setIsFetching(false);
            if (data) {
              setIsError("");
              resetReschedule();
              helper.setDashboardAlertMessage(data, "success");
              props.updateMeetings();
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                Constants.Information,
                `Rescheduled Appointment API call Success.`,
                globalStore.loginUserEmail,
                window.location.href,
                "success"
              );
            }
          })
          .catch((error: any) => {
            setIsFetching(false);
            setIsError(error.message);
            AppInsightMethods.TrackAppInsightsException(
              `Error at reschedule API call, method name rescheduleAppointment, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at rescheduleAppointment, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    if (rescheduleData) {
      rescheduleAppointment();
    }
  }, [rescheduleData]);

  useEffect(() => {
    if (globalStore.actionMenuSelectedMeeting) {
      setSelectedDate(
        `${moment(
          new Date(globalStore.actionMenuSelectedMeeting?.scheduleDateTimeTZD)
        ).format("DD/MM/YYYY")}`
      );
      setSelectedTime(
        `${moment(
          new Date(globalStore.actionMenuSelectedMeeting?.scheduleDateTimeTZD)
        ).format()}`
      );
      setDuration(globalStore.actionMenuSelectedMeeting.duration);
    }
  }, [globalStore.actionMenuSelectedMeeting]);
  /* Method for closing Reschedule appointment modal */
  const handleClickClose = () => {
    resetReschedule();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Rescheduled Appointment popup close button click.`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };
  /* Method for closing Reschedule appointment modal on keyboard enter */
  const onCloseKeyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      resetReschedule();
    }
  };

  /* onChange event handler for date picker */
  const handleDateChange = (date: any) => {
    try {
      const dateFormat = moment(date).format("DD-MM-YYYY");
      setSelectedDate(dateFormat === "Invalid date" ? "" : dateFormat);
      setShowDateErrorMgs(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleDateChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onChange event handler for time picker */
  const handleTimeChange = (date: any) => {
    try {
      setSelectedTime(date);
      setShowTimeErrorMgs(false);
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleTimeChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for resetting reschedule meeting form */
  const resetReschedule = () => {
    try {
      setShowTimeErrorMgs(false);
      setShowDateErrorMgs(false);
      setRescheduleData(null);
      setIsError("");
      globalStore.setOpenedModal("none");
      globalStore.setActionMenuSelectedMeeting(null);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Cancel button has been clicked on the reschedule Appointment popup.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at resetReschedule, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for confirming a meeting reschedule and calling api service */
  const confirmReschedule = () => {
    try {
      if (!selectedDate) {
        setShowDateErrorMgs(true);
      } else if (!selectedTime) {
        setShowTimeErrorMgs(true);
      } else {
        const dateObj = moment(selectedDate, "DD-MM-YYYY");
        const hours = new Date(selectedTime).getHours();
        const minutes = new Date(selectedTime).getMinutes();
        dateObj.set({ h: hours, m: minutes });
        const appointmentReschedule = {
          televisitId: globalStore.actionMenuSelectedMeeting?.televisitId,
          scheduledDateTime: moment(dateObj).format(),
          duration,
        };
        setRescheduleData({ ...appointmentReschedule });
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `The Confirm button has been clicked on the reschedule Appointment popup.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at confirmReschedule, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    const appointment = globalStore.actionMenuSelectedMeeting;
    return (
      <Modal
        open={globalStore.openedModal === "reschedule"}
        onClose={handleClickClose}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className="reschedule-appointment-modal"
        >
          {isFetching && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className="spinner"
            >
              <CircularProgress size={90} />
            </Grid>
          )}
          <Grid item className="content">
            <Grid container justify="space-between">
              <Grid item className="heading">
                Reschedule Visit
              </Grid>
              <Grid item>
                <CloseIcon
                  onClick={() => {
                    handleClickClose();
                  }}
                  onKeyDown={(e) => onCloseKeyDownHandler(e)}
                  tabIndex={0}
                  className={"reschedule-visit-closeIcon pointer"}
                />
              </Grid>
            </Grid>
            <Grid className="side-heading">
              Select the new date and time to reschedule the visit.
            </Grid>

            <Grid container className="details">
              <Grid item className="column-one">
                <Grid className="patient">
                  <Grid className="header">
                    {ConstantVariables.Meeting_List_Header_Column_One_Text}
                  </Grid>
                  <Grid className="text">{`${appointment?.patientFirstName} ${appointment?.patientLastName}`}</Grid>
                </Grid>
                <Grid className="appointment">
                  <Grid className="header">
                    {ConstantVariables.Appointment_Date_Time_Text}
                  </Grid>
                  <Grid className="text">{`${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("DD/MM/YYYY")} ${moment(
                    new Date(
                      appointment?.scheduleDateTimeTZD
                        ? appointment?.scheduleDateTimeTZD
                        : ""
                    )
                  ).format("LT")}`}</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid className="patient-id">
                  <Grid className="header">
                    {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                  </Grid>
                  <Grid className="text">{`${appointment?.patientId}`}</Grid>
                </Grid>
                <Grid className="provider">
                  <Grid className="header">
                    {ConstantVariables.Provider_Text}
                  </Grid>
                  <Grid className="text">{`${appointment?.providerRequest[0].title}. ${appointment?.providerRequest[0].firstName} ${appointment?.providerRequest[0].lastName}`}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="date-time-picker">
              <Grid item className="date-picker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <InputLabel className="label">
                    {ConstantVariables.Date_Text}
                  </InputLabel>
                  <KeyboardDatePicker
                    variant="inline"
                    id="date-picker-dialog1"
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    value={
                      selectedDate === ""
                        ? null
                        : moment(selectedDate, ["DD/MM/YYYY"])
                    }
                    onChange={(date) => handleDateChange(date)}
                    placeholder={"DD/MM/YYYY"}
                    autoOk
                    helperText={
                      showDateErrorMessage
                        ? ConstantVariables.Select_Appointment_Date_Text
                        : ""
                    }
                    error={showDateErrorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item className="time-picker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <InputLabel className="label">
                    {ConstantVariables.Time_Text}
                  </InputLabel>
                  <KeyboardTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    value={selectedTime === "" ? null : selectedTime}
                    onChange={handleTimeChange}
                    placeholder={"HH:MM AM"}
                    autoOk
                    error={showTimeErrorMessage}
                    helperText={
                      showTimeErrorMessage
                        ? ConstantVariables.Select_Appointment_Time_Text
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid className="duration">
              <InputLabel className="label">
                {ConstantVariables.Duration_Text}
              </InputLabel>
              <FormControl>
                <TextField
                  variant="outlined"
                  id="select-outlined"
                  select
                  error={showDurationMessage}
                  label={duration ? "" : "In minutes"}
                  value={duration}
                  onChange={(e) => {
                    setDuration(Number(e.target.value));
                    setShowDurationErrorMgs(false);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  helperText={
                    showDurationMessage
                      ? ConstantVariables.Select_Appointment_Duration_Text
                      : ""
                  }
                  InputLabelProps={{
                    shrink: false,
                  }}
                  className="dropdown"
                >
                  {mockDuration.map((val: IDurationMin) => {
                    return (
                      <MenuItem
                        className="dropdownListFont"
                        key={val.key}
                        value={val.key}
                      >
                        {val.value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            {!!isError && (
              <Grid className="error rescheduleError">{isError}</Grid>
            )}
            <Grid container className="reschedule-buttons" justify="flex-end">
              <Button
                variant="contained"
                className={"teleVisitBtn teleVisitRescheduleCancelBtn"}
                onClick={() => resetReschedule()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    resetReschedule();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={"teleVisitBtn teleVisitSuccessBtn"}
                onClick={() => confirmReschedule()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    confirmReschedule();
                  }
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  });
};

export default RescheduleAppointment;
