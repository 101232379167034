/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { apiService } from "../../services/api-service";
import { ISearchPatientTableObj } from "../../services/api-types";
import { useGlobalStore } from "../../state/global-store";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
} from "../../constants/Constants";

interface IExistingPatientSearchProps {
  setAlert: (val: boolean) => void;
  setError: (val: string) => void;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ExistingPatientSearch(
  props: IExistingPatientSearchProps
) {
  const re = /^0+$/;
  const pageName = "ExistingPatientSearch";
  const phoneText = "phone";
  const emailText = "email";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const classes = useStyles();
  const [patientId, setPatientId] = useState("");
  const [patientPhoneNumber, setPatientPhoneNumber] = useState("");
  const [patientEmailAddress, setPatientEmailAddress] = useState("");
  const [patientPhoneValidationMgs, setPatientPhoneValidationMgs] =
    useState("");
  const [patientEmailValidationMgs, setPatientEmailValidationMgs] =
    useState("");
  const [isFetching, setIsFetching] = useState(false);
  const trackComponent = useTrackMetric(appInsights, pageName);
  useEffect(() => {
    trackComponent();
  }, []);

  /* Reset method for the form */
  const clearAllFields = () => {
    try {
      setPatientId("");
      setPatientPhoneNumber("");
      setPatientEmailAddress("");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at clearAllFields, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Validations for the search patient form */
  const validateSearchPatient = () => {
    try {
      let isValidFailed = false;
      if (
        patientPhoneNumber.trim() !== "" &&
        (patientPhoneNumber.trim().replace(/-/g, "").length < 10 ||
          re.test(patientPhoneNumber.trim().replace(/-/g, "")))
      ) {
        setPatientPhoneValidationMgs(
          ConstantVariables.Patient_Phone_Validation_Text
        );
        isValidFailed = true;
      }

      if (
        patientEmailAddress.trim() !== "" &&
        !validator.isEmail(patientEmailAddress)
      ) {
        setPatientEmailValidationMgs(
          ConstantVariables.Patient_Email_Validation_Text
        );
        isValidFailed = true;
      }
      return isValidFailed;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at validateSearchPatient, Component: ${pageName}, Error: ${error}`
      );
      return false;
    }
  };

  /* Api call for patient search api */
  const callSearchApi = () => {
    try {
      let searchText = "";
      if (patientId.trim() !== "") {
        searchText = patientId.trim();
      } else if (patientPhoneNumber.trim().replace(/-/g, "") !== "") {
        searchText = patientPhoneNumber.trim().replace(/-/g, "");
      } else if (patientEmailAddress.trim() !== "") {
        searchText = patientEmailAddress.trim();
      }
      if (searchText !== "") {
        globalStore.resetPatientGridObj();
        props.setError("");
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Check Patient button has been clicked on the Appointment Creation screen. Search Text: ${searchText}`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
        setIsFetching(true);
        apiService
          .searchPatient(searchText)
          .then((result: any) => {
            setIsFetching(false);
            if (result.length > 0) {
              const addNewPatientObjToSearchResult: ISearchPatientTableObj[] =
                [];
              result.forEach((value: ISearchPatientTableObj, index: number) => {
                const iSearchPatientTableObj: ISearchPatientTableObj =
                  {} as ISearchPatientTableObj;
                iSearchPatientTableObj.patientId = value.patientId;
                iSearchPatientTableObj.firstName = value.firstName;
                iSearchPatientTableObj.middleName = value.middleName;
                iSearchPatientTableObj.lastName = value.lastName;
                iSearchPatientTableObj.dob = value.dob;
                iSearchPatientTableObj.emails = value.emails;
                iSearchPatientTableObj.phones = value.phones;
                iSearchPatientTableObj.sendSMS = value.sendSMS;
                iSearchPatientTableObj.isVerified = value.isVerified;
                iSearchPatientTableObj.recentVisit = value.recentVisit;
                iSearchPatientTableObj.selected = index === 0;
                addNewPatientObjToSearchResult.push(iSearchPatientTableObj);
              });
              globalStore.resetPatientGridObj();
              globalStore.resetPatientObj();
              globalStore.setPatientGridArray([
                ...addNewPatientObjToSearchResult,
              ]);
              clearAllFields();
            } else {
              props.setAlert(true);
            }
          })
          .catch((error: any) => {
            setIsFetching(false);
            if (error.message === "Not Found") {
              props.setAlert(true);
            } else {
              AppInsightMethods.TrackAppInsightsException(
                `Error at searchPatient API call, Component: ${pageName}, Error: ${error}`
              );
              props.setError(ConstantVariables.Common_Error_Text);
            }
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at callSearchApi, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* MEthod called after clicking on check patient button */
  const onCheckPatientClick = () => {
    try {
      if (
        patientId.trim() === "" &&
        patientPhoneNumber.trim() === "" &&
        patientEmailAddress.trim() === ""
      ) {
        props.setAlert(true);
        return;
      }
      const isValidFailed = validateSearchPatient();
      if (isValidFailed) {
        return;
      }
      props.setAlert(false);

      callSearchApi();
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCheckPatientClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  const normalizePhoneInput = (value: string) => {
    try {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, "");

      const cvLength = currentValue.length;

      if (cvLength < 4) return `${currentValue}`;
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at normalizePhoneInput, Component: ${pageName}, Error: ${error}`
      );
      return value;
    }
  };
  /* onChange event handler for email and phone number in patient search form */
  const onSearchPatientChangeEvent = (value: string, fieldName: string) => {
    try {
      switch (fieldName) {
        case phoneText:
          if (value.length <= 12) {
            setPatientPhoneNumber(normalizePhoneInput(value));
          }
          break;
        case emailText:
          setPatientEmailAddress(value);
          break;
        default:
          break;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSearchPatientChangeEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* onBlur event for Search patient form fields */
  const onSearchPatientBlurEvent = (fieldName: string) => {
    try {
      switch (fieldName) {
        case phoneText:
          if (
            patientPhoneNumber.trim().length > 0 &&
            (patientPhoneNumber.trim().replace(/-/g, "").length < 10 ||
              re.test(patientPhoneNumber.trim().replace(/-/g, "")))
          ) {
            setPatientPhoneValidationMgs(
              ConstantVariables.Patient_Phone_Validation_Text
            );
          } else {
            setPatientPhoneValidationMgs("");
          }
          break;
        case emailText:
          if (
            patientEmailAddress.trim().length > 0 &&
            !validator.isEmail(patientEmailAddress)
          ) {
            setPatientEmailValidationMgs(
              ConstantVariables.Patient_Email_Validation_Text
            );
          } else {
            setPatientEmailValidationMgs("");
          }
          break;
        default:
          break;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onSearchPatientBlurEvent, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Keyboard click event for check patient button */
  const onCheckPatientKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        onCheckPatientClick();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onCheckPatientKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return (
    <Grid className={"searchPatientComponent"}>
      <Grid className="searchPatientIdContainer createInputContainer">
        <InputLabel>
          {ConstantVariables.Meeting_List_Header_Column_Two_Text}
        </InputLabel>
        <TextField
          id="searchPatientIdTextField"
          variant="outlined"
          placeholder={"AAA###"}
          className={"scheduleInputTextField"}
          value={patientId}
          onChange={(e) => setPatientId(e.target.value)}
          autoComplete="off"
        />
      </Grid>
      <Grid className={"searchPatientOr"}>Or</Grid>
      <Grid className="searchPatientPhoneNumberContainer createInputContainer">
        <InputLabel>{ConstantVariables.Patient_Phone_Number_Text}</InputLabel>
        <TextField
          id="searchPatientPhoneNoTextField"
          variant="outlined"
          placeholder={"+1 212-999-9999"}
          className={"scheduleInputTextField"}
          value={patientPhoneNumber}
          onChange={(e) =>
            onSearchPatientChangeEvent(e.target.value, phoneText)
          }
          onBlur={() => onSearchPatientBlurEvent(phoneText)}
          error={!!patientPhoneValidationMgs}
          helperText={patientPhoneValidationMgs || ""}
          autoComplete="off"
        />
      </Grid>
      <Grid className={"searchPatientOr"}>Or</Grid>
      <Grid className="searchPatientEmailAddressContainer createInputContainer">
        <InputLabel>{ConstantVariables.Patient_Email_Address_Text}</InputLabel>
        <TextField
          id="searchPatientEmailTextField"
          variant="outlined"
          placeholder={"abcd@xyz.com"}
          className={"scheduleInputTextField"}
          value={patientEmailAddress}
          onChange={(e) =>
            onSearchPatientChangeEvent(e.target.value, emailText)
          }
          onBlur={() => onSearchPatientBlurEvent(emailText)}
          error={!!patientEmailValidationMgs}
          helperText={patientEmailValidationMgs || ""}
          autoComplete="off"
        />
      </Grid>
      <Grid className="add-patient-btn-container">
        <Button
          color="primary"
          className={"addCheckBtn"}
          onClick={onCheckPatientClick}
          onKeyDown={(e) => onCheckPatientKeyDown(e)}
        >
          {ConstantVariables.Check_Patient_Button_Text}
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
