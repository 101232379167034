import React from "react";
import { useObserver } from "mobx-react-lite";
import { Grid } from "@material-ui/core";
import CreateNewAppointment from "../components/CreateAppointment/CreateNewAppointment";
import Header from "../components/Header/Header";
import { appInsights } from "../AppInsights";
import { useGlobalStore } from "../state/global-store";

function SchedulingHomePage() {
  const globalStore = useGlobalStore();
  appInsights.trackPageView({
    name: "VirtualVisits-CreateNewAppointment",
    uri: window.location.href,
    properties: {
      user: globalStore.loginUserEmail,
    },
  });
  return useObserver(() => {
    return (
      <Grid className="schedulingAppointment">
        <Header />
        <CreateNewAppointment />
      </Grid>
    );
  });
}

export default SchedulingHomePage;
