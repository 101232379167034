/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import moment from "moment";
import { useObserver } from "mobx-react-lite";
import arrowDownEnabled from "../../assets/arrow_down_enabled.svg";
import arrowUpEnabled from "../../assets/arrow_up_enabled.svg";
import arrowDownDisabled from "../../assets/arrow_down_disabled.svg";
import arrowUpDisabled from "../../assets/arrow_up_disabled.svg";
import EllipsisMenuIcon from "../../assets/ellipsis_menu_icon.svg";
import { useGlobalStore } from "../../state/global-store";
import { IAppointmentList, IEndCall, SortType } from "../../services/api-types";
import { dashboardUtils } from "../../utils/dashboard-utils";
import MeetingDetailedView from "./MeetingDetailedView";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  AppInsightConstants as Constants,
  MeetingStatus,
} from "../../constants/Constants";
import { apiService } from "../../services/api-service";
import { helperMethods } from "../hooks/helper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyle = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 999,
  },
}));

interface IAppointmentListTableProps {
  refreshMeetings: () => void;
}

const AppointmentListTable = (props: IAppointmentListTableProps) => {
  const pageName = "AppointmentListTable";
  const appInsights = useAppInsightsContext();
  const globalStore = useGlobalStore();
  const alignLeftText = "left";
  const descText = "Desc";
  const ascText = "Asc";
  const noneText = "None";
  const headerCellText = "headerCell";
  const classes = useStyles();
  const backdropClass = useStyle();
  const helper = helperMethods();
  const [patientNameSortType, setPatientNameSortType] =
    useState<SortType>(noneText);
  const [appointmentTimeSortType, setAppointmentTimeSortType] =
    useState<SortType>(noneText);
  const [providerSortType, setProviderSortType] = useState<SortType>(noneText);
  const [currentSortColumn, setCurrentSortColumn] = useState("");
  const [anchorEls, setAnchorEls] = useState({} as any);
  const [showSpinner, setShowSpinner] = useState(false);

  const trackComponent = useTrackMetric(appInsights, pageName);

  useEffect(() => {
    trackComponent();
  }, []);
  /* Method for opening more actions menu */
  const handleActionClick = (
    email: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    anchorEls[email] = event?.target;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Action Menu Opened, PatientId: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };

  /* Method for closing more actions menu */
  const handleActionClose = (email: string) => {
    anchorEls[email] = null;
    setAnchorEls({ ...anchorEls });
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      Constants.Information,
      `Action Menu Closed, PatientId: ${email}`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  };
  /* method for ending visit & marking it as complete */
  const handleEndVisit = (email: string, data: IAppointmentList) => {
    try {
      anchorEls[email] = null;
      setAnchorEls({ ...anchorEls });
      const endMeeting: IEndCall = {
        participantId: "pID",
        televisitId: data.televisitId,
        timestamp: new Date().toISOString(),
        meetingUrl: data.teamsMeetingUrl,
        chatId: "",
      };
      setShowSpinner(true);
      apiService
        .endCall(endMeeting)
        .then((res: any) => {
          if (res === "success") {
            AppInsightMethods.trackAppInsightsEvent(
              pageName,
              Constants.Information,
              `The "EndVisit" API call success. televisitId: ${data.televisitId}`,
              globalStore.loginUserEmail,
              window.location.href,
              ""
            );
            helper.setDashboardAlertMessage(
              "Visit ended successfully.",
              "success"
            );
            props.refreshMeetings();
          }
          setShowSpinner(false);
        })
        .catch((error: any) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at "EndVisit" API call, handleEndVisit, Component: ${pageName}, Error: ${error}`
          );
          setShowSpinner(false);
        });
    } catch (error: any) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleEndVisit, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on patient name */
  const patientNameClickHandler = () => {
    try {
      if (
        patientNameSortType === noneText ||
        patientNameSortType === descText
      ) {
        setPatientNameSortType(ascText);
      } else if (patientNameSortType === ascText) {
        setPatientNameSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Patient Name Column clicked for the sorting. SortType : ${patientNameSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("patientName");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientNameClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for sorting meeting list based on patient name using keyboard event */
  const patientNameKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        patientNameClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at patientNameKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for sorting meeting list based on meeting time */
  const appointmentTimeClickHandler = () => {
    try {
      if (
        appointmentTimeSortType === noneText ||
        appointmentTimeSortType === descText
      ) {
        setAppointmentTimeSortType(ascText);
      } else if (appointmentTimeSortType === ascText) {
        setAppointmentTimeSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Appointment Time Column clicked for the sorting. SortType : ${appointmentTimeSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("appointmentTime");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentTimeClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for sorting meeting list based on meeting time using keyboard event */
  const appointmentTimeKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        appointmentTimeClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at appointmentTimeKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for sorting meeting list based on provider name */
  const providerClickHandler = () => {
    try {
      if (providerSortType === noneText || providerSortType === descText) {
        setProviderSortType(ascText);
      } else if (providerSortType === ascText) {
        setProviderSortType(descText);
      }
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        Constants.Information,
        `Provider Name Column clicked for the sorting. SortType : ${providerSortType}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      setCurrentSortColumn("provider");
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at providerClickHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };
  /* Method for sorting meeting list based on provider name using keyboard event */
  const providerKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        providerClickHandler();
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at providerKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method to get sorting icon based on the sorting order */
  const getSortIcon = (columnName: string): JSX.Element | null => {
    try {
      switch (columnName) {
        case "patientName":
          return getIcon(patientNameSortType, columnName);
        case "appointmentTime":
          return getIcon(appointmentTimeSortType, columnName);
        case "provider":
          return getIcon(providerSortType, columnName);
        default:
          return null;
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at gerSortIcon, Component: ${pageName}, Error: ${error}`
      );
      return null;
    }
  };

  /* Method to get sorting icon based on the sorting order */
  const getIcon = (
    sortType: SortType,
    columnName: string
  ): JSX.Element | null => {
    return sortType === ascText ? (
      <>
        {currentSortColumn === columnName ? (
          <img
            className="sort-icons"
            src={arrowUpEnabled}
            alt="sort-column-ascending-enabled"
          />
        ) : (
          <img
            className="sort-icons"
            src={arrowUpDisabled}
            alt="sort-column-ascending-disabled"
          />
        )}
      </>
    ) : sortType === descText ? (
      <>
        {currentSortColumn === columnName ? (
          <img
            className="sort-icons"
            src={arrowDownEnabled}
            alt="sort-column-descending-enabled"
          />
        ) : (
          <img
            className="sort-icons"
            src={arrowDownDisabled}
            alt="sort-column-descending-disabled"
          />
        )}
      </>
    ) : (
      <>
        <img
          className="sort-icons"
          src={arrowDownDisabled}
          alt="sort-column-disabled"
        />
        <img className="" src={arrowUpDisabled} alt="sort-column-disabled" />
      </>
    );
  };

  return useObserver(() => {
    /* filtering and sorting of meeting list */
    let appointments: IAppointmentList[] =
      globalStore.statusTab !== MeetingStatus.All
        ? globalStore.appointmentsList?.filter(
            (val: IAppointmentList) => val.status === globalStore.statusTab
          )
        : globalStore.appointmentsList;

    if (
      (patientNameSortType === ascText || patientNameSortType === descText) &&
      currentSortColumn === "patientName"
    ) {
      if (patientNameSortType === ascText) {
        appointments = appointments.sort((a, b) =>
          a.patientFirstName.toLowerCase() > b.patientFirstName.toLowerCase()
            ? 1
            : -1
        );
      } else {
        appointments = appointments.sort((a, b) =>
          a.patientFirstName.toLowerCase() < b.patientFirstName.toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (
      (providerSortType === ascText || providerSortType === descText) &&
      currentSortColumn === "provider"
    ) {
      if (providerSortType === ascText) {
        appointments = appointments.sort((a, b) =>
          a.providerRequest[0].firstName.toLowerCase() >
          b.providerRequest[0].firstName.toLowerCase()
            ? 1
            : -1
        );
      } else {
        appointments = appointments.sort((a, b) =>
          a.providerRequest[0].firstName.toLowerCase() <
          b.providerRequest[0].firstName.toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (
      (appointmentTimeSortType === ascText ||
        appointmentTimeSortType === descText) &&
      currentSortColumn === "appointmentTime"
    ) {
      if (appointmentTimeSortType === ascText) {
        appointments = appointments.sort((a, b) => {
          return (
            new Date(a.scheduleDateTimeTZD).getTime() -
            new Date(b.scheduleDateTimeTZD).getTime()
          );
        });
      } else {
        appointments = appointments.sort((a, b) => {
          return (
            new Date(b.scheduleDateTimeTZD).getTime() -
            new Date(a.scheduleDateTimeTZD).getTime()
          );
        });
      }
    }

    if (globalStore.searchMeetingString) {
      appointments = appointments.filter((appointment) => {
        if (
          globalStore?.searchMeetingString &&
          (appointment.patientId
            .toLowerCase()
            .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            appointment.patientFirstName
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            appointment.patientLastName
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1 ||
            `${appointment.patientFirstName} ${appointment.patientLastName}`
              .toLowerCase()
              .indexOf(globalStore?.searchMeetingString?.toLowerCase()) > -1)
        ) {
          return true;
        }
        return false;
      });
    }
    /* Method for opening send message into meeting room modal */
    const onSendMessageMenuItemClick = (
      value: string,
      data: IAppointmentList
    ) => {
      try {
        anchorEls[value] = null;
        setAnchorEls({ ...anchorEls });
        globalStore.updateTelevisitId(data.televisitId, data.teamsMeetingUrl);
        globalStore.setShowSendMessagePopup("open");
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          Constants.Information,
          `The Send Message Button has been Clicked on the Dashboard.`,
          globalStore.loginUserEmail,
          window.location.href,
          ""
        );
      } catch (error) {
        AppInsightMethods.TrackAppInsightsException(
          `Error at onSendMessageMenuItemClick, Component: ${pageName}, Error: ${error}`
        );
      }
    };
    return (
      <>
        {!globalStore.meetingDetailedView ? (
          appointments && appointments.length > 0 ? (
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={"appointmentListHeader"}>
                  <TableRow>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => patientNameClickHandler()}
                      onKeyDown={(e) => patientNameKeyDown(e)}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_One_Text}
                      {getSortIcon("patientName")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={headerCellText}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Two_Text}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={headerCellText}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Three_Text}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => appointmentTimeClickHandler()}
                      onKeyDown={(e) => appointmentTimeKeyDown(e)}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Four_Text}
                      {getSortIcon("appointmentTime")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={headerCellText}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Five_Text}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      style={{ minWidth: 200 }}
                      className={`${headerCellText} sortIcon`}
                      onClick={() => providerClickHandler()}
                      onKeyDown={(e) => providerKeyDown(e)}
                      tabIndex={0}
                    >
                      {ConstantVariables.Meeting_List_Header_Column_Six_Text}
                      {getSortIcon("provider")}
                    </TableCell>
                    <TableCell
                      align={alignLeftText}
                      className={headerCellText}
                      tabIndex={0}
                    >
                      {""}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"appointmentListBody"}>
                  {appointments?.map((row: IAppointmentList, index: number) => (
                    <TableRow key={`${row.patientId}-${index}`}>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        <span className="bold">{`${row.patientFirstName} ${row.patientLastName}`}</span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        {row.patientId}
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        {moment(new Date(row.scheduleDateTimeTZD)).format(
                          "DD/MM/YYYY"
                        )}
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        {moment(new Date(row.scheduleDateTimeTZD)).format("LT")}
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        {row.isAdhocMeeting &&
                          row.status.toLowerCase() === "scheduled" && (
                            <span className="adhocMeetingSign">!</span>
                          )}
                        <span
                          className={`statusColor ${dashboardUtils.getBackGroundColor(
                            row.status
                          )}`}
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell
                        align={alignLeftText}
                        className="bodyCell"
                        onClick={() =>
                          globalStore.setMeetingDetailedView(
                            appointments,
                            index,
                            appointments.length
                          )
                        }
                      >
                        {`${row.providerRequest[0].title}. ${row.providerRequest[0].firstName} ${row.providerRequest[0].middleName} ${row.providerRequest[0].lastName}`}
                      </TableCell>
                      <TableCell align={alignLeftText} className="bodyCell">
                        {row.status !== MeetingStatus.Completed && (
                          <>
                            <img
                              src={EllipsisMenuIcon}
                              alt="Action-Menu-Icon"
                              className="pointer"
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                              role="button"
                              onClick={(e) => {
                                globalStore.setActionMenuSelectedMeeting(row);
                                handleActionClick(
                                  `${row.patientId}${index}`,
                                  e
                                );
                              }}
                            />
                            <Menu
                              id={`${row.patientId}${index}`}
                              anchorEl={anchorEls[`${row.patientId}${index}`]}
                              keepMounted
                              open={Boolean(
                                anchorEls[`${row.patientId}${index}`]
                              )}
                              onClose={() =>
                                handleActionClose(`${row.patientId}${index}`)
                              }
                            >
                              <MenuItem
                                onClick={() => {
                                  window.open(row.teamsMeetingUrl, "_blank");
                                  handleActionClose(`${row.patientId}${index}`);
                                }}
                              >
                                {ConstantVariables.Join_Visit_Button_Text}
                              </MenuItem>
                              {row.status !== MeetingStatus.Scheduled && (
                                <Grid>
                                  <MenuItem
                                    onClick={() => {
                                      handleEndVisit(
                                        `${row.patientId}${index}`,
                                        row
                                      );
                                      AppInsightMethods.trackAppInsightsEvent(
                                        pageName,
                                        Constants.Information,
                                        `The "EndVisit" action menu has been clicked on the Meeting List. televisitId: ${row.televisitId}`,
                                        globalStore.loginUserEmail,
                                        window.location.href,
                                        ""
                                      );
                                    }}
                                  >
                                    End Visit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      onSendMessageMenuItemClick(
                                        `${row.patientId}${index}`,
                                        row
                                      )
                                    }
                                  >
                                    {ConstantVariables.Send_Message_Button_Text}
                                  </MenuItem>
                                </Grid>
                              )}
                              {row.status === MeetingStatus.Scheduled && (
                                <Grid>
                                  <MenuItem
                                    disabled={row.isAdhocMeeting}
                                    onClick={() => {
                                      handleActionClose(
                                        `${row.patientId}${index}`
                                      );
                                      globalStore.setOpenedModal("reschedule");
                                    }}
                                  >
                                    {ConstantVariables.Reschedule_Button_Text}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleActionClose(
                                        `${row.patientId}${index}`
                                      );
                                      globalStore.setOpenedModal("cancel");
                                    }}
                                  >
                                    {ConstantVariables.Cancel_Visit_Button_Text}
                                  </MenuItem>
                                </Grid>
                              )}
                            </Menu>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container className="cards-container">
              <Grid className="noResultListView">
                <InfoOutlinedIcon /> {ConstantVariables.No_Records_Text}
              </Grid>
            </Grid>
          )
        ) : (
          <MeetingDetailedView
            appointments={appointments}
            refreshMeetings={props.refreshMeetings}
          />
        )}

        {showSpinner && (
          <Backdrop className={backdropClass.backdrop} open={showSpinner}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  });
};

export default AppointmentListTable;
