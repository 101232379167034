/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useQuery } from "react-query";
import {
  Tab,
  Grid,
  Tabs,
  AppBar,
  makeStyles,
  CircularProgress,
  Backdrop,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ReorderIcon from "@material-ui/icons/Reorder";
import Search from "@material-ui/icons/Search";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { isMobile } from "react-device-detect";

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import DateFnsUtils from "@date-io/date-fns";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { useGlobalStore } from "../../state/global-store";
import { ScreenType } from "../../state/global-store-types";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  IAddProvider,
  IAppointmentList,
  IUserProvider,
} from "../../services/api-types";

import { MeetingStatus, AppInsightConstants } from "../../constants/Constants";
import { apiService } from "../../services/api-service";
import CardView from "../AppointmentsList/CardView";
import MobileProvider from "./MobileProvider";
import StatusDropdown from "./StatusDropdown";

import NoProviderScreen from "../Provider/NoProviderScreen";
import MobileAppointmentCreation from "./MobileAppointmentCreation";
import { ReactComponent as Filter } from "../../assets/mobile_Filter.svg";
import MobileSideNav from "./MobileSideNav";
import adhocIcon from "../../assets/adhoc-meeting.svg";
import newAppointmentIcon from "../../assets/add-meeting.svg";
import MobileAdhocMeeting from "./MobileAdhocMeeting";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 40,
    color: "#484644",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  speedDialRoot: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    right: "20px",
    bottom: "105px",
    zIndex: theme.zIndex.drawer + 2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface IMobileDashboardProps {
  apifetch: (sd: Date, ed: Date) => void;
  refreshPage: () => void;
  appointmentListFetching: boolean;
  appointmentListError: any;
}

export default function MobileDashboard(props: IMobileDashboardProps) {
  const pageName = "MobileDashboard";
  const tabClassNameText = "tab";
  const globalStore = useGlobalStore();
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<IUserProvider>(
    {} as IUserProvider
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [appointmentType, setAppointmentType] = useState<ScreenType>("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [openAddButton, setOpenAddButton] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const { data: providerData, refetch } = useQuery(
    [globalStore.loginUserEmail],
    apiService.getProviderDetailsBasedOnLogin,
    { retry: false }
  );

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
  }, []);

  useEffect(() => {
    if (globalStore.loginUserEmail) {
      refetch();
    }
  }, [globalStore.loginUserEmail]);

  useEffect(() => {
    if (providerData && providerData.length > 0) {
      globalStore.setUserSpecificProviderList(providerData);
    }
  }, [providerData]);

  const { data } = useQuery(
    ["logoandnameappoinmtnetheader"],
    apiService.getLogoAndName,
    { retry: false }
  );

  /* Method to call meeting list api on the selected date */
  const callGetAppointmentApi = async (_selectedDate: Date) => {
    try {
      props.apifetch(_selectedDate, _selectedDate);
      globalStore.setSelectedDate(_selectedDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Appointment Date Change, selected Date is ${_selectedDate}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at callGetAppointmentApi, Component: ${pageName}, Error: ${error}`
      );
      globalStore.setSpinner(false);
    }
  };

  /* Method called on change of date in calendar */
  const handleDateChange = async (date: any) => {
    globalStore.setSelectedDate(date);
    try {
      const _selectedDate = new Date(date);
      await callGetAppointmentApi(_selectedDate);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Date Change, calendar click event on the Appointment list screen.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at handleDateChange, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method called on calendar keyboard click event */
  const onDateKeyDown = (event: any) => {
    try {
      if (event.key === "Enter") {
        setOpen(true);
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onNextDateKeyDown, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for adding a provider from the search results */
  const onAddProviderClick = (event: any) => {
    try {
      if (
        selectedProvider &&
        selectedProvider.providerId &&
        selectedProvider.providerId !== ""
      ) {
        const addProvider: IAddProvider = {
          providers: [
            {
              providerId: selectedProvider.providerId,
              title: selectedProvider.title ? selectedProvider.title : "",
              firstName: selectedProvider.firstName,
              middleName: selectedProvider.middleName
                ? selectedProvider.middleName
                : "",
              lastName: selectedProvider.lastName,
              phone: selectedProvider.phone ? selectedProvider.phone : "",
              email: selectedProvider.email,
            },
          ],
          admin: {
            adminId: globalStore.loginUserAadId,
            emailId: globalStore.loginUserEmail,
          },
        };
        setShowSpinner(true);
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setSelectedProvider({} as IUserProvider);
        globalStore.setProviderSearchText("");
        apiService
          .addProvider(addProvider)
          .then((res: any) => {
            if (res === "success") {
              const globalProviderList = [
                ...globalStore.userSpecificProviderList,
              ];
              globalProviderList.push(selectedProvider);
              globalStore.setUserSpecificProviderList(globalProviderList);
              const appointmentLists: IAppointmentList[] = JSON.parse(
                JSON.stringify(globalStore.globalAppointmentsList)
              );
              const appointmentListFiltered = appointmentLists.filter((el) => {
                return globalProviderList.some((f) => {
                  return f.email === el.providerRequest[0].email;
                });
              });
              globalStore.setAppointmentsList(appointmentListFiltered);
              globalStore.setShowAlertMessage("success");
              globalStore.setDashboardAlertMessage(
                "Provider added successfully."
              );
              AppInsightMethods.trackAppInsightsEvent(
                pageName,
                AppInsightConstants.Information,
                `Add Provider API Call success.`,
                globalStore.loginUserEmail,
                window.location.href,
                "success"
              );
              setTimeout(() => {
                globalStore.setShowAlertMessage("");
                globalStore.setDashboardAlertMessage("");
              }, 5000);
            }
            setShowSpinner(false);
          })
          .catch((error: any) => {
            setShowSpinner(false);
            globalStore.setShowAlertMessage("error");
            globalStore.setDashboardAlertMessage("Provider not added.");
            setTimeout(() => {
              globalStore.setShowAlertMessage("");
              globalStore.setDashboardAlertMessage("");
            }, 5000);
            AppInsightMethods.TrackAppInsightsException(
              `Error at Add Provider API Call, Component: ${pageName}, Error: ${error}`
            );
          });
      }
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onAddProviderClick, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for getting status label and associated count in Tabs */
  const getAppointMentLabel = (statusType: string) => {
    try {
      if (statusType === MeetingStatus.All) {
        return globalStore.appointmentsList
          ? `${statusType} (${globalStore.appointmentsList?.length})`
          : `${statusType}`;
      }
      return globalStore.appointmentsList
        ? `${statusType} (${
            globalStore.appointmentsList?.filter(
              (val) => val.status === statusType
            ).length
          })`
        : `${statusType}`;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAppointMentLabel, Component: ${pageName}, Error: ${error}`
      );
      return statusType;
    }
  };

  /* On Change handler when meeting status tab is clicked */
  const onTabChangeHandler = (event: unknown, value: string) => {
    try {
      globalStore.setStatusTab(value);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Tab Change event, selected tab is ${value}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTabChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  const handleOpen = () => {
    setOpenAddButton(true);
  };

  const handleClose = () => {
    setOpenAddButton(false);
  };

  return useObserver(() => {
    return (
      <>
        {appointmentType === "" ? (
          <Grid
            className={"mobile_dashboard"}
            id={isMobile ? "mobileDeviceHeight" : "desktopHeight"}
          >
            {!globalStore.meetingDetailedView && (
              <Grid className={"mobile_dashboard_label"}>
                <p>Appointments</p>
                <Search />
              </Grid>
            )}
            {!globalStore.meetingDetailedView && (
              <Grid className={"mobile_dashboard_header"}>
                <Grid style={{ display: "flex" }}>
                  <MobileSideNav />
                  <ReorderIcon
                    onClick={() => globalStore.setIsNavOpen(true)}
                    style={{ marginLeft: "1rem", marginTop: "0.3rem" }}
                  />
                  <p className="providerName" style={{ marginLeft: "1rem" }}>
                    {globalStore.selectedProviderName}
                  </p>
                </Grid>
                <Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd MMMM yyyy"
                      margin="normal"
                      id="filterDatePicker"
                      value={globalStore.selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      keyboardIcon={
                        <KeyboardArrowDownIcon className={classes.root} />
                      }
                      className={"appointmentDatePicker"}
                      autoOk
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      onClick={() => setOpen(true)}
                      onKeyDown={(e) => onDateKeyDown(e)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            )}
            {!globalStore.meetingDetailedView && (
              <Grid className={"mobile_dashboard_nav"}>
                <Grid className={"mobile_dashboard_nav_left"}>
                  <StatusDropdown />
                </Grid>
                <Grid className={"mobile_dashboard_nav_right"}>
                  <Filter />
                </Grid>
              </Grid>
            )}

            {!props.appointmentListFetching &&
            globalStore.userSpecificProviderList.length === 0 &&
            globalStore.selectedProviderName === "My Providers" ? (
              <NoProviderScreen />
            ) : props.appointmentListFetching ? (
              <Grid
                container
                className={"appointmentList spinner"}
                justify={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={90} />
              </Grid>
            ) : props.appointmentListError ? (
              <Grid className={"errorMessage"}>
                <p> Error Fetching Appointment List.</p>
              </Grid>
            ) : (
              <>
                <Grid className={"mobile_dashboard_cards"}>
                  <CardView refreshMeetings={() => props.refreshPage()} />
                </Grid>
                {!globalStore.meetingDetailedView && (
                  <div className={classes.root}>
                    <Backdrop
                      className={classes.backdrop}
                      open={openAddButton}
                    />
                    <SpeedDial
                      ariaLabel="SpeedDial tooltip example"
                      className={classes.speedDial}
                      hidden={hidden}
                      icon={<SpeedDialIcon className="AddAppointmentIcon" />}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      open={openAddButton}
                    >
                      <SpeedDialAction
                        className="SpeedDialAction"
                        key="AdhocAppointment"
                        icon={<img src={adhocIcon} alt={"Adhoc Meeting"} />}
                        tooltipTitle="Adhoc Appointment"
                        tooltipOpen
                        onClick={() => {
                          setAppointmentType("adhoc");
                          handleClose();
                        }}
                      />
                      <SpeedDialAction
                        key="NewAppointment"
                        icon={
                          <img src={newAppointmentIcon} alt={"Add Meeting"} />
                        }
                        tooltipTitle="New Appointment"
                        tooltipOpen
                        onClick={() => {
                          setAppointmentType("new");
                          handleClose();
                        }}
                      />
                    </SpeedDial>
                  </div>
                )}
              </>
            )}
          </Grid>
        ) : appointmentType === "new" ? (
          <MobileAppointmentCreation
            backBtnClick={() => setAppointmentType("")}
            appointmentType={appointmentType}
          />
        ) : (
          <MobileAdhocMeeting
            backBtnClick={() => setAppointmentType("")}
            appointmentType={appointmentType}
          />
        )}
      </>
    );
  });
}
