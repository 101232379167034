/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useQuery } from "react-query";
import {
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Search from "@material-ui/icons/Search";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { useGlobalStore } from "../../state/global-store";
import AppointmentListHeader from "./AppointmentListHeader";
import AppointmentListNav from "./AppointmentListNav";
import AppointmentListTable from "./AppointmentListTable";
import ProviderComponent from "../Provider/ProviderComponent";
import RescheduleAppointment from "../ModifyAppointment/RescheduleAppointment";
import NoProviderScreen from "../Provider/NoProviderScreen";
import CancelAppointment from "../ModifyAppointment/CancelAppointment";
import { apiService } from "../../services/api-service";
import CardViewIconDisabled from "../../assets/card_view_icon_disabled.svg";
import CardViewIconEnabled from "../../assets/card_view_icon_enabled.svg";
import TableViewIconDisabled from "../../assets/table_view_icon_disabled.svg";
import TableViewIconEnabled from "../../assets/table_view_icon_enabled.svg";
import filter from "../../assets/filter.svg";
import CardView from "./CardView";
import {
  IAppointmentList,
  IProvider,
  IUserProvider,
} from "../../services/api-types";
import { AppInsightMethods } from "../../AppInsightMethods";
import {
  ConstantVariables,
  MeetingStatus,
  AppInsightConstants,
} from "../../constants/Constants";
import SendMessage from "../SendMessage/SendMessage";
import MobileDashboard from "../mobile/MobileDashboard";
import { authApiService } from "../../services/authentication-api-service";
import configData from "../../config.json";
import StatusDropdown from "../mobile/StatusDropdown";
import { IThemeColors } from "../../state/global-store-types";

function AppointmentsList() {
  const globalStore = useGlobalStore();
  const appInsights = useAppInsightsContext();
  const tabClassNameText = "tab";
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [loginToken, setLoginToken] = useState("");
  const pageName = "AppointmentsList";
  const trackComponent = useTrackMetric(appInsights, pageName);

  /* Method for adding zero in front of single digit day and month in a date */
  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  };

  const getStartDate = (date: Date) => {
    return moment(new Date(date.setHours(0, 0, 0, 0))).format();
  };

  const getEndDate = (date: Date) => {
    return moment(new Date(date.setHours(23, 59, 59, 0))).format();
  };

  useEffect(() => {
    apiService
      .getThemeDetails()
      .then((resultData: any) => {
        resultData?.filter((data: IThemeColors) => {
          let root;
          data.isSelected === true &&
            (globalStore.setThemeData(data),
            (root = document.documentElement),
            root.style.setProperty(
              "--primary-color",
              data?.themeData?.primaryColor
            ),
            root.style.setProperty(
              "--secondary-color",
              data?.themeData?.secondaryColor
            ),
            root.style.setProperty(
              "--page-background-color",
              data?.themeData?.pageBackgroundColor
            ),
            root.style.setProperty(
              "--sub-page-background-color",
              data?.themeData?.subPageBackgroundColor
            ),
            root.style.setProperty(
              "--alert-color",
              data?.themeData?.alertColor
            ),
            root.style.setProperty(
              "--heading-color",
              data?.themeData?.headingsTextColor
            ),
            root.style.setProperty(
              "--body-text-color",
              data?.themeData?.bodyTextColor
            ));
        });
      })
      .catch((error: any) => {
        AppInsightMethods.trackAppInsightsEvent(
          pageName,
          AppInsightConstants.Information,
          `Error in getting Customised Theme API call`,
          globalStore.loginUserEmail,
          window.location.href,
          `${error}`
        );
      });
  }, []);

  useEffect(() => {
    trackComponent();
    AppInsightMethods.trackAppInsightsEvent(
      pageName,
      AppInsightConstants.Information,
      `${pageName} Page Load`,
      globalStore.loginUserEmail,
      window.location.href,
      ""
    );
    authApiService
      .getAccessToken()
      .then((token: any) => {
        setLoginToken(token);
      })
      .catch((error: any) => {
        AppInsightMethods.TrackAppInsightsException(
          `Error at getAccessToken API call, useEffect, Component: ${pageName}, Error: ${error}`
        );
      });
  }, []);

  const { data, refetch, isFetching, isError } = useQuery(
    [
      getStartDate(selectedDate.startDate),
      getEndDate(selectedDate.endDate),
      configData.PROVIDER_GROUP_ID,
      globalStore.loginUserAadId,
    ],
    apiService.getAppointments,
    { retry: false }
  );

  useEffect(() => {
    if (data && !isError) {
      globalStore.setGlobalAppointmentsList(data);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Get Appointment List API call Success.`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      if (
        globalStore.selectedProviderId === ConstantVariables.All_Provider_Text
      ) {
        const appointmentLists: IAppointmentList[] = JSON.parse(
          JSON.stringify(data)
        );
        globalStore.setAppointmentsList(appointmentLists);
      } else if (
        globalStore.selectedProviderId === ConstantVariables.My_Provider_Text
      ) {
        if (globalStore.userSpecificProviderList.length > 0) {
          const appointmentLists: IAppointmentList[] = JSON.parse(
            JSON.stringify(data)
          );
          const userProvidersList: IUserProvider[] = JSON.parse(
            JSON.stringify(globalStore.userSpecificProviderList)
          );
          const appointmentListFiltered = appointmentLists.filter((el) => {
            return userProvidersList.some((f) => {
              return f.email === el.providerRequest[0].email;
            });
          });
          globalStore.setAppointmentsList(appointmentListFiltered);
        } else {
          globalStore.setAppointmentsList([]);
        }
      } else {
        const filteredObj = data.filter((valu: IAppointmentList) =>
          valu.providerRequest.some(
            (val: IProvider) =>
              val.providerId === globalStore.selectedProviderId
          )
        );
        globalStore.setAppointmentsList(filteredObj);
      }
    } else if (isError) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at GetAppointments API call, Component: ${pageName}`
      );
    }
  }, [data, isError, globalStore.selectedProviderName]);

  /* On Change handler when meeting status tab is clicked */
  const onTabChangeHandler = (event: unknown, value: string) => {
    try {
      globalStore.setStatusTab(value);
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Tab Change event, selected tab is ${value}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTabChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  /* Method for getting status label and associated count in Tabs */
  const getAppointMentLabel = (statusType: string) => {
    try {
      if (statusType === MeetingStatus.All) {
        return globalStore.appointmentsList
          ? `${statusType} (${globalStore.appointmentsList.length})`
          : `${statusType}`;
      }
      return globalStore.appointmentsList
        ? `${statusType} (${
            globalStore.appointmentsList.filter(
              (val) => val.status === statusType
            ).length
          })`
        : `${statusType}`;
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at getAppointMentLabel, Component: ${pageName}, Error: ${error}`
      );
      return statusType;
    }
  };

  useEffect(() => {
    if (selectedDate) {
      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        AppInsightConstants.Information,
        `Date Change event, selected Date is "${selectedDate.startDate.toString()}"`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
      refetch();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (loginToken) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${configData.API_URL}/meetingHub`, {
          accessTokenFactory: () => loginToken,
          withCredentials: false,
        })
        .configureLogging("info")
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [loginToken]);

  useEffect(() => {
    async function startConnection() {
      try {
        if (connection && connection.state !== HubConnectionState.Connected) {
          await connection.start();
          connection.on("broadcastMessage", (name: string, message: string) => {
            const messageObj: IAppointmentList = JSON.parse(message);
            broadcastMessageHandler(messageObj);
          });
        }
      } catch (error) {
        AppInsightMethods.TrackAppInsightsException(
          `Error at startConnection, Component: ${pageName}, Error: ${error}`
        );
        setTimeout(() => startConnection(), 5000);
      }
    }
    /* SignalR handler */
    const broadcastMessageHandler = (messageObj: IAppointmentList) => {
      try {
        const startDateStr = formatDate(globalStore.selectedDate);
        const scheduleDateStr = formatDate(
          new Date(messageObj.scheduleDateTimeTZD)
        );
        if (messageObj.isMeetingDeleted) {
          globalStore.deleteAppointmentList(messageObj.televisitId);
        } else if (
          startDateStr === scheduleDateStr &&
          globalStore.globalAppointmentsList.some(
            (searchElement: IAppointmentList) =>
              searchElement.televisitId === messageObj.televisitId
          ) &&
          globalStore.appointmentsList.some(
            (value: IAppointmentList) =>
              value.televisitId === messageObj.televisitId
          )
        ) {
          globalStore.updateAppointmentList(messageObj.televisitId, messageObj);
        } else if (startDateStr === scheduleDateStr) {
          globalStore.insertAppointmentList(messageObj);
        } else if (
          globalStore.appointmentsList.some(
            (value: IAppointmentList) =>
              value.televisitId === messageObj.televisitId
          )
        ) {
          globalStore.deleteAppointmentList(messageObj.televisitId);
        }
      } catch (error) {
        AppInsightMethods.TrackAppInsightsException(
          `Error at broadcastMessageHandler, Component: ${pageName}, Error: ${error}`
        );
      }
    };

    if (connection) {
      connection
        .start()
        .then((result) => {
          AppInsightMethods.trackAppInsightsEvent(
            pageName,
            AppInsightConstants.Information,
            `Azure SignalR connection established successfully. Result : ${result}`,
            globalStore.loginUserEmail,
            window.location.href,
            ""
          );
          connection.on("broadcastMessage", (name: string, message: string) => {
            const messageObj: IAppointmentList = JSON.parse(message);
            broadcastMessageHandler(messageObj);
          });
        })
        .catch((error) => {
          AppInsightMethods.TrackAppInsightsException(
            `Error at connecting to Azure SignalR, Component: ${pageName}, Error: ${error}`
          );
          setTimeout(() => startConnection(), 5000);
        });

      connection.onclose((error) => {
        startConnection();
        connection.off("broadcastMessage");
        AppInsightMethods.TrackAppInsightsException(
          `Error at SignalR Connection, Component: ${pageName}, Error: ${error}`
        );
      });
    }
  }, [connection]);

  return useObserver(() => {
    return (
      <Grid className="appointments-container">
        <Grid className={"appointmentListHeaderContainer"}>
          <AppointmentListHeader />
          {globalStore.showAlertMessage && (
            <Grid className={"appointScreenAlert"}>
              <Alert severity={globalStore.showAlertMessage}>
                {globalStore.dashboardAlertMessage}
              </Alert>
            </Grid>
          )}
        </Grid>
        {(isMobile &&
          globalStore.windowWidth <= ConstantVariables.mobileMaxWidth) ||
        globalStore.windowWidth <= ConstantVariables.mobileMaxWidth ? (
          <MobileDashboard
            apifetch={(s, e) => {
              setSelectedDate({
                ...selectedDate,
                startDate: new Date(s),
                endDate: new Date(e),
              });
            }}
            refreshPage={() => refetch()}
            appointmentListFetching={isFetching}
            appointmentListError={isError}
          />
        ) : (
          <Grid className="appointmentListContent">
            {globalStore.windowWidth > ConstantVariables.tabletMaxWidth ? (
              <Grid className={"appointmentListProviderSection"}>
                <ProviderComponent />
              </Grid>
            ) : (
              <Grid
                className={`"appointmentListProviderSection" ${
                  globalStore.navOpen ? "navOpenAppointmnetList" : "hideDisplay"
                }`}
              >
                <ProviderComponent />
              </Grid>
            )}

            {globalStore.userSpecificProviderList.length === 0 &&
            globalStore.selectedProviderName === "My Providers" ? (
              <NoProviderScreen />
            ) : (
              <Grid className={"appointmentListMainSection"}>
                {!globalStore.meetingDetailedView && (
                  <AppointmentListNav
                    apifetch={(s, e) => {
                      setSelectedDate({
                        ...selectedDate,
                        startDate: new Date(s),
                        endDate: new Date(e),
                      });
                    }}
                  />
                )}
                {isFetching || globalStore.spinner ? (
                  <Grid
                    container
                    className={"appointmentList spinner"}
                    justify={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={90} />
                  </Grid>
                ) : isError ? (
                  <Grid className={"errorMessage"}>
                    <p> Error Fetching Appointment List.</p>
                  </Grid>
                ) : (
                  <Grid>
                    {!globalStore.meetingDetailedView && (
                      <Grid className={"appointmentListTabs"}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          {(globalStore.windowWidth <
                            ConstantVariables.windowsWidthForDDView &&
                            globalStore.windowWidth >
                              ConstantVariables.tabletMaxWidth) ||
                          (globalStore.windowWidth <
                            ConstantVariables.tabletWidthForDDView &&
                            globalStore.windowWidth >
                              ConstantVariables.mobileMaxWidth) ? (
                            <StatusDropdown />
                          ) : (
                            <Grid item>
                              <Tabs
                                value={globalStore.statusTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(e, val) =>
                                  onTabChangeHandler(e, val)
                                }
                                aria-label="tabs"
                                TabIndicatorProps={{
                                  style: {
                                    backgroundColor: "#4a8409",
                                  },
                                }}
                              >
                                <Tab
                                  className={tabClassNameText}
                                  label={getAppointMentLabel(MeetingStatus.All)}
                                  value={MeetingStatus.All}
                                  tabIndex={0}
                                />
                                <Tab
                                  label={getAppointMentLabel(
                                    MeetingStatus.InProgress
                                  )}
                                  value={MeetingStatus.InProgress}
                                  className={tabClassNameText}
                                  tabIndex={0}
                                />
                                <Tab
                                  label={getAppointMentLabel(
                                    MeetingStatus.Waiting
                                  )}
                                  value={MeetingStatus.Waiting}
                                  className={tabClassNameText}
                                  tabIndex={0}
                                />
                                <Tab
                                  label={getAppointMentLabel(
                                    MeetingStatus.Delayed
                                  )}
                                  value={MeetingStatus.Delayed}
                                  className={tabClassNameText}
                                  tabIndex={0}
                                />
                                <Tab
                                  label={getAppointMentLabel(
                                    MeetingStatus.Scheduled
                                  )}
                                  value={MeetingStatus.Scheduled}
                                  className={tabClassNameText}
                                  tabIndex={0}
                                />
                                <Tab
                                  label={getAppointMentLabel(
                                    MeetingStatus.Completed
                                  )}
                                  value={MeetingStatus.Completed}
                                  className={tabClassNameText}
                                  tabIndex={0}
                                />
                              </Tabs>
                            </Grid>
                          )}
                          <Grid item className={"appointmentListTabsRight"}>
                            <Grid className={"searchBox"}>
                              <Search />
                              <TextField
                                id="header-SearchBox"
                                placeholder={"Search"}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event) => {
                                  AppInsightMethods.trackAppInsightsEvent(
                                    pageName,
                                    AppInsightConstants.Information,
                                    `The search box onchange event on the Appointment list screen, searchText: ${event.target.value}`,
                                    globalStore.loginUserEmail,
                                    window.location.href,
                                    ""
                                  );
                                  globalStore.setSearchMeetingString(
                                    event.target.value
                                  );
                                }}
                                value={globalStore.searchMeetingString}
                              />
                            </Grid>
                            <Grid className={"filterContainer"}>
                              <img src={filter} alt={"Filter"} /> Filter
                            </Grid>
                            {globalStore.meetingsView === "table" ? (
                              <>
                                <img
                                  src={TableViewIconEnabled}
                                  alt="Table view Icon Enabled"
                                  className="icon tableView cursor disabled"
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                  role={"button"}
                                />
                                <img
                                  src={CardViewIconDisabled}
                                  alt="Card view Icon Disabled"
                                  className="icon cursor enabled"
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                  role={"button"}
                                  onClick={() => {
                                    AppInsightMethods.trackAppInsightsEvent(
                                      pageName,
                                      AppInsightConstants.Information,
                                      `The Card view is selected on the Appointment list screen.`,
                                      globalStore.loginUserEmail,
                                      window.location.href,
                                      ""
                                    );
                                    globalStore.setMeetingsView("card");
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={TableViewIconDisabled}
                                  alt="Table view Icon Disabled"
                                  className="icon tableView cursor enabled"
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                  role={"button"}
                                  onClick={() => {
                                    AppInsightMethods.trackAppInsightsEvent(
                                      pageName,
                                      AppInsightConstants.Information,
                                      `The Table view is selected on the Appointment list screen.`,
                                      globalStore.loginUserEmail,
                                      window.location.href,
                                      ""
                                    );
                                    globalStore.setMeetingsView("table");
                                  }}
                                />
                                <img
                                  src={CardViewIconEnabled}
                                  alt="Card view Icon Enabled"
                                  className="icon cursor disabled"
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                  role={"button"}
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid className={"appointmentTableContainer"}>
                      {globalStore.meetingsView === "table" ? (
                        <AppointmentListTable
                          refreshMeetings={() => refetch()}
                        />
                      ) : (
                        <CardView refreshMeetings={() => refetch()} />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
        <RescheduleAppointment
          updateMeetings={() => {
            refetch();
            globalStore.setActionMenuSelectedMeeting(null);
            globalStore.setMeetingDetailedView([], null, 0);
          }}
        />
        <CancelAppointment
          refreshMeetings={() => {
            refetch();
            globalStore.setActionMenuSelectedMeeting(null);
            globalStore.setMeetingDetailedView([], null, 0);
          }}
        />
        <SendMessage />
      </Grid>
    );
  });
}

export default AppointmentsList;
